import { useRef } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import {
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { closeOutline, closeSharp } from "ionicons/icons";
import { useNavigate } from "react-router";
import { ADMIN_USERS } from "../constants";

import "./Account.css";

const Menu: React.FC = () => {
  const menuRef = useRef<any>(null);
  const navigate = useNavigate();
  const { signOut, user } = useAuthenticator((context) => [
    context.signOut,
    context.user,
  ]);

  const logOut = () => {
    signOut();
    navigate("/auth");
  };

  const closeMenu = () => {
    if (menuRef && menuRef.current) {
      menuRef.current.close();
    }
  };

  const onMenuItemClick = (navigatePath: string) => {
    closeMenu();
    navigate(navigatePath);
  };

  return (
    <IonMenu
      menuId="account"
      contentId="main-content"
      type="overlay"
      side="end"
      ref={menuRef}
    >
      <IonHeader>
        <IonToolbar>
          <IonTitle>{user.attributes?.email}</IonTitle>
          <IonMenuToggle menu="account" slot="end">
            <IonButton color="dark" fill="clear">
              <IonIcon ios={closeOutline} md={closeSharp} />
            </IonButton>
          </IonMenuToggle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonList lines="none" style={{ padding: "0px" }}>
          <IonItem button={true} onClick={() => onMenuItemClick("/home")}>
            <IonLabel>Home</IonLabel>
          </IonItem>
          {/* {ADMIN_USERS.has(user.getUsername()) && (
            <IonItem
              button={true}
              onClick={() => onMenuItemClick("/account/audit")}
            >
              <IonLabel>Audit</IonLabel>
            </IonItem>
          )} */}
          {/* <IonItem
            button={true}
            onClick={() => onMenuItemClick("/account/profile")}
          >
            <IonLabel>Profile</IonLabel>
          </IonItem> */}
          {/*<IonItem button={true} onClick={() => navigate("/account/usage")}>
            <IonLabel>Usage</IonLabel>
          </IonItem>*/}
          <IonItem button={true} onClick={logOut}>
            <IonLabel>Log out</IonLabel>
          </IonItem>
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
