import React from "react";
import moment from "moment";

import { JSONTree } from "react-json-tree";
import { useParams } from "react-router-dom";
import { Box, Paper, Stack, Typography, styled } from "@mui/material";
import { alpha } from "@mui/material/styles";
import { useAuthenticator } from "@aws-amplify/ui-react";

import { darkTheme } from "./Theme";
import {
  TERMINAL_EXECUTION_STATUSES,
  Execution as ExecutionModel,
  capitalizeFirstLetter,
  getExecution,
} from "../api/Nunchi";

import "@aws-amplify/ui-react/styles.css";

const GET_EXECUTION_INTERVAL_IN_MS = 5000;

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Execution: React.FC = () => {
  const { user } = useAuthenticator((context) => [context.user]);
  const { executionId } = useParams();
  const [execution, setExecution] = React.useState<any>({});

  const loggingEndRef = React.useRef<null | HTMLDivElement>(null);

  const scrollToBottom = () => {
    loggingEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  React.useEffect(() => {
    scrollToBottom();
  }, [execution]);

  const jsonTreeTheme = {
    base00: darkTheme.palette.background.default,
  };

  const getDuration = (execution: ExecutionModel) => {
    const creationTime = moment(execution.start_time);
    const referenceTime = execution.end_time
      ? moment(execution.end_time)
      : moment();
    return moment.utc(referenceTime.diff(creationTime)).format("HH:mm:ss");
  };

  React.useEffect(() => {
    (async function () {
      if (executionId) {
        const response = await getExecution(user, undefined, executionId);
        if (response.ok) {
          const responseData = await response.json();
          const execution = responseData.execution;
          setExecution(execution);

          // poll for status until execution is in terminal status
          if (!TERMINAL_EXECUTION_STATUSES.has(execution.status)) {
            const interval = setInterval(async () => {
              const response = await getExecution(user, undefined, executionId);
              if (response.ok) {
                const responseData = await response.json();
                const updatedExecution = responseData.execution;
                setExecution(updatedExecution);

                if (TERMINAL_EXECUTION_STATUSES.has(updatedExecution.status)) {
                  clearInterval(interval);
                }
              } else {
                clearInterval(interval);
              }
            }, GET_EXECUTION_INTERVAL_IN_MS);
          }
        } else {
          alert("Unexpected error occurred");
        }
      }
    })();
  }, []);

  return (
    <Stack direction="column" spacing={2} width={"100%"}>
      <Typography variant={"h6"} color="text.primary">
        Execution: {executionId}
      </Typography>
      <Stack direction="row" spacing={2} width={"100%"}>
        {execution.status && (
          <Item>Status: {capitalizeFirstLetter(execution.status)}</Item>
        )}
        {execution.start_time && (
          <Item>
            Start Date: {moment(execution.start_time).local().format("l LT")}
          </Item>
        )}
        {execution.end_time && (
          <Item>
            End Date: {moment(execution.end_time).local().format("l LT")}
          </Item>
        )}
        {execution.start_time && (
          <Item>Duration: {getDuration(execution)}</Item>
        )}
      </Stack>
      <Stack direction="row" spacing={2} width={"100%"}>
        <Box flex={1}>
          <Item>Input</Item>
          <Box
            width={"100%"}
            height={"250px"}
            border={`0.1px solid ${alpha(
              darkTheme.palette.primary.light,
              0.2
            )}`}
            p={1}
            overflow={"auto"}
          >
            {execution.input && (
              <JSONTree
                data={execution.input}
                hideRoot={true}
                theme={jsonTreeTheme}
                shouldExpandNodeInitially={() => true}
              />
            )}
          </Box>
        </Box>
        <Box flex={1}>
          <Item>Output</Item>
          <Box
            width={"100%"}
            height={"250px"}
            border={`0.1px solid ${alpha(
              darkTheme.palette.primary.light,
              0.2
            )}`}
            p={1}
            overflow={"auto"}
          >
            {execution.output && (
              <JSONTree
                data={execution.output}
                hideRoot={true}
                theme={jsonTreeTheme}
                shouldExpandNodeInitially={() => true}
              />
            )}
          </Box>
        </Box>
      </Stack>
      <Box flex={1}>
        <Item>Logging</Item>
        <Box
          width={"100%"}
          height={"400px"}
          border={`0.1px solid ${alpha(darkTheme.palette.primary.light, 0.2)}`}
          p={2}
          overflow={"auto"}
        >
          <Typography style={{ whiteSpace: "pre-wrap", wordBreak: "keep-all" }}>
            {execution.logging}
          </Typography>
          <Box ref={loggingEndRef} />
        </Box>
      </Box>
    </Stack>
  );
};

export default Execution;
