import React from "react";

import { IonPage, IonContent } from "@ionic/react";
import { Link } from "react-router-dom";

import "@aws-amplify/ui-react/styles.css";
import { useAuthenticator } from "@aws-amplify/ui-react";

const NotFound: React.FC = () => {
  const { route } = useAuthenticator((context) => [context.route]);
  const { signOut } = useAuthenticator((context) => [context.user]);

  return (
    <IonPage id="main-content">
      <IonContent className="ion-padding">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h1>404 - Not Found!</h1>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Link to={route === "authenticated" ? "/home" : "/"}>Go Home</Link>
            {route === "authenticated" && (
              <div style={{ marginLeft: "10px" }}>
                <Link to="#" onClick={signOut}>
                  Sign Out
                </Link>
              </div>
            )}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default NotFound;
