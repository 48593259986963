import React from "react";

import { menuController } from "@ionic/core/components";
import {
  IonHeader,
  IonToolbar,
  IonButton,
  IonMenuToggle,
  IonIcon,
  IonText,
} from "@ionic/react";
import { useNavigate } from "react-router";
import { personCircleOutline, personCircleSharp } from "ionicons/icons";

const Header: React.FC = () => {
  const navigate = useNavigate();

  return (
    <IonHeader style={{ background: "white" }}>
      <IonToolbar>
        <IonButton slot="start" fill="clear" onClick={() => navigate("/home")}>
          <img
            width={32}
            height={32}
            src="/assets/icon/favicon.png"
            alt="logo"
          />
        </IonButton>
        <IonText slot="start">
          <p
            style={{
              margin: "0px",
              fontSize: "20px",
              fontWeight: "400",
              color: "black",
              fontFamily: "Roboto-Bold",
            }}
          >
            Nunchi
          </p>
        </IonText>
        <IonButton slot="end" fill="clear">
          <IonMenuToggle menu="account">
            <IonIcon
              size="large"
              color="dark"
              ios={personCircleOutline}
              md={personCircleSharp}
            />
          </IonMenuToggle>
        </IonButton>
      </IonToolbar>
    </IonHeader>
  );
};

export default Header;
