import { Paper, styled } from "@mui/material";

import "@aws-amplify/ui-react/styles.css";

export const StyledBoxLabel = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
