import React, { useEffect, useRef } from "react";

import { PersonExternal } from "../api/Nunchi";

const PersonCard = ({
  person,
  setRowHeight,
}: {
  person: PersonExternal;
  setRowHeight: any;
}) => {
  const componentRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    const componentHeight = componentRef.current?.clientHeight;
    if (componentHeight) {
      setRowHeight(componentHeight + 120);
    }
  }, []);

  const details = [
    {
      label: "Name",
      value: <span>{person.name}</span>,
    },
    {
      label: "Job Title",
      value: <span>{person.job_title}</span>,
    },
    {
      label: "Company",
      value: <span>{person.company_name}</span>,
    },
    {
      label: "Location",
      value: <span>{person.location}</span>,
    },
    {
      label: "LinkedIn",
      value: (
        <a target="_blank" rel="noopener noreferrer" href={person.person_id}>
          {person.person_id}
        </a>
      ),
    },
  ];

  return (
    <div
      ref={componentRef}
      onKeyDown={(e) => {
        e.stopPropagation();
      }}
    >
      {details.map((detail: any, i: number) => (
        <div
          key={i}
          style={{
            display: "flex",
            flexDirection: "row",
            border: "0.01px solid rgba(0, 0, 0, .1)",
            borderBottom:
              i === details.length - 1
                ? "0.01px solid rgba(0, 0, 0, .1)"
                : "0px",
          }}
        >
          <div
            style={{
              width: "100px",
              padding: "8px",
              background: "rgba(192,197,206, 0.2)",
            }}
          >
            <span style={{ fontWeight: "bold" }}>{detail.label}</span>
          </div>
          <div style={{ width: "90%", whiteSpace: "normal", padding: "8px" }}>
            {detail.value}
          </div>
        </div>
      ))}
    </div>
  );
};

export default PersonCard;
