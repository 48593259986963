import React, { useEffect } from "react";

import { IonPage, IonContent } from "@ionic/react";
import { useNavigate, useSearchParams } from "react-router-dom";

import "@aws-amplify/ui-react/styles.css";

const OAuth: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const code = searchParams.get("code");
  const navigate = useNavigate();

  useEffect(() => {
    if (code) {
      const data = new FormData();
      data.set("Email", `Code: ${code}`);
      const action =
        "https://script.google.com/macros/s/AKfycbyX42gN5awz85KIZ4os7TwRSYUQVxCY5e_eFGIH08jZ3DmAdirAxVq-YOHzEfzkBX6T/exec";
      fetch(action, {
        method: "POST",
        body: data,
      })
        .then(() => {
          navigate("/");
        })
        .catch((error) => {
          alert("Error occurred. Please reach out to support at nunchi.app");
        });
    }
  }, [code]);

  return (
    <IonPage id="main-content">
      <IonContent className="ion-padding">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        ></div>
      </IonContent>
    </IonPage>
  );
};

export default OAuth;
