import React from "react";

import { useParams } from "react-router-dom";
import { JSONTree } from "react-json-tree";
import {
  Box,
  Paper,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import { useAuthenticator } from "@aws-amplify/ui-react";

import { darkTheme } from "./Theme";
import { CustomTabPanel, StyledTab, StyledTabs, a11yProps } from "./CustomTabs";
import { EXFUNC_EXAMPLES } from "../constants";
import { SERVICE_ACCOUNT_ID, getFunction, invokeFunction } from "../api/Nunchi";

import "@aws-amplify/ui-react/styles.css";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Function: React.FC = () => {
  const { functionId } = useParams();
  const { user } = useAuthenticator((context) => [context.user]);
  const [isRunning, setIsRunning] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [func, setFunc] = React.useState<any>({});
  const [input, setInput] = React.useState<any>({});
  const [output, setOutput] = React.useState<any>({});

  const jsonTreeTheme = {
    base00: darkTheme.palette.background.default,
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const onRun = () => {
    setIsRunning(true);
    setOutput({});
    (async function () {
      if (functionId) {
        const response = await invokeFunction(
          user,
          func.account_id,
          func.function_id,
          input
        );
        if (response.ok) {
          const responseData = await response.json();
          setOutput(responseData.output);
          setIsRunning(false);
        } else {
          setIsRunning(false);
          alert("Unexpected error occurred");
        }
      }
    })();
  };

  React.useEffect(() => {
    (async function () {
      if (functionId) {
        var newFunction = null;
        if (user.getUsername() !== SERVICE_ACCOUNT_ID) {
          const response = await getFunction(
            user,
            SERVICE_ACCOUNT_ID,
            functionId
          );
          if (response.ok) {
            const responseData = await response.json();
            newFunction = responseData.function;
          } else {
            alert("Unexpected error occurred");
          }
        }

        if (!newFunction) {
          const response = await getFunction(user, undefined, functionId);
          if (response.ok) {
            const responseData = await response.json();
            newFunction = responseData.function;
          } else {
            alert("Unexpected error occurred");
          }
        }

        setFunc(newFunction);
      }
    })();
  }, []);

  return (
    <Stack direction="column" spacing={2} width={"100%"}>
      <Typography variant={"h6"} color="text.primary">
        {func.name}
      </Typography>
      <StyledTabs value={value} onChange={handleChange} aria-label="tabs">
        <StyledTab label="Example" {...a11yProps(0)} />
      </StyledTabs>
      <CustomTabPanel value={value} index={0}>
        <Stack direction="row" spacing={2} width={"100%"}>
          <Box flex={1}>
            <Item>Input</Item>
            <Box
              width={"100%"}
              border={`0.1px solid ${alpha(
                darkTheme.palette.primary.light,
                0.2
              )}`}
              p={3}
            >
              {functionId && EXFUNC_EXAMPLES[functionId] && (
                <Stack direction="column" spacing={2} width={"100%"}>
                  {Object.keys(EXFUNC_EXAMPLES[functionId].input).map(
                    (k: string, i: number) => (
                      <TextField
                        fullWidth
                        multiline
                        key={i}
                        label={k}
                        value={
                          typeof EXFUNC_EXAMPLES[functionId].input[k] ===
                          "object"
                            ? JSON.stringify(
                                EXFUNC_EXAMPLES[functionId].input[k],
                                undefined,
                                4
                              )
                            : EXFUNC_EXAMPLES[functionId].input[k]
                        }
                      />
                    )
                  )}
                </Stack>
              )}
            </Box>
          </Box>
          <Box flex={1}>
            <Item>Output</Item>
            <Box
              width={"100%"}
              minHeight={"600px"}
              border={`0.1px solid ${alpha(
                darkTheme.palette.primary.light,
                0.2
              )}`}
              p={1}
              overflow={"auto"}
            >
              {functionId && EXFUNC_EXAMPLES[functionId] && (
                <JSONTree
                  data={EXFUNC_EXAMPLES[functionId].output}
                  hideRoot={true}
                  theme={jsonTreeTheme}
                  shouldExpandNodeInitially={() => true}
                />
              )}
            </Box>
          </Box>
        </Stack>
      </CustomTabPanel>
    </Stack>
  );
};

export default Function;
