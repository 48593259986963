import React, { useEffect, useRef, useState } from "react";
import Header from "../components/Header";
import { useAuthenticator } from "@aws-amplify/ui-react";
import {
  IonButton,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  IonText,
  IonTextarea,
} from "@ionic/react";
import { useNavigate } from "react-router";
import { getUser, updateUser } from "../api/Nunchi";

import "@aws-amplify/ui-react/styles.css";

const Profile: React.FC = () => {
  const navigate = useNavigate();
  const [companyName, setCompanyName] = useState("");
  const [companyWebsiteUrl, setCompanyWebsiteUrl] = useState("");
  const [companyProductDescription, setCompanyProductDescription] =
    useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuthenticator((context) => [context.user]);
  const companyNameInputRef = useRef<any>(null);
  const companyWebsiteUrlInputRef = useRef<any>(null);
  const companyProductDescriptionInputRef = useRef<any>(null);

  const updateProfile = (e: any) => {
    e.preventDefault();
    (async function () {
      const response = await updateUser(
        user,
        companyName,
        companyWebsiteUrl,
        companyProductDescription
      );
      if (!response.ok) {
        const responseData = await response.json();
        console.log(responseData);
        alert("An error occured while updating the profile. Please try again.");
      } else {
        alert("Successfully updated the profile");
        navigate("/home");
      }
    })();
  };

  const saveCompanyNameValue = (e: any) => {
    if (
      companyNameInputRef != null &&
      companyNameInputRef.current != null &&
      companyNameInputRef.current.value !== ""
    ) {
      setCompanyName(companyNameInputRef.current.value);
    }
  };

  const saveCompanyWebsiteUrlValue = (e: any) => {
    if (
      companyWebsiteUrlInputRef != null &&
      companyWebsiteUrlInputRef.current != null &&
      companyWebsiteUrlInputRef.current.value !== ""
    ) {
      setCompanyWebsiteUrl(companyWebsiteUrlInputRef.current.value);
    }
  };

  const saveCompanyProductDescriptionValue = (e: any) => {
    if (
      companyProductDescriptionInputRef != null &&
      companyProductDescriptionInputRef.current != null &&
      companyProductDescriptionInputRef.current.value !== ""
    ) {
      setCompanyProductDescription(
        companyProductDescriptionInputRef.current.value
      );
    }
  };

  useEffect(() => {
    if (user) {
      setIsLoading(true);
      (async function () {
        const response = await getUser(user);
        if (response.ok) {
          const responseData = await response.json();
          if (responseData.user.company_name) {
            setCompanyName(responseData.user.company_name);
          }
          if (responseData.user.company_website_url) {
            setCompanyWebsiteUrl(responseData.user.company_website_url);
          }
          if (responseData.user.company_product_description) {
            setCompanyProductDescription(
              responseData.user.company_product_description
            );
          }
        }
        setIsLoading(false);
      })();
    }
  }, [user]);

  return (
    <IonPage id="main-content">
      <Header />
      <IonContent fullscreen>
        <IonGrid
          style={{
            paddingLeft: "20%",
            height: "100%",
          }}
        >
          <IonRow>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <IonText>
                <h1>Profile</h1>
              </IonText>
              <IonText>
                <h4>Company Name</h4>
              </IonText>
              <IonTextarea
                fill="outline"
                placeholder="e.g. Nunchi"
                value={companyName}
                onKeyUp={saveCompanyNameValue}
                ref={companyNameInputRef}
                disabled={isLoading}
              ></IonTextarea>
              <IonText>
                <h4>Company Website URL</h4>
              </IonText>
              <IonTextarea
                fill="outline"
                placeholder="e.g. https://nunchi.app/"
                value={companyWebsiteUrl}
                onKeyUp={saveCompanyWebsiteUrlValue}
                ref={companyWebsiteUrlInputRef}
                disabled={isLoading}
              ></IonTextarea>
              <IonText>
                <h4>Product Description</h4>
              </IonText>
              <IonTextarea
                fill="outline"
                placeholder="e.g. AI Sales Prospecting Service"
                value={companyProductDescription}
                onKeyUp={saveCompanyProductDescriptionValue}
                ref={companyProductDescriptionInputRef}
                disabled={isLoading}
              ></IonTextarea>
              <div
                style={{
                  paddingTop: "20px",
                }}
              ></div>
              <IonButton
                color="dark"
                style={{ textTransform: "none", float: "right", width: "60px" }}
                onClick={updateProfile}
              >
                <div
                  style={{
                    fontWeight: "600",
                    textAlign: "center",
                  }}
                >
                  Save
                </div>
              </IonButton>
            </div>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Profile;
