import React from "react";
import Header from "./Header";
import Panel from "./Panel";

import { IonContent, IonGrid, IonPage, IonRow } from "@ionic/react";

const Home = ({ children }: { children: any }) => {
  return (
    <IonPage id="main-content">
      <Header />
      <IonContent fullscreen>
        <div style={{ height: "100%", paddingBottom: "2%", display: "flex" }}>
          {/* <Panel /> */}
          <IonGrid>
            <IonRow style={{ flexGrow: 1 }}>{children}</IonRow>
          </IonGrid>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Home;
