import React from "react";

import AgencyLeads from "../components/AgencyLeads";
import AgencyLeadsPublic from "../components/AgencyLeadsPublic";

import { AppBar, Box, CssBaseline, Toolbar, Typography } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

import { darkTheme } from "../components/Theme";

import "@aws-amplify/ui-react/styles.css";

const AgencyLeadsPage = ({ isPublic }: { isPublic: boolean }) => {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Box
        minHeight={"100%"}
        overflow={"auto"}
        sx={{ display: "flex" }}
        paddingX={2}
        paddingY={1}
      >
        <CssBaseline />
        <AppBar
          sx={{
            paddingX: 3,
          }}
        >
          <Toolbar>
            <Typography color="text.primary">Staffing Agency Leads</Typography>
          </Toolbar>
        </AppBar>
        <Box
          component="main"
          sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
        >
          <Toolbar />
          {isPublic ? <AgencyLeadsPublic /> : <AgencyLeads />}
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default AgencyLeadsPage;
