import { CognitoUser } from "@aws-amplify/auth";
import {
  ADMIN_USERS,
  NUNCHI_API_ROOT,
  TOOLBOX_LAMBDA_URL,
  SERVICE_PUBLIC_LAMBDA_URL,
  PRODUCT_SEARCH_LAMBDA_URL,
  EXFUNC_SERVICE_API_ROOT,
} from "../constants";

export interface Activity {
  readonly activity_id: string;
  readonly activity_time: string;
  readonly activity_type: string;
  readonly entity_id: string;
  readonly entity_name: string;
  readonly entity_tagline: string;
  readonly url: string;
  readonly article_body: string;
  readonly parent_article_body: string;
}

export interface Company {
  readonly company_id: string;
  readonly name: string;
  readonly location: string;
  readonly industry: string;
  readonly overview: string;
  readonly num_employees: string;
  readonly website_url: string;
}

export interface Person {
  readonly person_id: string;
  readonly name: string;
  readonly location: string;
  readonly job_title: string;
  readonly company_name: string;
  readonly company: Company;
}

export interface Lead {
  readonly lead_id: string;
  readonly score: number;
  readonly status: string;
  readonly person: Person;
  readonly activities: Activity[];
}

export interface PersonExternal {
  readonly person_id: string;
  readonly name: string;
  readonly location: string;
  readonly job_title: string;
  readonly company_name: string;
  readonly company: Company;
  readonly activities: Activity[];
}

export interface FilterAttribute {
  readonly include: boolean;
  readonly value: string[];
}

export interface Author {
  readonly regions: FilterAttribute;
  readonly jobFunctions: FilterAttribute;
  readonly companySizes: FilterAttribute;
  readonly companyIndustries: FilterAttribute;
}

export interface Feed {
  readonly account_id: string;
  readonly feed_id: string;
  readonly resource_type: string;
  readonly resource_description: string;
  readonly intent: IntentValue;
  readonly query: string;
  readonly sources: SourceValue[];
  readonly author: Author;
  readonly status: string;
  readonly creation_time: string;
  readonly last_updated_time: string;
}

export interface FeedItem {
  readonly account_id: string;
  readonly feed_item_id: string;
  readonly feed_id: string;
  readonly activity_id: string;
  readonly activity_time: string;
  readonly activity_type: string;
  readonly entity_id: string;
  readonly url: string;
  readonly article_body: string;
  readonly creation_time: string;
  readonly last_updated_time: string;
  readonly parent_article_body: string;
  readonly meta: any;
}

export interface Function {
  readonly account_id: string;
  readonly function_id: string;
  readonly name: string;
  readonly description: string;
  readonly parameters: object;
  readonly status: string;
  readonly creation_time: string;
  readonly last_updated_time: string;
}

export interface Task {
  readonly account_id: string;
  readonly task_id: string;
  readonly name: string;
  readonly description: string;
  readonly code: string;
  readonly definition: string;
  readonly parameters: object;
  readonly status: string;
  readonly creation_time: string;
  readonly last_updated_time: string;
}

export interface Execution {
  readonly account_id: string;
  readonly execution_id: string;
  readonly task_id: string;
  readonly command_id: string;
  readonly input: object;
  readonly output: object;
  readonly code: string;
  readonly logging: string;
  readonly status: string;
  readonly start_time: string;
  readonly end_time: string;
}

export enum TaskStatus {
  ACTIVE = "ACTIVE",
  PENDING = "PENDING",
}

export enum ExecutionStatus {
  PENDING = "PENDING",
  IN_PROGRESS = "IN_PROGRESS",
  SUCCEEDED = "SUCCEEDED",
  FAILED = "FAILED",
  TIMED_OUT = "TIMED_OUT",
}

export enum UserActionType {
  CLICK = "CLICK",
  CONVERSATION = "CONVERSATION",
  QUERY = "QUERY",
  LOAD_MORE = "LOAD_MORE",
  DATE_RANGE_CHANGE = "DATE_RANGE_CHANGE",
  INTENT_CHANGE = "INTENT_CHANGE",
  DOWNLOAD_CSV = "DOWNLOAD_CSV",
}

export enum UserActionEntityType {
  PERSON = "PERSON",
  COMPANY = "COMPANY",
  ACTIVITIES = "ACTIVITIES",
  ACTIVITY = "ACTIVITY",
  COMPLETION = "COMPLETION",
  SEARCH = "SEARCH",
  FEED = "FEED",
}

export enum SearchPeopleFilterKey {
  JOB_TITLE = "job_title",
  PERSON_LOCATION = "person_location",
  COMPANY_NAME = "company_name",
  COMPANY_LOCATION = "company_location",
  COMPANY_INDUSTRY = "company_industry",
  NUM_EMPLOYEES_MIN = "num_employees_min",
  NUM_EMPLOYEES_MAX = "num_employees_max",
  ACTIVITY_TIME = "activity_time",
  INTENT = "intent",
}

export enum ActivityTimeValue {
  ANY_TIME = "anyTime",
  PAST_MONTH = "pastMonth",
  PAST_WEEK = "pastWeek",
  PAST_DAY = "pastDay",
}

export enum IntentValue {
  ALL = "ALL",
  HIRING_JOB = "HIRING_JOB",
  SEEKING_JOB = "SEEKING_JOB",
  SEEKING_RECOMMENDATION = "SEEKING_RECOMMENDATION",
  SHARING_OPINION = "SHARING_OPINION",
  HIRING = "HIRING",
  PROMOTING = "PROMOTING",
  EXPRESSING_INTEREST = "EXPRESSING_INTEREST",
}

export enum ResourceTypeValue {
  SOCIAL_MEDIA_POSTING = "SOCIAL_MEDIA_POSTING",
  JOB_POSTING = "JOB_POSTING",
}

export enum SourceValue {
  LINKEDIN = "LINKEDIN",
  FACEBOOK = "FACEBOOK",
}

export const INTENT_VALUE_LABEL: { [key: string]: string } = {
  HIRING: "Hiring",
  PROMOTING: "Promoting",
  EXPRESSING_INTEREST: "Expressing interest in",
};

export const RESOURCE_TYPE_VALUE_LABEL: { [key: string]: string } = {
  SOCIAL_MEDIA_POSTING: "Social Media Posting",
  JOB_POSTING: "Job Posting",
};

export const SERVICE_ACCOUNT_ID = "f81123f0-7011-702b-0c93-a8b04a049b9d"; // dev@nunchi.app
export const PUBLIC_FEED_IDS = [
  "3c165daa-36bf-4248-a8bd-09e8307ca327",
  "6e3c97ae-9fa0-413e-a0b7-afcd63be2e94",
  "75f1593a-bbaf-442d-a9e1-03564a18d33a",
];
export const TERMINAL_EXECUTION_STATUSES = new Set([
  ExecutionStatus.SUCCEEDED,
  ExecutionStatus.FAILED,
  ExecutionStatus.TIMED_OUT,
]);

const getIdToken = (user: CognitoUser) => {
  return user.getSignInUserSession()?.getIdToken().getJwtToken() || "";
};

const getAccessToken = (user: CognitoUser) => {
  return user.getSignInUserSession()?.getAccessToken().getJwtToken() || "";
};

const toISOString = (x: any) => {
  let now = new Date();
  switch (x) {
    case ActivityTimeValue.ANY_TIME:
      return undefined;
    case ActivityTimeValue.PAST_MONTH:
      now.setTime(now.getTime() - 24 * 60 * 60 * 1000 * 30);
      return now.toISOString();
    case ActivityTimeValue.PAST_WEEK:
      now.setTime(now.getTime() - 24 * 60 * 60 * 1000 * 7);
      return now.toISOString();
    case ActivityTimeValue.PAST_DAY:
      now.setTime(now.getTime() - 24 * 60 * 60 * 1000 * 1);
      return now.toISOString();
    default:
      return undefined;
  }
};

export const capitalizeFirstLetter = (
  s: string,
  lower: boolean = true
): string => {
  if (!s) {
    return s;
  }
  if (lower) {
    s = s.toLowerCase();
  }
  return s.charAt(0).toUpperCase() + s.slice(1).replaceAll("_", " ");
};

export const getWorkflowExecutionStatus = (
  state: string,
  status: string
): string => {
  if (state === "AUDIT" && (status === "COMPLETED" || status === "FAILED")) {
    return capitalizeFirstLetter(status);
  }
  return "In Progress";
};

export const getWorkflowExecutionDateString = (
  s: string | undefined
): string => {
  return s
    ? new Date(s).toLocaleString("en-US", { hour12: true }).replace(",", "")
    : "";
};

export const listWorkflows = async (
  user: CognitoUser,
  accountId: string | undefined = undefined
): Promise<any> => {
  const body: { [key: string]: string } = {};
  if (accountId && ADMIN_USERS.has(user.getUsername())) {
    body.account_id = accountId;
  }
  return fetch(`${NUNCHI_API_ROOT}/list-workflows`, {
    method: "POST",
    headers: {
      Authorization: getIdToken(user),
    },
    body: JSON.stringify(body),
  });
};

export const getWorkflowExecution = async (
  user: CognitoUser,
  workflowExecutionId: string,
  accountId: string | undefined = undefined
): Promise<any> => {
  const body: { [key: string]: string } = {
    workflow_execution_id: workflowExecutionId,
  };
  if (accountId && ADMIN_USERS.has(user.getUsername())) {
    body.account_id = accountId;
  }
  return fetch(`${NUNCHI_API_ROOT}/get-workflow-execution`, {
    method: "POST",
    headers: {
      Authorization: getIdToken(user),
    },
    body: JSON.stringify(body),
  });
};

export const listWorkflowExecutions = async (
  user: CognitoUser,
  workflowId: string,
  nextToken: string | undefined = undefined,
  accountId: string | undefined = undefined
): Promise<any> => {
  const body: { [key: string]: string | undefined } = {
    workflow_id: workflowId,
    next_token: nextToken,
  };
  if (accountId && ADMIN_USERS.has(user.getUsername())) {
    body.account_id = accountId;
  }

  return fetch(`${NUNCHI_API_ROOT}/list-workflow-executions`, {
    method: "POST",
    headers: {
      Authorization: getIdToken(user),
    },
    body: JSON.stringify(body),
  });
};

export const updateWorkflowExecution = async (
  user: CognitoUser,
  workflowExecutionId: string,
  accountId: string,
  status: string | undefined = undefined,
  output: string | undefined = undefined,
  audit: string | undefined = undefined
): Promise<any> => {
  const body: { [key: string]: string | undefined } = {
    workflow_execution_id: workflowExecutionId,
    status: status,
    output: output,
    audit: audit,
  };
  if (accountId && ADMIN_USERS.has(user.getUsername())) {
    body.account_id = accountId;
  }
  return fetch(`${NUNCHI_API_ROOT}/update-workflow-execution`, {
    method: "POST",
    headers: {
      Authorization: getIdToken(user),
    },
    body: JSON.stringify(body),
  });
};

export const startProspectingJob = async (
  user: CognitoUser,
  leadIds: string[],
  query: string
): Promise<any> => {
  const body: { [key: string]: any } = {
    lead_ids: leadIds,
    query: query,
  };
  return fetch(`${NUNCHI_API_ROOT}/start-prospecting-job`, {
    method: "POST",
    headers: {
      Authorization: getIdToken(user),
    },
    body: JSON.stringify(body),
  });
};

export const listLeads = async (
  user: CognitoUser,
  accountId: string | undefined = undefined,
  descriptions: string[] | undefined = undefined
): Promise<any> => {
  const body: { [key: string]: any } = {
    descriptions: descriptions,
  };
  if (accountId && ADMIN_USERS.has(user.getUsername())) {
    body.account_id = accountId;
  }
  return fetch(`${NUNCHI_API_ROOT}/list-leads`, {
    method: "POST",
    headers: {
      Authorization: getIdToken(user),
    },
    body: JSON.stringify(body),
  });
};

export const searchPeople = async (
  user: CognitoUser,
  accountId: string | undefined = undefined,
  query: string | undefined = undefined,
  filters: { [key: string]: string },
  offset: number = 0
): Promise<any> => {
  const body: { [key: string]: any } = {
    query: query,
    filters: filters,
    offset: offset,
  };
  if (accountId && ADMIN_USERS.has(user.getUsername())) {
    body.account_id = accountId;
  }
  return fetch(`${NUNCHI_API_ROOT}/search-people`, {
    method: "POST",
    headers: {
      Authorization: getIdToken(user),
    },
    body: JSON.stringify(body),
  });
};

export const batchPutLead = async (
  user: CognitoUser,
  leads: any[]
): Promise<any> => {
  const body: { [key: string]: any } = {
    leads,
  };
  return fetch(`${NUNCHI_API_ROOT}/batch-put-lead`, {
    method: "POST",
    headers: {
      Authorization: getIdToken(user),
    },
    body: JSON.stringify(body),
  });
};

export const updateLead = async (
  user: CognitoUser,
  leadId: string,
  meta: { [key: string]: any }
): Promise<any> => {
  const body: { [key: string]: any } = {
    lead_id: leadId,
    meta,
  };
  return fetch(`${NUNCHI_API_ROOT}/update-lead`, {
    method: "POST",
    headers: {
      Authorization: getIdToken(user),
    },
    body: JSON.stringify(body),
  });
};

export const getUser = async (user: CognitoUser): Promise<any> => {
  const body: { [key: string]: any } = {
    account_id: user.getUsername(),
  };
  return fetch(`${NUNCHI_API_ROOT}/get-user`, {
    method: "POST",
    headers: {
      Authorization: getIdToken(user),
    },
    body: JSON.stringify(body),
  });
};

export const updateUser = async (
  user: CognitoUser,
  companyName: string,
  companyWebsiteUrl: string,
  companyProductDescription: string
): Promise<any> => {
  const body: { [key: string]: any } = {
    company_name: companyName,
    company_website_url: companyWebsiteUrl,
    company_product_description: companyProductDescription,
  };
  return fetch(`${NUNCHI_API_ROOT}/update-user`, {
    method: "POST",
    headers: {
      Authorization: getIdToken(user),
    },
    body: JSON.stringify(body),
  });
};

export const putUserAction = async (
  user: CognitoUser,
  actionId: string,
  actionType: string,
  entityId: string,
  entityType: string,
  activityId: string,
  queryString: string
): Promise<any> => {
  const body: { [key: string]: any } = {
    action_id: actionId,
    action_type: actionType,
    activity_id: activityId,
    entity_id: entityId,
    entity_type: entityType,
    query_string: queryString,
  };
  return fetch(`${NUNCHI_API_ROOT}/put-user-action`, {
    method: "POST",
    headers: {
      Authorization: getIdToken(user),
    },
    body: JSON.stringify(body),
  });
};

export const getActivityWithPerson = async (
  user: CognitoUser,
  activityId: string
): Promise<any> => {
  const body: { [key: string]: any } = {
    activity_id: activityId,
  };
  return fetch(`${NUNCHI_API_ROOT}/get-activity-with-person`, {
    method: "POST",
    headers: {
      Authorization: getIdToken(user),
    },
    body: JSON.stringify(body),
  });
};

export const enrichFunctionCalling = async (
  query: string,
  context: string
): Promise<any> => {
  const body: { [key: string]: string } = {
    query,
    context,
  };
  return fetch(
    "https://3svoxd7gxrx5y56qc2bqf6wsta0xrhmx.lambda-url.us-west-2.on.aws/enrich-function-calling",
    {
      method: "POST",
      body: JSON.stringify(body),
    }
  );
};

export const complete = async (
  user: CognitoUser,
  messages: any[],
  context: string
): Promise<any> => {
  const body: { [key: string]: any } = {
    messages: messages,
    context: context,
  };
  return fetch(`${TOOLBOX_LAMBDA_URL}/complete`, {
    method: "POST",
    headers: {
      "X-Authorization": getAccessToken(user),
    },
    body: JSON.stringify(body),
  });
};

export const search = async (
  user: CognitoUser,
  query: string | undefined = undefined,
  filters: { [key: string]: string },
  page: number = 1
): Promise<any> => {
  const searchParams: { [key: string]: any } = {
    activityTime: filters.activity_time,
    intent: filters.intent,
    page: page.toString(),
  };
  if (query) {
    searchParams.query = query;
  }
  const searchParamsStr = new URLSearchParams(searchParams).toString();
  console.log(searchParamsStr);
  return fetch(`${NUNCHI_API_ROOT}/search?${searchParamsStr}`, {
    method: "GET",
    headers: {
      Authorization: getIdToken(user),
    },
  });
};

export const searchPeopleAsync = async (
  user: CognitoUser,
  accountId: string | undefined = undefined,
  query: string | undefined = undefined,
  filters: { [key: string]: string },
  offset: number = 0
): Promise<any> => {
  const searchParams: { [key: string]: any } = {
    activityTime: filters.activity_time,
    offset: offset.toString(),
  };
  if (query) {
    searchParams.query = query;
  }
  const searchParamsStr = new URLSearchParams(searchParams).toString();
  return fetch(`${TOOLBOX_LAMBDA_URL}/search-people?${searchParamsStr}`, {
    method: "GET",
    headers: {
      "X-Authorization": getAccessToken(user),
    },
  });
};

export const createFeed = async (
  user: CognitoUser,
  accountId: string | undefined = undefined,
  resourceType: string,
  resourceDescription: string,
  sources: string[],
  entity: object,
  updateFrequency: string,
  updateMaxCount: number | undefined = undefined
): Promise<any> => {
  const body: { [key: string]: any } = {
    resource_type: resourceType,
    resource_description: resourceDescription,
    sources: sources,
    entity: entity,
    update_frequency: updateFrequency,
  };
  if (updateMaxCount) {
    body.update_max_count = updateMaxCount;
  }
  if (accountId && ADMIN_USERS.has(user.getUsername())) {
    body.account_id = accountId;
  }
  return fetch(`${NUNCHI_API_ROOT}/create-feed`, {
    method: "POST",
    headers: {
      Authorization: getIdToken(user),
    },
    body: JSON.stringify(body),
  });
};

export const getFeed = async (
  user: CognitoUser,
  accountId: string | undefined = undefined,
  feedId: string
): Promise<any> => {
  const body: { [key: string]: any } = {
    feed_id: feedId,
  };
  if (accountId) {
    body.account_id = accountId;
  }
  return fetch(`${NUNCHI_API_ROOT}/get-feed`, {
    method: "POST",
    headers: {
      Authorization: getIdToken(user),
    },
    body: JSON.stringify(body),
  });
};

export const getFeedPublic = async (feedId: string): Promise<any> => {
  const body: { [key: string]: any } = {
    feed_id: feedId,
  };
  return fetch(`${SERVICE_PUBLIC_LAMBDA_URL}/get-feed`, {
    method: "POST",
    body: JSON.stringify(body),
  });
};

export const listFeeds = async (
  user: CognitoUser,
  accountId: string | undefined = undefined,
  nextToken: string | undefined = undefined
): Promise<any> => {
  const body: { [key: string]: string | undefined } = { next_token: nextToken };
  if (accountId) {
    body.account_id = accountId;
  }
  return fetch(`${NUNCHI_API_ROOT}/list-feeds`, {
    method: "POST",
    headers: {
      Authorization: getIdToken(user),
    },
    body: JSON.stringify(body),
  });
};

export const updateFeed = async (
  user: CognitoUser,
  accountId: string | undefined = undefined,
  feedId: string,
  updateFrequency: string | undefined = undefined,
  updateMaxCount: number | undefined = undefined,
  entity: any | undefined = undefined
): Promise<any> => {
  const body: { [key: string]: any } = {
    feed_id: feedId,
  };
  if (updateFrequency) {
    body.update_frequency = updateFrequency;
  }
  if (updateMaxCount) {
    body.update_max_count = updateMaxCount;
  }
  if (entity) {
    body.entity = entity;
  }
  if (accountId && ADMIN_USERS.has(user.getUsername())) {
    body.account_id = accountId;
  }
  return fetch(`${NUNCHI_API_ROOT}/update-feed`, {
    method: "POST",
    headers: {
      Authorization: getIdToken(user),
    },
    body: JSON.stringify(body),
  });
};

export const listFeedItems = async (
  user: CognitoUser,
  accountId: string | undefined = undefined,
  feedId: string,
  startTime: string | undefined = undefined,
  limit: number | undefined = undefined,
  nextToken: string | undefined = undefined
): Promise<any> => {
  const body: { [key: string]: string | number | undefined } = {
    feed_id: feedId,
    start_time: toISOString(startTime),
    limit: limit,
    next_token: nextToken,
  };
  if (accountId) {
    body.account_id = accountId;
  }

  return fetch(`${NUNCHI_API_ROOT}/list-feed-items`, {
    method: "POST",
    headers: {
      Authorization: getIdToken(user),
    },
    body: JSON.stringify(body),
  });
};

export const listFeedItemsPublic = async (
  feedId: string,
  startTime: string | undefined = undefined,
  limit: number | undefined = undefined,
  nextToken: string | undefined = undefined
): Promise<any> => {
  const body: { [key: string]: string | number | undefined } = {
    feed_id: feedId,
    start_time: toISOString(startTime),
    limit: limit,
    next_token: nextToken,
  };

  return fetch(`${SERVICE_PUBLIC_LAMBDA_URL}/list-feed-items`, {
    method: "POST",
    body: JSON.stringify(body),
  });
};

export const createFeedSubscription = async (
  user: CognitoUser,
  accountId: string | undefined = undefined,
  feedId: string
): Promise<any> => {
  const body: { [key: string]: any } = {
    feed_id: feedId,
  };
  if (accountId && ADMIN_USERS.has(user.getUsername())) {
    body.account_id = accountId;
  }
  return fetch(`${NUNCHI_API_ROOT}/create-feed-subscription`, {
    method: "POST",
    headers: {
      Authorization: getIdToken(user),
    },
    body: JSON.stringify(body),
  });
};

export const listFeedSubscriptions = async (
  user: CognitoUser,
  accountId: string | undefined = undefined,
  feedId: string,
  nextToken: string | undefined = undefined
): Promise<any> => {
  const body: { [key: string]: string | undefined } = {
    feed_id: feedId,
    next_token: nextToken,
  };
  if (accountId && ADMIN_USERS.has(user.getUsername())) {
    body.account_id = accountId;
  }
  return fetch(`${NUNCHI_API_ROOT}/list-feed-subscriptions`, {
    method: "POST",
    headers: {
      Authorization: getIdToken(user),
    },
    body: JSON.stringify(body),
  });
};

export const deleteFeedSubscription = async (
  user: CognitoUser,
  accountId: string | undefined = undefined,
  feedSubscriptionId: string
): Promise<any> => {
  const body: { [key: string]: any } = {
    feed_subscription_id: feedSubscriptionId,
  };
  if (accountId && ADMIN_USERS.has(user.getUsername())) {
    body.account_id = accountId;
  }
  return fetch(`${NUNCHI_API_ROOT}/delete-feed-subscription`, {
    method: "POST",
    headers: {
      Authorization: getIdToken(user),
    },
    body: JSON.stringify(body),
  });
};

export const searchProducts = async (
  q: string | undefined = undefined,
  sort: string | undefined = undefined,
  order: string | undefined = undefined
): Promise<any> => {
  const searchParams: { [key: string]: any } = {};
  if (q) {
    searchParams.q = q;
  }
  if (sort) {
    searchParams.sort = sort;
  }
  if (order) {
    searchParams.order = order;
  }

  const searchParamsStr = new URLSearchParams(searchParams).toString();
  return fetch(`${PRODUCT_SEARCH_LAMBDA_URL}/search?${searchParamsStr}`, {
    method: "GET",
  });
};

export const getFunction = async (
  user: CognitoUser,
  accountId: string | undefined = undefined,
  functionId: string
): Promise<any> => {
  const body: { [key: string]: any } = {
    function_id: functionId,
  };
  if (accountId) {
    body.account_id = accountId;
  }
  return fetch(`${EXFUNC_SERVICE_API_ROOT}/get-function`, {
    method: "POST",
    headers: {
      "x-api-key": user.getUsername(),
    },
    body: JSON.stringify(body),
  });
};

export const listFunctions = async (
  user: CognitoUser,
  accountId: string | undefined = undefined,
  nextToken: string | undefined = undefined
): Promise<any> => {
  const body: { [key: string]: string | undefined } = { next_token: nextToken };
  if (accountId) {
    body.account_id = accountId;
  }
  return fetch(`${EXFUNC_SERVICE_API_ROOT}/list-functions`, {
    method: "POST",
    headers: {
      "x-api-key": user.getUsername(),
    },
    body: JSON.stringify(body),
  });
};

export const invokeFunction = async (
  user: CognitoUser,
  accountId: string | undefined = undefined,
  functionId: string,
  input: { [key: string]: any }
): Promise<any> => {
  const body: { [key: string]: any } = {
    function_id: functionId,
    input: input,
  };
  if (accountId) {
    body.account_id = accountId;
  }
  return fetch(`${EXFUNC_SERVICE_API_ROOT}/invoke-function`, {
    method: "POST",
    headers: {
      "x-api-key": user.getUsername(),
    },
    body: JSON.stringify(body),
  });
};

export const createTask = async (
  user: CognitoUser,
  accountId: string | undefined = undefined,
  name: string,
  description: string
): Promise<any> => {
  const body: { [key: string]: any } = {
    name: name,
    description: description,
  };
  if (accountId) {
    body.account_id = accountId;
  }
  return fetch(`${EXFUNC_SERVICE_API_ROOT}/create-task`, {
    method: "POST",
    headers: {
      "x-api-key": user.getUsername(),
    },
    body: JSON.stringify(body),
  });
};

export const getTask = async (
  user: CognitoUser,
  accountId: string | undefined = undefined,
  taskId: string
): Promise<any> => {
  const body: { [key: string]: any } = {
    task_id: taskId,
  };
  if (accountId) {
    body.account_id = accountId;
  }
  return fetch(`${EXFUNC_SERVICE_API_ROOT}/get-task`, {
    method: "POST",
    headers: {
      "x-api-key": user.getUsername(),
    },
    body: JSON.stringify(body),
  });
};

export const listTasks = async (
  user: CognitoUser,
  accountId: string | undefined = undefined,
  nextToken: string | undefined = undefined
): Promise<any> => {
  const body: { [key: string]: string | undefined } = { next_token: nextToken };
  if (accountId) {
    body.account_id = accountId;
  }
  return fetch(`${EXFUNC_SERVICE_API_ROOT}/list-tasks`, {
    method: "POST",
    headers: {
      "x-api-key": user.getUsername(),
    },
    body: JSON.stringify(body),
  });
};

export const getExecution = async (
  user: CognitoUser,
  accountId: string | undefined = undefined,
  executionId: string
): Promise<any> => {
  const body: { [key: string]: any } = {
    execution_id: executionId,
  };
  if (accountId) {
    body.account_id = accountId;
  }
  return fetch(`${EXFUNC_SERVICE_API_ROOT}/get-execution`, {
    method: "POST",
    headers: {
      "x-api-key": user.getUsername(),
    },
    body: JSON.stringify(body),
  });
};

export const listExecutions = async (
  user: CognitoUser,
  accountId: string | undefined = undefined,
  taskId: string | undefined,
  nextToken: string | undefined = undefined
): Promise<any> => {
  const body: { [key: string]: string | undefined } = {
    task_id: taskId,
    next_token: nextToken,
  };
  if (accountId) {
    body.account_id = accountId;
  }
  return fetch(`${EXFUNC_SERVICE_API_ROOT}/list-executions`, {
    method: "POST",
    headers: {
      "x-api-key": user.getUsername(),
    },
    body: JSON.stringify(body),
  });
};

export const startExecution = async (
  user: CognitoUser,
  accountId: string | undefined = undefined,
  taskId: string,
  input: object
): Promise<any> => {
  const body: { [key: string]: any } = {
    task_id: taskId,
    input: input,
  };
  if (accountId) {
    body.account_id = accountId;
  }
  return fetch(`${EXFUNC_SERVICE_API_ROOT}/start-execution`, {
    method: "POST",
    headers: {
      "x-api-key": user.getUsername(),
    },
    body: JSON.stringify(body),
  });
};
