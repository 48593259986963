import Context from "./context";
import Box from "./components/Box";
import Audit from "./pages/Audit";
import Auth from "./pages/Auth";
import Feed from "./pages/Feed";
import Feeds from "./pages/Feeds";
import FeedEdit from "./pages/FeedEdit";
import Landing from "./pages/Landing";
import NewFeed from "./pages/NewFeed";
import NotFound from "./pages/NotFound";
import OAuth from "./pages/OAuth";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Terms from "./pages/Terms";
import Unsubscribe from "./pages/Unsubscribe";
import Profile from "./pages/Profile";
import Search from "./pages/Search";
import ApiReference from "./pages/ApiReference";
import FeedPublic from "./pages/FeedPublic";
import Products from "./pages/Products";
import Account from "./components/Account";
import Memo from "./pages/Memo";
import TaskPage from "./pages/TaskPage";
import TasksPage from "./pages/TasksPage";
import ExecutionPage from "./pages/ExecutionPage";
import FunctionsPage from "./pages/FunctionsPage";
import FunctionPage from "./pages/FunctionPage";
import AgencyLeadsPage from "./pages/AgencyLeadsPage";

import { useAuthenticator, Authenticator } from "@aws-amplify/ui-react";
import { setupIonicReact, IonApp } from "@ionic/react";
import { useState } from "react";
import {
  createBrowserRouter,
  useLocation,
  Navigate,
  RouterProvider,
  useSearchParams,
} from "react-router-dom";
import {
  ADMIN_USERS,
  SAMPLE_AGENCY_LEAD_FEED_ID,
  MAIN_AGENCY_LEAD_FEED_ID,
} from "./constants";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import Demo from "./pages/Demo";

setupIonicReact();

function RequireAuth({ children }: { children: any }) {
  const location = useLocation();
  const { route } = useAuthenticator((context) => [context.route]);
  if (route !== "authenticated") {
    if (route === "idle") {
      return <NotFound />;
    }
    return <Navigate to="/auth" state={{ from: location }} replace />;
  }
  return children;
}

function RequireAdmin({ children }: { children: any }) {
  const { user } = useAuthenticator((context) => [context.user]);
  if (!ADMIN_USERS.has(user.getUsername())) {
    return <NotFound />;
  }
  return children;
}

function Redirect({
  to,
  replace,
  state,
}: {
  to: string;
  replace?: boolean;
  state?: any;
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  return (
    <Navigate
      to={{ pathname: to, search: searchParams.toString() }}
      replace={replace}
      state={state}
    />
  );
}

const router = createBrowserRouter([
  {
    path: "*",
    element: <NotFound />,
  },
  {
    path: "/",
    element: <Landing />,
  },
  {
    path: "/auth",
    element: <Auth />,
  },
  {
    path: "/oauth",
    element: <OAuth />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/terms",
    element: <Terms />,
  },
  {
    path: "/unsubscribe",
    element: <Unsubscribe />,
  },
  {
    path: "/home",
    element: <Redirect to="/feeds" replace />,
  },
  {
    path: "/dashboard",
    element: <Redirect to="/feeds" replace />,
  },
  {
    path: "/products",
    element: <Products />,
  },
  {
    path: "/beta",
    children: [
      {
        path: ":feedId",
        children: [
          {
            index: true,
            element: <FeedPublic />,
          },
        ],
      },
      {
        index: true,
        element: <FeedPublic />,
      },
    ],
  },
  {
    path: "/feeds",
    children: [
      {
        path: ":feedId",
        children: [
          {
            path: "edit",
            element: (
              <RequireAuth>
                <Account />
                <FeedEdit />
              </RequireAuth>
            ),
          },
          {
            index: true,
            element: (
              <RequireAuth>
                <Account />
                <Feed />
              </RequireAuth>
            ),
          },
        ],
      },
      {
        path: "create",
        element: (
          <RequireAuth>
            <Account />
            <NewFeed />
          </RequireAuth>
        ),
      },
      {
        index: true,
        element: (
          <RequireAuth>
            <Account />
            <Feeds />
          </RequireAuth>
        ),
      },
    ],
  },
  // {
  //   path: "/workflows",
  //   children: [
  //     {
  //       path: ":workflowId",
  //       children: [
  //         {
  //           path: ":workflowExecutionId",
  //           element: (
  //             <RequireAuth>
  //               <Account />
  //               <WorkflowExecution />
  //             </RequireAuth>
  //           ),
  //         },
  //         {
  //           index: true,
  //           element: (
  //             <RequireAuth>
  //               <Account />
  //               <Workflow />
  //             </RequireAuth>
  //           ),
  //         },
  //       ],
  //     },
  //     {
  //       index: true,
  //       element: (
  //         <RequireAuth>
  //           <Account />
  //           <Workflows />
  //         </RequireAuth>
  //       ),
  //     },
  //   ],
  // },
  // {
  //   path: "/leads",
  //   children: [
  //     {
  //       index: true,
  //       element: (
  //         <RequireAuth>
  //           <RequireAdmin>
  //             <Account />
  //             <Leads />
  //           </RequireAdmin>
  //         </RequireAuth>
  //       ),
  //     },
  //   ]
  // },
  {
    path: "/search",
    element: (
      <RequireAuth>
        <RequireAdmin>
          <Account />
          <Search />
        </RequireAdmin>
      </RequireAuth>
    ),
  },
  {
    path: "/demo",
    element: <Demo />,
  },
  {
    path: "/memo",
    element: <Memo />,
  },
  {
    path: "/account",
    children: [
      // {
      //   path: "audit",
      //   element: (
      //     <RequireAuth>
      //       <RequireAdmin>
      //         <Account />
      //         <Audit />
      //       </RequireAdmin>
      //     </RequireAuth>
      //   ),
      // },
      // {
      //   path: "profile",
      //   element: (
      //     <RequireAuth>
      //       <Account />
      //       <Profile />
      //     </RequireAuth>
      //   ),
      // },
      {
        index: true,
        element: (
          <RequireAuth>
            <Account />
            <Profile />
          </RequireAuth>
        ),
      },
      /*{
        path: "usage",
        element: (
          <RequireAuth>
            <Account />
            <Usage />
          </RequireAuth>
        ),
      },
      {
        index: true,
        element: (
          <RequireAuth>
            <Account />
            <Usage />
          </RequireAuth>
        ),
      },*/
    ],
  },
  {
    path: "/docs",
    children: [
      {
        path: "api-reference",
        element: <ApiReference />,
      },
      {
        index: true,
        element: <Redirect to="api-reference" replace />,
      },
    ],
  },
  {
    path: "/v2",
    children: [
      {
        path: "tasks",
        children: [
          {
            path: ":taskId",
            element: (
              <RequireAuth>
                <TaskPage />
              </RequireAuth>
            ),
          },
          {
            index: true,
            element: (
              <RequireAuth>
                <TasksPage />
              </RequireAuth>
            ),
          },
        ],
      },
      {
        path: "executions",
        children: [
          {
            path: ":executionId",
            element: (
              <RequireAuth>
                <ExecutionPage />
              </RequireAuth>
            ),
          },
          {
            index: true,
            element: (
              <RequireAuth>
                <TasksPage />
              </RequireAuth>
            ),
          },
        ],
      },
      {
        path: "functions",
        children: [
          {
            path: ":functionId",
            element: (
              <RequireAuth>
                <FunctionPage />
              </RequireAuth>
            ),
          },
          {
            index: true,
            element: (
              <RequireAuth>
                <FunctionsPage />
              </RequireAuth>
            ),
          },
        ],
      },
      {
        index: true,
        element: <Redirect to="tasks" replace />,
      },
    ],
  },
  {
    path: "/agency-leads",
    children: [
      {
        path: "sample",
        element: <AgencyLeadsPage isPublic={true} />,
      },
      {
        index: true,
        element: (
          <RequireAuth>
            <AgencyLeadsPage isPublic={false} />
          </RequireAuth>
        ),
      },
    ],
  },
]);

const App: React.FC = () => {
  const [conversationLog, setConversationLog] = useState<(typeof Box)[]>([]);
  const [sessionId, setSessionId] = useState("");
  const [isLoadingCompletionApiResponse, setIsLoadingCompletionApiResponse] =
    useState(false);

  return (
    <Context.Provider
      value={{
        conversationLog,
        setConversationLog,
        sessionId,
        setSessionId,
        isLoadingCompletionApiResponse,
        setIsLoadingCompletionApiResponse,
      }}
    >
      <Authenticator.Provider>
        <IonApp>
          <RouterProvider router={router} />
        </IonApp>
      </Authenticator.Provider>
    </Context.Provider>
  );
};

export default App;
