import React from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import Drawer from "../components/Drawer";
import Task from "../components/Task";

import { useNavigate, useParams } from "react-router";
import {
  AppBar,
  Box,
  Breadcrumbs,
  CssBaseline,
  Link,
  Toolbar,
  Typography,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { useAuthenticator } from "@aws-amplify/ui-react";

import { DRAWER_WIDTH } from "../constants";
import { darkTheme } from "../components/Theme";
import { SERVICE_ACCOUNT_ID, getTask } from "../api/Nunchi";

import "@aws-amplify/ui-react/styles.css";

const TaskPage: React.FC = () => {
  const navigate = useNavigate();
  const { taskId } = useParams();
  const { user } = useAuthenticator((context) => [context.user]);
  const [breadcrumbs, setBreadcrumbs] = React.useState<any>([
    <Link
      underline="hover"
      key="1"
      color="inherit"
      sx={{ cursor: "pointer" }}
      onClick={() => navigate("/v2/tasks")}
    >
      Tasks
    </Link>,
  ]);

  React.useEffect(() => {
    (async function () {
      if (taskId) {
        var newTask = null;
        const response = await getTask(user, SERVICE_ACCOUNT_ID, taskId);
        if (response.ok) {
          const responseData = await response.json();
          newTask = responseData.task;
        }

        if (!newTask) {
          const response = await getTask(user, undefined, taskId);
          if (response.ok) {
            const responseData = await response.json();
            newTask = responseData.task;
          } else {
            alert("Unexpected error occurred");
          }
        }

        const newBreadcrumbs = [
          ...breadcrumbs,
          <Typography key="2" color="text.primary">
            {newTask.name}
          </Typography>,
        ];
        setBreadcrumbs(newBreadcrumbs);
      }
    })();
  }, []);

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Box minHeight={"100%"} overflow={"auto"} sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: `calc(100% - ${DRAWER_WIDTH}px)`,
            ml: `${DRAWER_WIDTH}px`,
          }}
        >
          <Toolbar>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              {breadcrumbs}
            </Breadcrumbs>
          </Toolbar>
        </AppBar>
        <Drawer />
        <Box
          component="main"
          sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
        >
          <Toolbar />
          <Task />
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default TaskPage;
