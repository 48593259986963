import React from "react";
import moment from "moment";

import { useNavigate } from "react-router";
import {
  Box,
  Button,
  Modal,
  Paper,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useAuthenticator } from "@aws-amplify/ui-react";

import { StyledTableCell, StyledTableRow } from "./CustomTable";
import {
  SERVICE_ACCOUNT_ID,
  Task,
  TaskStatus,
  capitalizeFirstLetter,
  createTask,
  listTasks,
} from "../api/Nunchi";

import "@aws-amplify/ui-react/styles.css";

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  width: "50%",
};

const Tasks: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuthenticator((context) => [context.user]);
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [tasks, setTasks] = React.useState<Task[]>([]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleCreate = () => {
    (async function () {
      const createTaskResponse = await createTask(
        user,
        undefined,
        name,
        description
      );
      if (createTaskResponse.ok) {
        setOpen(false);
        setName("");
        setDescription("");

        const newTasks: Task[] = [];
        if (user.getUsername() !== SERVICE_ACCOUNT_ID) {
          const listTasksResponse = await listTasks(user, SERVICE_ACCOUNT_ID);
          if (listTasksResponse.ok) {
            const listTasksResponseData = await listTasksResponse.json();
            newTasks.push(...listTasksResponseData.tasks);
          } else {
            alert("Unexpected error occurred");
          }
        }

        const listTasksResponse = await listTasks(user);
        if (listTasksResponse.ok) {
          const listTasksResponseData = await listTasksResponse.json();
          newTasks.push(...listTasksResponseData.tasks);
        } else {
          alert("Unexpected error occurred");
        }

        setTasks(newTasks);
      } else {
        alert("Unexpected error occurred");
      }
    })();
  };

  React.useEffect(() => {
    (async function () {
      const newTasks: Task[] = [];
      if (user.getUsername() !== SERVICE_ACCOUNT_ID) {
        const response = await listTasks(user, SERVICE_ACCOUNT_ID);
        if (response.ok) {
          const responseData = await response.json();
          newTasks.push(...responseData.tasks);
        } else {
          alert("Unexpected error occurred");
        }
      }

      const response = await listTasks(user);
      if (response.ok) {
        const responseData = await response.json();
        newTasks.push(...responseData.tasks);
      } else {
        alert("Unexpected error occurred");
      }

      setTasks(newTasks);
    })();
  }, []);

  return (
    <Stack direction="column" spacing={2} width={"100%"}>
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography variant={"h6"} color="text.primary">
          Tasks
        </Typography>
        <Button
          variant="contained"
          disableElevation
          onClick={handleOpen}
          sx={{ width: "100px" }}
        >
          Create
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell style={{ width: 200 }} align="right">
                Status
              </StyledTableCell>
              <StyledTableCell style={{ width: 200 }} align="right">
                Creation Date
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tasks.map((item) => (
              <StyledTableRow
                key={item.name}
                sx={{
                  cursor:
                    item.status === TaskStatus.ACTIVE ? "pointer" : "default",
                }}
                onClick={() => {
                  if (item.status === TaskStatus.ACTIVE) {
                    navigate(`/v2/tasks/${item.task_id}`);
                  }
                }}
              >
                <StyledTableCell>{item.name}</StyledTableCell>
                <StyledTableCell style={{ width: 200 }} align="right">
                  {capitalizeFirstLetter(item.status)}
                </StyledTableCell>
                <StyledTableCell style={{ width: 200 }} align="right">
                  {moment(item.creation_time).local().format("l LT")}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Stack direction="column" spacing={2} width={"100%"}>
            <Typography variant={"h6"} color="text.primary">
              New Task
            </Typography>
            <TextField
              fullWidth
              label="Task name"
              value={name}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setName(event.target.value);
              }}
            />
            <TextField
              multiline
              rows={10}
              placeholder="Describe step by step..."
              value={description}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setDescription(event.target.value);
              }}
            />
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"flex-end"}
            >
              <Button
                variant="contained"
                disableElevation
                onClick={handleCreate}
                sx={{ width: "100px" }}
              >
                Create
              </Button>
            </Box>
          </Stack>
        </Box>
      </Modal>
    </Stack>
  );
};

export default Tasks;
