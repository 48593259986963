import React from "react";
import Highlight from "react-highlight";

import Executions from "./Executions";

import { ClipLoader } from "react-spinners";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Modal,
  Stack,
  TextField,
  Typography,
  alpha,
} from "@mui/material";
import { useAuthenticator } from "@aws-amplify/ui-react";

import { StyledBoxLabel } from "../components/CustomBox";
import {
  CustomTabPanel,
  StyledTabs,
  StyledTab,
  a11yProps,
} from "../components/CustomTabs";
import { darkTheme } from "./Theme";
import { SERVICE_ACCOUNT_ID, getTask, startExecution } from "../api/Nunchi";

import "@aws-amplify/ui-react/styles.css";

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  width: "33%",
};

const Task: React.FC = () => {
  const navigate = useNavigate();
  const { taskId } = useParams();
  const { user } = useAuthenticator((context) => [context.user]);
  const [isRunning, setIsRunning] = React.useState(false);
  const [task, setTask] = React.useState<any>({});
  const [input, setInput] = React.useState<any>({});
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const onRun = () => {
    if (taskId) {
      setIsRunning(true);
      (async function () {
        const response = await startExecution(
          user,
          task.account_id,
          task.task_id,
          input
        );
        if (response.ok) {
          const responseData = await response.json();
          setIsRunning(false);
          setOpen(false);
          navigate(`/v2/executions/${responseData.execution_id}`);
        } else {
          setIsRunning(false);
          setOpen(false);
          alert("Unexpected error occurred");
        }
      })();
    }
  };

  React.useEffect(() => {
    (async function () {
      if (taskId) {
        var newTask = null;
        if (user.getUsername() !== SERVICE_ACCOUNT_ID) {
          const response = await getTask(user, SERVICE_ACCOUNT_ID, taskId);
          if (response.ok) {
            const responseData = await response.json();
            newTask = responseData.task;
          }
        }

        if (!newTask) {
          const response = await getTask(user, undefined, taskId);
          if (response.ok) {
            const responseData = await response.json();
            newTask = responseData.task;
          } else {
            alert("Unexpected error occurred");
          }
        }

        setTask(newTask);
      }
    })();
  }, []);

  return (
    <Stack direction="column" spacing={2} width={"100%"}>
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography variant={"h6"} color="text.primary">
          {task.name}
        </Typography>
        <Button
          variant="contained"
          disableElevation
          onClick={handleOpen}
          sx={{ width: "100px" }}
        >
          Run
        </Button>
      </Box>
      <StyledTabs value={value} onChange={handleChange} aria-label="tabs">
        <StyledTab label="Executions" {...a11yProps(0)} />
        <StyledTab label="Definition" {...a11yProps(1)} />
      </StyledTabs>
      <CustomTabPanel value={value} index={0}>
        <Executions taskId={taskId} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Stack direction="column" spacing={2} width={"100%"}>
          <Box flex={1}>
            <StyledBoxLabel>Procedure</StyledBoxLabel>
            <Box
              width={"100%"}
              border={`0.1px solid ${alpha(
                darkTheme.palette.primary.light,
                0.2
              )}`}
              p={3}
            >
              <Typography style={{ whiteSpace: "pre-line" }}>
                {task.procedure}
              </Typography>
            </Box>
          </Box>
        </Stack>
      </CustomTabPanel>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          {task.parameters && (
            <Stack direction="column" spacing={2} width={"100%"}>
              <Typography variant={"h6"} color="text.primary">
                Input
              </Typography>
              {Object.keys(task.parameters.properties).map(
                (k: string, i: number) => (
                  <TextField
                    fullWidth
                    multiline
                    key={i}
                    label={k}
                    value={input[k]}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      const newInput = { ...input };
                      newInput[k] = event.target.value;
                      setInput(newInput);
                    }}
                  />
                )
              )}

              <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"flex-end"}
              >
                <Button
                  variant="contained"
                  disableElevation
                  onClick={onRun}
                  sx={{ width: "100px" }}
                >
                  {isRunning ? <ClipLoader size={"25px"} /> : <span>Run</span>}
                </Button>
              </Box>
            </Stack>
          )}
        </Box>
      </Modal>
    </Stack>
  );
};

export default Task;
