import React, { KeyboardEventHandler, useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import Home from "../components/Home";
import PersonList from "../components/PersonList";

import { Auth } from "aws-amplify";
import { useAuthenticator } from "@aws-amplify/ui-react";
import {
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonList,
  IonPopover,
  IonText,
} from "@ionic/react";
import {
  closeOutline,
  closeSharp,
  ellipsisHorizontalOutline,
  ellipsisHorizontalSharp,
  searchOutline,
  searchSharp,
  optionsOutline,
  optionsSharp,
  refreshOutline,
  refreshSharp,
} from "ionicons/icons";
import {
  InputAdornment,
  MenuItem,
  Popover,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { MultiValue } from "react-select";
import { ClipLoader } from "react-spinners";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import {
  Activity,
  ActivityTimeValue,
  IntentValue,
  PersonExternal,
  SearchPeopleFilterKey,
  UserActionEntityType,
  UserActionType,
  getActivityWithPerson,
  getUser,
  putUserAction,
  search,
  searchPeople,
  searchPeopleAsync,
} from "../api/Nunchi";
import { v4 as uuidv4 } from "uuid";
import {
  usePopupState,
  bindTrigger,
  bindPopover,
} from "material-ui-popup-state/hooks";
import "@aws-amplify/ui-react/styles.css";

import "./Search.css";

interface Option {
  readonly label: string;
  readonly value: string;
}

const UTF8_DECODER = new TextDecoder();
const IS_LOCALHOST = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

const Dashboard: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const defaultSearchValue = searchParams.get("query") || "";
  const defaultIntentValue = Object.values(IntentValue).includes(
    searchParams.get("intent") as IntentValue
  )
    ? (searchParams.get("intent") as IntentValue)
    : IntentValue.ALL;
  const defaultTimeValue = Object.values(ActivityTimeValue).includes(
    searchParams.get("activityTime") as ActivityTimeValue
  )
    ? (searchParams.get("activityTime") as ActivityTimeValue)
    : ActivityTimeValue.PAST_MONTH;

  const navigate = useNavigate();
  const [persons, setPersons] = useState<Map<string, PersonExternal>>(
    new Map()
  );
  const [activityIds, setActivityIds] = useState<string[]>([]);
  const [activities, setActivities] = useState<{ [key: string]: any }>({});
  const [entityToActivitiesMap, setEntityToActivitiesMap] = useState<
    Map<string, Activity[]>
  >(new Map());
  const [activityToEntityMap, setActivityToEntityMap] = useState<
    Map<string, string>
  >(new Map());
  const [isLoading, setIsLoading] = useState(false);
  const [isActivityLoading, setIsActivityLoading] = useState(false);
  const [searchValue, setSearchValue] = useState(defaultSearchValue);
  const [currentSearchedValue, setCurrentSearchedValue] =
    useState(defaultSearchValue);
  const [jobTitleValue, setJobTitleValue] = useState<readonly Option[]>([]);
  const [jobTitleInputValue, setJobTitleInputValue] = useState("");
  const [personLocationValue, setPersonLocationValue] = useState<
    readonly Option[]
  >([]);
  const [personLocationInputValue, setPersonLocationInputValue] = useState("");
  const [companyNameValue, setCompanyNameValue] = useState<readonly Option[]>(
    []
  );
  const [companyNameInputValue, setCompanyNameInputValue] = useState("");
  const [companyLocationValue, setCompanyLocationValue] = useState<
    readonly Option[]
  >([]);
  const [companyLocationInputValue, setCompanyLocationInputValue] =
    useState("");
  const [companyIndustryValue, setCompanyIndustryValue] = useState<
    readonly Option[]
  >([]);
  const [companyIndustryInputValue, setCompanyIndustryInputValue] =
    useState("");
  const [numEmployeesMinValue, setNumEmployeesMinValue] = useState("");
  const [numEmployeesMinValueError, setNumEmployeesMinValueError] =
    useState(false);
  const [numEmployeesMinErrorText, setNumEmployeesMinErrorText] = useState("");
  const [numEmployeesMaxValue, setNumEmployeesMaxValue] = useState("");
  const [numEmployeesMaxValueError, setNumEmployeesMaxValueError] =
    useState(false);
  const [numEmployeesMaxErrorText, setNumEmployeesMaxErrorText] = useState("");
  const [jobTitleOptions, setJobTitleOptions] = useState<Option[]>([]);
  const [personLocationOptions, setPersonLocationOptions] = useState<Option[]>(
    []
  );
  const [companyNameOptions, setCompanyNameOptions] = useState<Option[]>([]);
  const [companyLocationOptions, setCompanyLocationOptions] = useState<
    Option[]
  >([]);
  const [companyIndustryOptions, setCompanyIndustryOptions] = useState<
    Option[]
  >([]);
  const [filters, setFilters] = useState({});
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(1);
  const [endOfResultReached, setEndOfResultReached] = useState(false);
  const [timeValue, setTimeValue] = useState<string>(defaultTimeValue);
  const [intentValue, setIntentValue] = useState<string>(defaultIntentValue);
  const popupState = usePopupState({
    variant: "popover",
    popupId: "demoPopover",
  });

  const { user } = useAuthenticator((context) => [context.user]);

  const debugLog = (message?: any, ...optionalParams: any[]): void => {
    if (IS_LOCALHOST) {
      console.log(message, optionalParams);
    }
  };

  const createOption = (label: string) => ({
    label,
    value: label,
  });

  const exportToCsv = () => {
    const head = [
      [
        "entity_url",
        "name",
        "tagline",
        "activity_url",
        "activity_body",
        "parent_activity_body",
        "activity_posted_date",
      ],
    ];
    const body = [];
    for (let person of persons.values()) {
      let activities = entityToActivitiesMap.get(person.person_id);
      const entityUrl = person.person_id.startsWith("https://")
        ? person.person_id
        : "";
      if (!activities) {
        let item = [entityUrl, person.name, person.job_title, "", "", "", ""];
        body.push(item);
      } else {
        for (let activity of activities) {
          let item = [
            entityUrl,
            person.name,
            person.job_title,
            activity.url,
            JSON.stringify(activity.article_body),
            JSON.stringify(activity.parent_article_body),
            activity.activity_time,
          ];
          body.push(item);
        }
      }
    }
    const content = [...head, ...body]
      .map((cells) => cells.map(serialiseCellValue).join(","))
      .join("\n");

    downloadFile(
      "export.csv",
      new Blob([content], { type: "text/csv;charset=utf-8;" })
    );
  };

  function serialiseCellValue(value: unknown) {
    if (typeof value === "string") {
      const formattedValue = value.replace(/"/g, '""');
      return formattedValue.includes(",")
        ? `"${formattedValue}"`
        : formattedValue;
    }
    return value;
  }

  function downloadFile(fileName: string, data: Blob) {
    const downloadLink = document.createElement("a");
    downloadLink.download = fileName;
    const url = URL.createObjectURL(data);
    downloadLink.href = url;
    downloadLink.click();
    URL.revokeObjectURL(url);
  }

  const recordUserAction = (
    actionType: string,
    entityType: string,
    queryString: string
  ) => {
    (async function () {
      const actionId = uuidv4();
      await putUserAction(
        user,
        actionId,
        actionType,
        "",
        entityType,
        "",
        queryString
      );
    })();
  };

  const handleSearchPeopleAsyncResponse = (
    buffer: string,
    inputOffset: number,
    searchQuery: string
  ) => {
    debugLog(buffer);
    const responseArray = buffer.split("\n");
    responseArray.pop();
    debugLog("Array: ", responseArray);
    if (
      responseArray[responseArray.length - 1] &&
      responseArray[responseArray.length - 1].startsWith("Offset") &&
      responseArray[responseArray.length - 1].split(":").length === 2
    ) {
      const lastItem = responseArray.pop();
      const splitted = lastItem!.split(":");
      const newOffset = Number(splitted[splitted.length - 1]);
      if (newOffset === 0) {
        setEndOfResultReached(true);
      } else {
        setOffset(inputOffset + newOffset);
      }
      debugLog("Rest of the response array: ", responseArray);
    }
    if (responseArray !== activityIds) {
      debugLog("Updating activity IDs");
      setActivityIds(responseArray);
    }
    setCurrentSearchedValue(searchQuery);
  };

  const callSearchPeopleAsync = (
    searchQuery: string,
    inputFilters: any,
    inputOffset: number
  ) => {
    (async function () {
      const response = await searchPeopleAsync(
        user,
        undefined,
        searchQuery,
        inputFilters,
        inputOffset
      );
      if (response.ok) {
        let buffer = "";
        try {
          // Async iterable is only supported in Firefox at the moment
          for await (const chunk of response.body) {
            const val = UTF8_DECODER.decode(chunk);
            buffer += val;
            handleSearchPeopleAsyncResponse(buffer, inputOffset, searchQuery);
          }
        } catch (error) {
          // Fallback to traditional stream reader approach
          const reader = response.body.getReader();
          while (true) {
            const { done, value } = await reader.read();
            if (done) break;
            const val = UTF8_DECODER.decode(value);
            buffer += val;
            handleSearchPeopleAsyncResponse(buffer, inputOffset, searchQuery);
          }
        }
      }
      setIsLoading(false);
    })();
  };

  const callSearch = (
    searchQuery: string,
    inputFilters: any,
    inputPage: number
  ) => {
    (async function () {
      const response = await search(user, searchQuery, inputFilters, inputPage);
      if (response.ok) {
        const responseData = await response.json();
        const items = responseData.items;
        const activityIds = items.map((x: any) => x.activity_id);
        const activities: { [key: string]: any } = {};
        items.forEach((x: any) => (activities[x.activity_id] = x));
        if (items.length === 0) {
          setEndOfResultReached(true);
        } else {
          setPage(inputPage + 1);
        }
        setActivityIds(activityIds);
        setActivities(activities);
        setCurrentSearchedValue(searchQuery);
      }
      setIsLoading(false);
    })();
  };

  const handleKeyDownSearch: KeyboardEventHandler = (event) => {
    if (!searchValue && !currentSearchedValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        setIsLoading(true);
        setOffset(0);
        setPage(1);
        setPersons(new Map());
        setActivityIds([]);
        setActivityToEntityMap(new Map());
        setEntityToActivitiesMap(new Map());
        setEndOfResultReached(false);
        event.preventDefault();
        const queryJson = {
          query: searchValue,
          filters: filters,
          offset: 0,
        };
        recordUserAction(
          UserActionType.QUERY,
          UserActionEntityType.SEARCH,
          JSON.stringify(queryJson)
        );
        if (searchValue) {
          setSearchParams({
            query: searchValue,
            activityTime: timeValue,
            intent: intentValue,
          });
        } else {
          setSearchParams({});
        }
        callSearch(searchValue, filters, 1);
    }
  };

  const handleKeyDownNumEmployeesMin: KeyboardEventHandler = (event) => {
    if (!numEmployeesMinValue || numEmployeesMinValueError) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        setIsLoading(true);
        event.preventDefault();
        saveFilters(
          SearchPeopleFilterKey.NUM_EMPLOYEES_MIN,
          undefined,
          Number(numEmployeesMinValue)
        );
    }
  };

  const handleKeyDownNumEmployeesMax: KeyboardEventHandler = (event) => {
    if (!numEmployeesMaxValue || numEmployeesMaxValueError) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        setIsLoading(true);
        event.preventDefault();
        saveFilters(
          SearchPeopleFilterKey.NUM_EMPLOYEES_MAX,
          undefined,
          Number(numEmployeesMaxValue)
        );
    }
  };

  const saveFilters = (
    newlyChangedFilterKey: string,
    newlyChangedFilterValue?: MultiValue<Option>,
    newlyChangedFilterValueNumber?: number
  ) => {
    const updatedFilters: { [key: string]: any } = {};
    if (jobTitleValue.length > 0) {
      updatedFilters[SearchPeopleFilterKey.JOB_TITLE] = jobTitleValue.map(
        (val) => val.value
      );
    }
    if (personLocationValue.length > 0) {
      updatedFilters[SearchPeopleFilterKey.PERSON_LOCATION] =
        personLocationValue.map((val) => val.value);
    }
    if (companyNameValue.length > 0) {
      updatedFilters[SearchPeopleFilterKey.COMPANY_NAME] = companyNameValue.map(
        (val) => val.value
      );
    }
    if (companyLocationValue.length > 0) {
      updatedFilters[SearchPeopleFilterKey.COMPANY_LOCATION] =
        companyLocationValue.map((val) => val.value);
    }
    if (companyIndustryValue.length > 0) {
      updatedFilters[SearchPeopleFilterKey.COMPANY_INDUSTRY] =
        companyIndustryValue.map((val) => val.value);
    }
    if (numEmployeesMinValue && !numEmployeesMinValueError) {
      updatedFilters[SearchPeopleFilterKey.NUM_EMPLOYEES_MIN] =
        Number(numEmployeesMinValue);
    }
    if (numEmployeesMaxValue && !numEmployeesMaxValueError) {
      updatedFilters[SearchPeopleFilterKey.NUM_EMPLOYEES_MAX] =
        Number(numEmployeesMaxValue);
    }

    // Capture the latest change in filter value as it's not applied to the state
    if (newlyChangedFilterKey && newlyChangedFilterValue) {
      updatedFilters[newlyChangedFilterKey] = newlyChangedFilterValue.map(
        (value) => value.value
      );
    } else if (newlyChangedFilterKey && newlyChangedFilterValueNumber) {
      updatedFilters[newlyChangedFilterKey] = newlyChangedFilterValueNumber;
    }

    setFilters(updatedFilters);

    (async function () {
      setIsLoading(true);
      const response = await searchPeople(
        user,
        undefined,
        searchValue,
        updatedFilters,
        0
      );
      if (response.ok) {
        const responseData = await response.json();
        const newPersons: Map<string, PersonExternal> = new Map();
        responseData.persons.forEach((person: any) =>
          newPersons.set(person.person_id, person)
        );
        setCurrentSearchedValue(searchValue);
        setPersons(newPersons);
        setOffset(responseData.offset);
      }
      setIsLoading(false);
    })();
  };

  const loadMoreResults = () => {
    setIsLoading(true);
    const queryJson = {
      query: searchValue,
      filters: filters,
      page: page,
    };
    recordUserAction(
      UserActionType.LOAD_MORE,
      UserActionEntityType.SEARCH,
      JSON.stringify(queryJson)
    );
    callSearch(currentSearchedValue, filters, page);
  };

  const checkNumEmployeesMinValue = (event: any) => {
    setNumEmployeesMinValue(event.target.value);
    if (isNaN(event.target.value)) {
      setNumEmployeesMinValueError(true);
      setNumEmployeesMinErrorText("Please input number.");
    } else {
      setNumEmployeesMinValueError(false);
      setNumEmployeesMinErrorText("");
    }
  };

  const checkNumEmployeesMaxValue = (event: any) => {
    setNumEmployeesMaxValue(event.target.value);
    if (isNaN(event.target.value)) {
      setNumEmployeesMaxValueError(true);
      setNumEmployeesMaxErrorText("Please input number.");
    } else {
      setNumEmployeesMaxValueError(false);
      setNumEmployeesMaxErrorText("");
    }
  };

  const renderBottomText = () => {
    let message = "";
    // Once the loading is over, we need to show "No result" message or "End of result" message
    if (!isLoading && !isActivityLoading) {
      if (persons.size === 0) {
        message = "No result from the search.";
      } else if (endOfResultReached) {
        message =
          "Reached the end of the search results, try other parameters to find more people!";
      }
    }
    return <IonText>{message}</IonText>;
  };

  const handleIntentChange = (event: SelectChangeEvent) => {
    const selectedIntentValue = event.target.value;
    if (selectedIntentValue === intentValue) {
      return;
    }
    debugLog("Intent changed: ", selectedIntentValue);
    setIntentValue(selectedIntentValue);

    const updatedFilters: { [key: string]: any } = {};
    updatedFilters[SearchPeopleFilterKey.ACTIVITY_TIME] = timeValue;
    updatedFilters[SearchPeopleFilterKey.INTENT] = selectedIntentValue;
    setFilters(updatedFilters);

    setIsLoading(true);
    setOffset(0);
    setPage(1);
    setPersons(new Map());
    setActivityIds([]);
    setActivityToEntityMap(new Map());
    setEntityToActivitiesMap(new Map());
    setEndOfResultReached(false);
    const queryJson = {
      query: searchValue,
      filters: updatedFilters,
      offset: 0,
    };
    recordUserAction(
      UserActionType.INTENT_CHANGE,
      UserActionEntityType.SEARCH,
      JSON.stringify(queryJson)
    );
    if (searchValue) {
      setSearchParams({
        query: searchValue,
        activityTime: timeValue,
        intent: selectedIntentValue,
      });
    } else {
      setSearchParams({});
    }
    callSearch(searchValue, updatedFilters, 1);
    popupState.setOpen(false);
  };

  const handleTimeChange = (event: SelectChangeEvent) => {
    const selectedTimeValue = event.target.value;
    if (selectedTimeValue === timeValue) {
      return;
    }
    debugLog("Time changed: ", selectedTimeValue);
    setTimeValue(selectedTimeValue);

    const updatedFilters: { [key: string]: any } = {};
    updatedFilters[SearchPeopleFilterKey.ACTIVITY_TIME] = selectedTimeValue;
    updatedFilters[SearchPeopleFilterKey.INTENT] = intentValue;
    setFilters(updatedFilters);

    setIsLoading(true);
    setOffset(0);
    setPage(1);
    setPersons(new Map());
    setActivityIds([]);
    setActivityToEntityMap(new Map());
    setEntityToActivitiesMap(new Map());
    setEndOfResultReached(false);
    const queryJson = {
      query: searchValue,
      filters: updatedFilters,
      offset: 0,
    };
    recordUserAction(
      UserActionType.DATE_RANGE_CHANGE,
      UserActionEntityType.SEARCH,
      JSON.stringify(queryJson)
    );
    if (searchValue) {
      setSearchParams({
        query: searchValue,
        activityTime: selectedTimeValue,
        intent: intentValue,
      });
    } else {
      setSearchParams({});
    }
    callSearch(searchValue, updatedFilters, 1);
    popupState.setOpen(false);
  };

  useEffect(() => {
    // Update activities state with activity IDs
    const fetchData = async () => {
      setIsActivityLoading(true);
      try {
        let toBeProcessed = [];
        for (let activityId of activityIds) {
          if (!activityToEntityMap.has(activityId)) {
            toBeProcessed.push(activityId);
          }
        }

        const updatedDataPromises = toBeProcessed.map(async (activityId) => {
          if (activityId && activityId !== "None") {
            return activities[activityId];
          }
        });

        // Wait for all async calls to finish using Promise.all
        const updatedDataArray = await Promise.all(updatedDataPromises);
        // Filter out undefined and company (non-person) profiles
        const filteredArray = updatedDataArray.map((x: any) => ({
          person_id: x.entity_id,
          name: x.entity_name,
          location: "",
          job_title: x.entity_tagline,
          company_name: "",
          company: {
            company_id: "",
            name: "",
            location: "",
            industry: "",
            overview: "",
            num_employees: "",
            website_url: "",
          },
          activities: [x],
        }));

        if (filteredArray.length === 0) {
          setIsActivityLoading(false);
          return;
        }

        const newActivityMap: Map<string, string> = new Map(
          activityToEntityMap
        );
        const newPersons: Map<string, PersonExternal> = new Map(persons);
        const newEntityToActivitiesMap: Map<string, Activity[]> = new Map(
          entityToActivitiesMap
        );
        for (let person of filteredArray) {
          if (person) {
            newActivityMap.set(
              person.activities[0].activity_id,
              person.person_id
            );
            if (
              person.person_id &&
              newEntityToActivitiesMap.has(person.person_id)
            ) {
              const updatedActivities = newEntityToActivitiesMap
                .get(person.person_id)!
                .concat(person.activities);
              newEntityToActivitiesMap.set(person.person_id, updatedActivities);
            } else {
              newEntityToActivitiesMap.set(person.person_id, person.activities);
              // Only set person if ther person does not exist in the map
              newPersons.set(person.person_id, person);
            }
          }
        }

        // Set the updated array state with the fetched data
        setActivityToEntityMap(newActivityMap);
        setPersons(newPersons);
        setEntityToActivitiesMap(newEntityToActivitiesMap);
        setIsActivityLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsActivityLoading(false);
      }
    };

    fetchData();
  }, [activityIds]);

  useEffect(() => {
    (async function () {
      const response = await getUser(user);
      if (response.ok) {
        const responseData = await response.json();
        if (!responseData.user.sign_up_complete) {
          alert("Please update your profile to complete sign-up!");
          navigate("/account/profile");
        }
      }
    })();
    // Manually refresh the token on page reload
    (async function () {
      try {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        const currentSession = await Auth.currentSession();
        cognitoUser.refreshSession(currentSession.getRefreshToken(), () => {
          debugLog("Successfully refreshed the token");
        });
      } catch (e) {
        debugLog("Unable to refresh Token", e);
      }
    })();

    const updatedFilters: { [key: string]: any } = {};
    updatedFilters[SearchPeopleFilterKey.ACTIVITY_TIME] = timeValue;
    updatedFilters[SearchPeopleFilterKey.INTENT] = intentValue;
    setFilters(updatedFilters);

    if (searchValue) {
      setSearchParams({
        query: searchValue,
        activityTime: timeValue,
        intent: intentValue,
      });
    } else {
      setSearchParams({});
    }
    setIsLoading(true);
    callSearch(searchValue, updatedFilters, 1);
  }, []);

  useEffect(() => {
    const personExternals = persons.values();
    const jobTitles: Option[] = [];
    const jobTitleSet: Set<string> = new Set();
    const personLocations: Option[] = [];
    const personLocationSet: Set<string> = new Set();
    const companyNames: Option[] = [];
    const companyNameSet: Set<string> = new Set();
    const companyLocations: Option[] = [];
    const companyLocationSet: Set<string> = new Set();
    const companyIndustries: Option[] = [];
    const companyIndustrySet: Set<string> = new Set();
    for (const personExternal of personExternals) {
      if (
        personExternal.job_title &&
        !jobTitleSet.has(personExternal.job_title)
      ) {
        jobTitles.push(createOption(personExternal.job_title));
        jobTitleSet.add(personExternal.job_title);
      }

      if (
        personExternal.location &&
        !personLocationSet.has(personExternal.location)
      ) {
        personLocations.push(createOption(personExternal.location));
        personLocationSet.add(personExternal.location);
      }

      if (
        personExternal.company &&
        personExternal.company.name &&
        !companyNameSet.has(personExternal.company.name)
      ) {
        companyNames.push(createOption(personExternal.company.name));
        companyNameSet.add(personExternal.company.name);
      }

      if (
        personExternal.company &&
        personExternal.company.location &&
        !companyLocationSet.has(personExternal.company.location)
      ) {
        companyLocations.push(createOption(personExternal.company.location));
        companyLocationSet.add(personExternal.company.location);
      }

      if (
        personExternal.company &&
        personExternal.company.industry &&
        !companyIndustrySet.has(personExternal.company.industry)
      ) {
        companyIndustries.push(createOption(personExternal.company.industry));
        companyIndustrySet.add(personExternal.company.industry);
      }
    }
    setJobTitleOptions(jobTitles);
    setPersonLocationOptions(personLocations);
    setCompanyNameOptions(companyNames);
    setCompanyLocationOptions(companyLocations);
    setCompanyIndustryOptions(companyIndustries);
  }, [persons]);

  return (
    <Home>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          paddingTop: "2%",
          paddingBottom: "2%",
          paddingLeft: "10%",
          paddingRight: "10%",
          width: "100%",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                height: "50px",
              }}
            >
              <div style={{ width: "100%" }}>
                <TextField
                  id="main-search-bar"
                  variant="outlined"
                  onChange={(event) => {
                    setSearchValue(event.target.value);
                  }}
                  onKeyDown={handleKeyDownSearch}
                  size="small"
                  value={searchValue}
                  placeholder="What are you looking for?"
                  disabled={isLoading || isActivityLoading}
                  style={{
                    width: "100%",
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Select
                          variant="standard"
                          style={{
                            borderRight: "0.1px solid rgba(0, 0, 0, .1)",
                            marginRight: "7px",
                          }}
                          autoWidth
                          disableUnderline
                          onChange={handleIntentChange}
                          value={intentValue}
                          // disabled={true}
                          disabled={isLoading || isActivityLoading}
                        >
                          <MenuItem value={IntentValue.ALL}>All</MenuItem>
                          <MenuItem value={IntentValue.HIRING_JOB}>
                            Hiring Job
                          </MenuItem>
                          <MenuItem value={IntentValue.SEEKING_JOB}>
                            Seeking Job
                          </MenuItem>
                          <MenuItem value={IntentValue.SEEKING_RECOMMENDATION}>
                            Seeking Recommendation
                          </MenuItem>
                          <MenuItem value={IntentValue.SHARING_OPINION}>
                            Sharing Opinion
                          </MenuItem>
                        </Select>
                        {isLoading || isActivityLoading ? (
                          <ClipLoader size={"15px"} />
                        ) : (
                          <IonIcon
                            ios={searchOutline}
                            md={searchSharp}
                            style={{ fontSize: "18px" }}
                          />
                        )}
                      </InputAdornment>
                    ),
                    endAdornment:
                      searchValue && !isLoading && !isActivityLoading ? (
                        <div
                          onClick={() => setSearchValue("")}
                          style={{ cursor: "pointer", display: "flex" }}
                        >
                          <IonIcon
                            ios={closeOutline}
                            md={closeSharp}
                            style={{ fontSize: "18px" }}
                          />
                        </div>
                      ) : undefined,
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: "5px",
                }}
              >
                <IonButton
                  id="filter-trigger"
                  color="dark"
                  fill="clear"
                  style={{
                    margin: "0px",
                    "--padding-start": "0.8em",
                    "--padding-end": "0.8em",
                  }}
                  {...bindTrigger(popupState)}
                >
                  <IonIcon
                    ios={optionsOutline}
                    md={optionsSharp}
                    style={{ fontSize: "20px" }}
                  />
                </IonButton>
                <Popover
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  {...bindPopover(popupState)}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      padding: "15px",
                      alignItems: "center",
                      width: "300px",
                    }}
                  >
                    <IonText
                      style={{
                        flex: "1",
                        paddingRight: "30px",
                      }}
                    >
                      Date posted
                    </IonText>
                    <Select
                      inputProps={{ "aria-label": "Without label" }}
                      variant="standard"
                      style={{
                        width: "100%",
                        flex: "1",
                      }}
                      onChange={handleTimeChange}
                      value={timeValue}
                      disabled={isLoading || isActivityLoading}
                    >
                      <MenuItem value={ActivityTimeValue.ANY_TIME}>
                        Any Time
                      </MenuItem>
                      <MenuItem value={ActivityTimeValue.PAST_MONTH}>
                        Past Month
                      </MenuItem>
                      <MenuItem value={ActivityTimeValue.PAST_WEEK}>
                        Past Week
                      </MenuItem>
                      <MenuItem value={ActivityTimeValue.PAST_DAY}>
                        Last 24 Hours
                      </MenuItem>
                    </Select>
                  </div>
                </Popover>
                <IonButton
                  id="menu-trigger"
                  color="dark"
                  fill="clear"
                  style={{
                    margin: "0px",
                    "--padding-start": "0.8em",
                    "--padding-end": "0.8em",
                  }}
                >
                  <IonIcon
                    ios={ellipsisHorizontalOutline}
                    md={ellipsisHorizontalSharp}
                    style={{ fontSize: "20px" }}
                  />
                </IonButton>
                <IonPopover
                  trigger="menu-trigger"
                  size="auto"
                  dismissOnSelect={true}
                >
                  <IonContent>
                    <IonList color="none" lines="none">
                      <IonItem
                        button={true}
                        detail={false}
                        onClick={exportToCsv}
                      >
                        Export to CSV
                      </IonItem>
                    </IonList>
                  </IonContent>
                </IonPopover>
              </div>
            </div>
            <div>
              {/*<div
                style={{
                  display: showFilters,
                  flexDirection: "row",
                  paddingBottom: "10px",
                }}
              >
                <div
                  style={{
                    flex: 1,
                    paddingRight: "10px",
                  }}
                >
                  <CreatableSelect
                    value={companyNameValue}
                    inputValue={companyNameInputValue}
                    onChange={(newValue, actionMeta) => {
                      if (
                        actionMeta.action === "pop-value" &&
                        actionMeta.removedValue === undefined
                      ) {
                        return;
                      }
                      setCompanyNameValue(newValue);
                      saveFilters(SearchPeopleFilterKey.COMPANY_NAME, newValue);
                    }}
                    onInputChange={(newValue) =>
                      setCompanyNameInputValue(newValue)
                    }
                    isMulti
                    placeholder="Company"
                    options={companyNameOptions}
                    isDisabled={isLoading}
                    createOptionPosition="first"
                  />
                </div>
                <div
                  style={{
                    flex: 1,
                  }}
                >
                  <CreatableSelect
                    value={companyLocationValue}
                    inputValue={companyLocationInputValue}
                    onChange={(newValue, actionMeta) => {
                      if (
                        actionMeta.action === "pop-value" &&
                        actionMeta.removedValue === undefined
                      ) {
                        return;
                      }
                      setCompanyLocationValue(newValue);
                      saveFilters(
                        SearchPeopleFilterKey.COMPANY_LOCATION,
                        newValue
                      );
                    }}
                    onInputChange={(newValue) =>
                      setCompanyLocationInputValue(newValue)
                    }
                    isMulti
                    placeholder="Company Location"
                    options={companyLocationOptions}
                    isDisabled={isLoading}
                    createOptionPosition="first"
                  />
                </div>
              </div>
              <div
                style={{
                  display: showFilters,
                  flexDirection: "row",
                  paddingBottom: "10px",
                }}
              >
                <div
                  style={{
                    flex: 1,
                    paddingRight: "10px",
                  }}
                >
                  <CreatableSelect
                    value={jobTitleValue}
                    inputValue={jobTitleInputValue}
                    onChange={(newValue, actionMeta) => {
                      if (
                        actionMeta.action === "pop-value" &&
                        actionMeta.removedValue === undefined
                      ) {
                        return;
                      }
                      setJobTitleValue(newValue);
                      saveFilters(SearchPeopleFilterKey.JOB_TITLE, newValue);
                    }}
                    onInputChange={(newValue) =>
                      setJobTitleInputValue(newValue)
                    }
                    isMulti
                    placeholder="Job Title"
                    options={jobTitleOptions}
                    isDisabled={isLoading}
                    createOptionPosition="first"
                  />
                </div>
                <div
                  style={{
                    flex: 1,
                  }}
                >
                  <CreatableSelect
                    value={personLocationValue}
                    inputValue={personLocationInputValue}
                    onChange={(newValue, actionMeta) => {
                      if (
                        actionMeta.action === "pop-value" &&
                        actionMeta.removedValue === undefined
                      ) {
                        return;
                      }
                      setPersonLocationValue(newValue);
                      saveFilters(
                        SearchPeopleFilterKey.PERSON_LOCATION,
                        newValue
                      );
                    }}
                    onInputChange={(newValue) =>
                      setPersonLocationInputValue(newValue)
                    }
                    isMulti
                    placeholder="Location"
                    options={personLocationOptions}
                    isDisabled={isLoading}
                    createOptionPosition="first"
                  />
                </div>
              </div>
              <div
                style={{
                  display: showFilters,
                  flexDirection: "row",
                  paddingBottom: "10px",
                  height: "50px",
                }}
              >
                <div
                  style={{
                    flex: 1,
                    paddingRight: "10px",
                  }}
                >
                  <TextField
                    id="num-employees-min"
                    variant="outlined"
                    error={numEmployeesMinValueError}
                    onChange={checkNumEmployeesMinValue}
                    onKeyDown={handleKeyDownNumEmployeesMin}
                    size="small"
                    value={numEmployeesMinValue}
                    helperText={numEmployeesMinErrorText}
                    placeholder="# Employees (Min)"
                    disabled={isLoading}
                    style={{
                      width: "100%",
                    }}
                  />
                </div>
                <div
                  style={{
                    flex: 1,
                  }}
                >
                  <TextField
                    id="num-employees-max"
                    variant="outlined"
                    error={numEmployeesMaxValueError}
                    onChange={checkNumEmployeesMaxValue}
                    onKeyDown={handleKeyDownNumEmployeesMax}
                    size="small"
                    value={numEmployeesMaxValue}
                    helperText={numEmployeesMaxErrorText}
                    placeholder="# Employees (Max)"
                    disabled={isLoading}
                    style={{
                      width: "100%",
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  display: showFilters,
                  flexDirection: "row",
                }}
              >
                <div
                  style={{
                    flex: 1,
                    paddingRight: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  <CreatableSelect
                    value={companyIndustryValue}
                    inputValue={companyIndustryInputValue}
                    onChange={(newValue, actionMeta) => {
                      if (
                        actionMeta.action === "pop-value" &&
                        actionMeta.removedValue === undefined
                      ) {
                        return;
                      }
                      setCompanyIndustryValue(newValue);
                      saveFilters(
                        SearchPeopleFilterKey.COMPANY_INDUSTRY,
                        newValue
                      );
                    }}
                    onInputChange={(newValue) =>
                      setCompanyIndustryInputValue(newValue)
                    }
                    isMulti
                    placeholder="Industry"
                    options={companyIndustryOptions}
                    isDisabled={isLoading}
                    createOptionPosition="first"
                  />
                </div>
                <div
                  style={{
                    flex: 1,
                  }}
                />
                </div>*/}
              <PersonList
                persons={persons}
                entityToActivitiesMap={entityToActivitiesMap}
                filters={{ searchValue: "" }}
              />
              <div
                style={{
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                {renderBottomText()}
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {!endOfResultReached ? (
                  !isLoading && !isActivityLoading ? (
                    <IonButton
                      color="dark"
                      fill="clear"
                      style={{
                        width: "fit-content",
                      }}
                      onClick={loadMoreResults}
                    >
                      <IonIcon
                        ios={refreshOutline}
                        md={refreshSharp}
                        style={{ fontSize: "20px", paddingRight: "10px" }}
                      />
                      <IonText color="black">Load more</IonText>
                    </IonButton>
                  ) : (
                    <IonButton
                      color="dark"
                      fill="clear"
                      style={{
                        width: "fit-content",
                      }}
                      onClick={loadMoreResults}
                      disabled={true}
                    >
                      <div
                        style={{
                          paddingRight: "10px",
                        }}
                      >
                        <ClipLoader size={"20px"} />
                      </div>
                      <IonText>Loading more...</IonText>
                    </IonButton>
                  )
                ) : null}
              </div>
              {/*<IonInfiniteScroll
                onIonInfinite={(ev) => {
                  onScroll(ev);
                }}
                disabled={
                  infiniteScrollDisabled || isLoading || isActivityLoading
                }
              >
                <IonInfiniteScrollContent
                  loadingText="Searching for more people..."
                  loadingSpinner="circular"
                ></IonInfiniteScrollContent>
              </IonInfiniteScroll>*/}
            </div>
          </div>
        </div>
      </div>
    </Home>
  );
};

export default Dashboard;
