import React from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import { useNavigate } from "react-router";
import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";

import { DRAWER_WIDTH } from "../constants";

import "@aws-amplify/ui-react/styles.css";

const drawerItems = [
  { name: "Tasks", pathname: "/v2/tasks" },
  { name: "Functions", pathname: "/v2/functions" },
];

const Panel: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Drawer
      sx={{
        width: DRAWER_WIDTH,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: DRAWER_WIDTH,
          boxSizing: "border-box",
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Toolbar>
        <Typography variant="h6" noWrap component="div">
          Nunchi
        </Typography>
      </Toolbar>
      <Divider />
      <List>
        {drawerItems.map((item, index) => (
          <ListItem key={item.name} sx={{ paddingTop: 0, paddingBottom: 0 }}>
            <ListItemButton onClick={() => navigate(item.pathname)}>
              <ListItemText primary={item.name} />
              <ListItemIcon sx={{ justifyContent: "right" }}>
                <KeyboardArrowRightIcon />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

export default Panel;
