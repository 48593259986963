import React, { KeyboardEventHandler } from "react";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";

import { ClipLoader } from "react-spinners";
import { useNavigate } from "react-router";
import {
  Box,
  InputAdornment,
  Link,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  alpha,
} from "@mui/material";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { TableVirtuoso, TableComponents } from "react-virtuoso";

import { darkTheme } from "./Theme";
import {
  AGENCY_LEAD_ALLOWLISTED_USER_IDS,
  MAIN_AGENCY_LEAD_FEED_ID,
} from "../constants";
import { SERVICE_ACCOUNT_ID, FeedItem, listFeedItems } from "../api/Nunchi";

import "@aws-amplify/ui-react/styles.css";

function isValidUrl(string: string) {
  try {
    const url = new URL(string);
    return true;
  } catch (_) {
    return false;
  }
}

interface Data {
  agency_name: string;
  job_posting_url: string;
  job_title: string;
  job_location: string;
  job_posted_date: string;
  matching_client_job_posting_url: string;
  client_name: string;
  client_linkedin_url: string;
  client_website_url: string;
}

interface ColumnData {
  dataKey: keyof Data;
  label: string;
  numeric?: boolean;
  width: number;
}

const columns: ColumnData[] = [
  {
    width: 200,
    label: "Agency Name",
    dataKey: "agency_name",
  },
  {
    width: 400,
    label: "Job Posting URL",
    dataKey: "job_posting_url",
  },
  {
    width: 200,
    label: "Job Title",
    dataKey: "job_title",
  },
  {
    width: 200,
    label: "Job Location",
    dataKey: "job_location",
  },
  {
    width: 200,
    label: "Job Posted Date",
    dataKey: "job_posted_date",
  },
  {
    width: 400,
    label: "Matching Client Job Posting URL",
    dataKey: "matching_client_job_posting_url",
  },
  {
    width: 200,
    label: "Client Name",
    dataKey: "client_name",
  },
  {
    width: 400,
    label: "Client LinkedIn URL",
    dataKey: "client_linkedin_url",
  },
  {
    width: 400,
    label: "Client Website URL",
    dataKey: "client_website_url",
  },
];

const VirtuosoTableComponents: TableComponents<Data> = {
  Scroller: React.forwardRef<HTMLDivElement>((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => (
    <Table
      {...props}
      sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
    />
  ),
  TableHead,
  TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
  TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
};

const AgencyLeads = () => {
  const navigate = useNavigate();
  const { user } = useAuthenticator((context) => [context.user]);
  const [rows, setRows] = React.useState<Data[]>([]);
  const [filteredRows, setFilteredRows] = React.useState<Data[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState("");

  function fixedHeaderContent() {
    return (
      <TableRow>
        {columns.map((column) => (
          <TableCell
            key={column.dataKey}
            variant="head"
            style={{ width: column.width }}
            sx={{
              backgroundColor: "background.paper",
            }}
          >
            {column.label}
          </TableCell>
        ))}
      </TableRow>
    );
  }

  function rowContent(_index: number, row: Data) {
    return (
      <React.Fragment>
        {columns.map((column) => (
          <TableCell key={column.dataKey}>
            {row[column.dataKey] && isValidUrl(row[column.dataKey]) ? (
              <Link
                target="_blank"
                rel="noopener noreferrer"
                href={row[column.dataKey]}
              >
                {row[column.dataKey]}
              </Link>
            ) : (
              row[column.dataKey]
            )}
          </TableCell>
        ))}
      </React.Fragment>
    );
  }

  const callListFeedItems = (
    startTime: string | undefined = undefined,
    limit: number | undefined = undefined,
    nextToken: string | undefined = undefined
  ) => {
    setIsLoading(true);
    (async function () {
      const response = await listFeedItems(
        user,
        SERVICE_ACCOUNT_ID,
        MAIN_AGENCY_LEAD_FEED_ID,
        startTime,
        limit,
        nextToken
      );
      if (response.ok) {
        const responseData = await response.json();
        setIsLoading(false);
        const rows = responseData.feed_items.map((x: FeedItem) => ({
          agency_name: x.meta.custom && x.meta.custom.agency_name,
          job_posting_url: x.meta.custom && x.meta.custom.job_posting_url,
          job_title: x.meta.custom && x.meta.custom.job_title,
          job_location: x.meta.custom && x.meta.custom.job_location,
          job_posted_date:
            x.meta.custom &&
            (x.meta.custom.job_posted_date
              ? moment(x.meta.custom.job_posted_date).local().format("l LT")
              : ""),
          matching_client_job_posting_url:
            x.meta.custom && x.meta.custom.matching_client_job_posting_url,
          client_name: x.meta.custom && x.meta.custom.client_name,
          client_linkedin_url:
            x.meta.custom && x.meta.custom.client_linkedin_url,
          client_website_url: x.meta.custom && x.meta.custom.client_website_url,
        }));
        setRows(rows);
        setFilteredRows(rows);
      } else {
        setIsLoading(false);
        alert("Unexpected error occurred");
      }
    })();
  };

  const handleKeyDownSearch: KeyboardEventHandler = (event) => {
    if (!searchValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        setSearchValue(searchValue);
    }
  };

  React.useEffect(() => {
    if (AGENCY_LEAD_ALLOWLISTED_USER_IDS.has(user.getUsername())) {
      callListFeedItems();
    }
  }, []);

  if (!AGENCY_LEAD_ALLOWLISTED_USER_IDS.has(user.getUsername())) {
    return <Typography>Not Found</Typography>;
  }

  return (
    <Stack direction="column" spacing={2} width={"100%"} height={"100%"}>
      <TextField
        fullWidth
        onKeyDown={handleKeyDownSearch}
        onChange={(event) => {
          const newSearchValue = event.target.value;
          setSearchValue(newSearchValue);
          setFilteredRows(
            rows.filter((row: Data) => {
              {
                if (!newSearchValue) {
                  return true;
                }
                const candidateValues = [
                  row.agency_name,
                  row.client_name,
                  row.job_title,
                  row.job_location,
                ];
                return candidateValues.some((v) =>
                  v.toLowerCase().includes(newSearchValue.toLowerCase())
                );
              }
            })
          );
        }}
        size="small"
        value={searchValue}
        placeholder="Search"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {isLoading ? <ClipLoader size={"15px"} /> : <SearchIcon />}
            </InputAdornment>
          ),
          endAdornment:
            searchValue && !isLoading ? (
              <Box
                onClick={() => setSearchValue("")}
                style={{ cursor: "pointer", display: "flex" }}
              >
                <CloseIcon />
              </Box>
            ) : undefined,
        }}
      />
      <Box
        width={"100%"}
        height={"85%"}
        border={`0.1px solid ${alpha(darkTheme.palette.primary.light, 0.2)}`}
      >
        <TableVirtuoso
          data={searchValue ? filteredRows : rows}
          components={VirtuosoTableComponents}
          fixedHeaderContent={fixedHeaderContent}
          itemContent={rowContent}
        />
      </Box>
    </Stack>
  );
};

export default AgencyLeads;
