import React, { KeyboardEventHandler, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { searchProducts } from "../api/Nunchi";
import { Helmet } from "react-helmet";
import { IonPage, IonContent, IonButton, IonIcon } from "@ionic/react";
import {
  closeOutline,
  closeSharp,
  searchOutline,
  searchSharp,
} from "ionicons/icons";
import { InputAdornment, TextField } from "@mui/material";
import { ClipLoader } from "react-spinners";
import "@aws-amplify/ui-react/styles.css";

import "./Products.css";

const IS_LOCALHOST = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

const Products: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const defaultSearchValue = searchParams.get("q") || "";
  const defaultSortValue = searchParams.get("sort") || "";
  const defaultOrderValue = searchParams.get("order") || "";
  const [searchInputValue, setSearchInputValue] = useState(defaultSearchValue);
  const [searchValue, setSearchValue] = useState(defaultSearchValue);
  const [sortValue, setSortValue] = useState(defaultSortValue);
  const [orderValue, setOrderValue] = useState(defaultOrderValue);
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState([]);

  const debugLog = (message?: any, ...optionalParams: any[]): void => {
    if (IS_LOCALHOST) {
      console.log(message, optionalParams);
    }
  };

  const handleKeyDownSearch: KeyboardEventHandler = (event) => {
    switch (event.key) {
      case "Enter":
      case "Tab":
        setSearchInputValue(searchInputValue);
        setSearchValue(searchInputValue);

        const updatedSearchParams: any = {};
        if (searchInputValue) {
          updatedSearchParams.q = searchInputValue;
        }
        if (sortValue) {
          updatedSearchParams.sort = sortValue;
        }
        if (orderValue) {
          updatedSearchParams.order = orderValue;
        }
        setSearchParams(updatedSearchParams);
    }
  };

  const handleClear = () => {
    setSearchInputValue("");
    setSearchValue("");
    setSortValue("");
    setOrderValue("");
    setSearchParams({});
  };

  const setSortOrder = (sort: string, order: string) => {
    setSortValue(sort);
    setOrderValue(order);

    const updatedSearchParams: any = {};
    if (searchInputValue) {
      updatedSearchParams.q = searchInputValue;
    }
    if (sort) {
      updatedSearchParams.sort = sort;
    }
    if (order) {
      updatedSearchParams.order = order;
    }
    setSearchParams(updatedSearchParams);
  };

  useEffect(() => {
    (async function () {
      const response = await searchProducts(searchValue, sortValue, orderValue);
      if (response.ok) {
        const items = await response.json();
        setItems(items);
      }
    })();
  }, [searchValue, sortValue, orderValue]);

  return (
    <IonPage id="main-content">
      <IonContent fullscreen>
        <div
          style={{
            minHeight: "100%",
            paddingBottom: "2%",
            display: "flex",
            backgroundColor: "#181818",
          }}
        >
          <Helmet>
            <meta name="robots" content="noindex" />
          </Helmet>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingTop: "2%",
              paddingBottom: "2%",
              paddingLeft: "2%",
              paddingRight: "2%",
              width: "100%",
            }}
          >
            <TextField
              id="main-search-bar"
              variant="outlined"
              onChange={(event) => {
                setSearchInputValue(event.target.value);
              }}
              onKeyDown={handleKeyDownSearch}
              size="small"
              value={searchInputValue}
              placeholder="검색"
              disabled={isLoading}
              style={{
                backgroundColor: "white",
                width: "100%",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {isLoading ? (
                      <ClipLoader size={"15px"} />
                    ) : (
                      <IonIcon
                        ios={searchOutline}
                        md={searchSharp}
                        style={{ fontSize: "18px" }}
                      />
                    )}
                  </InputAdornment>
                ),
                endAdornment:
                  searchValue && !isLoading ? (
                    <div
                      onClick={handleClear}
                      style={{ cursor: "pointer", display: "flex" }}
                    >
                      <IonIcon
                        ios={closeOutline}
                        md={closeSharp}
                        style={{ fontSize: "18px" }}
                      />
                    </div>
                  ) : undefined,
              }}
            />
            <div>
              <IonButton
                color="light"
                style={{
                  width: "fit-content",
                  border: "0.1px solid rgba(0, 0, 0, 0.2)",
                }}
                onClick={() => setSortOrder("price", "asc")}
              >
                <div
                  style={{
                    fontWeight: "600",
                    textAlign: "center",
                  }}
                >
                  낮은 가격순
                </div>
              </IonButton>
              <IonButton
                color="light"
                style={{
                  width: "fit-content",
                  border: "0.1px solid rgba(0, 0, 0, 0.2)",
                }}
                onClick={() => setSortOrder("num_ratings", "desc")}
              >
                <div
                  style={{
                    fontWeight: "600",
                    textAlign: "center",
                  }}
                >
                  리뷰 많은순
                </div>
              </IonButton>
              <IonButton
                color="light"
                style={{
                  width: "fit-content",
                  border: "0.1px solid rgba(0, 0, 0, 0.2)",
                }}
                onClick={() => setSortOrder("rating", "desc")}
              >
                <div
                  style={{
                    fontWeight: "600",
                    textAlign: "center",
                  }}
                >
                  리뷰 좋은순
                </div>
              </IonButton>
            </div>
            <div style={{ margin: "0px" }}>
              {items &&
                items.map((item: any) => (
                  <a
                    key={item.item_id}
                    href={item.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="product-item">
                      <img className="product-thumbnail" src={item.image_url} />
                      <div className="product-details">
                        <h4>{item.name}</h4>
                        <p>
                          평점: {item.rating} ({item.num_ratings}개)
                        </p>
                        <p>가격: {item.price}원</p>
                      </div>
                    </div>
                  </a>
                ))}
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Products;
