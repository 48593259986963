import { IonContent, IonPage } from "@ionic/react";
import { Helmet } from "react-helmet";

const Memo: React.FC = () => {
  return (
    <IonPage>
      <IonContent fullscreen>
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
        <object
          width="100%"
          height="100%"
          data="/assets/memo.pdf"
          type="application/pdf"
        >
          Memo
        </object>
      </IonContent>
    </IonPage>
  );
};

export default Memo;
