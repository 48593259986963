import React from "react";

import { IonContent, IonPage } from "@ionic/react";

const Terms: React.FC = () => {
  return (
    <IonPage>
      <IonContent fullscreen>
        <object
          width="100%"
          height="100%"
          data="/assets/terms.pdf"
          type="application/pdf"
        >
          Terms of Service
        </object>
      </IonContent>
    </IonPage>
  );
};

export default Terms;
