import { useState } from "react";

import { Helmet } from "react-helmet";
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  IonText,
  IonToolbar,
} from "@ionic/react";
import { Link } from "react-router-dom";
import { ClipLoader } from "react-spinners";

import "./Landing.css";

const Landing: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);

  const submitForm = (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    const form = document.getElementById(
      "email-submit-form"
    ) as HTMLFormElement;
    const data = new FormData(form);
    const action = e.target.action;
    fetch(action, {
      method: "POST",
      body: data,
    })
      .then(() => {
        setIsLoading(false);
        const successMessage = document.getElementById(
          "form-submit-success-message"
        );
        successMessage!.style.display = "flex";
      })
      .catch((error) => {
        setIsLoading(false);
        alert(
          "Error occurred. Please make sure to disable tracking blockers and try again or reach out to richard.hj.song at gmail."
        );
      });
  };

  return (
    <IonPage>
      <Helmet>
        <script id="vtag-ai-js" async src="https://r2.leadsy.ai/tag.js" data-pid="1axSxBOWC48G4xEoY" data-version="062024"></script>
      </Helmet>
      <IonContent fullscreen>
        <IonGrid
          style={{
            paddingLeft: "4%",
            paddingRight: "4%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <IonRow>
            <IonToolbar>
              <IonText slot="start">
                <p
                  style={{
                    fontSize: "26px",
                    fontWeight: "400",
                    color: "black",
                    fontFamily: "Roboto-Bold",
                  }}
                >
                  Nunchi
                </p>
              </IonText>

              {/* <IonButton
                type="submit"
                color="dark"
                slot="end"
                onClick={() => navigate("/auth")}
                style={{ textTransform: "none" }}
              >
                <div
                  style={{
                    fontWeight: "600",
                    textAlign: "center",
                  }}
                >
                  {route === "authenticated" ? "Home" : "Sign in"}
                </div>
              </IonButton> */}
            </IonToolbar>
          </IonRow>
          <IonRow style={{ flexGrow: 1 }}>
            <IonCol
              style={{
                padding: "0px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h1
                style={{
                  padding: "5px",
                  fontSize: "52px",
                  fontWeight: "500",
                  fontFamily: "Roboto-Medium",
                }}
              >
                Generate Workflow Automations
              </h1>
              <h4 style={{ fontFamily: "Roboto-Regular" }}>
                Go from idea to custom automations in minutes.
              </h4>
              <h1 style={{ fontWeight: "500", fontFamily: "Roboto-Medium" }}>
                Join waitlist
              </h1>
              <div>
                <form
                  action="https://script.google.com/macros/s/AKfycbyX42gN5awz85KIZ4os7TwRSYUQVxCY5e_eFGIH08jZ3DmAdirAxVq-YOHzEfzkBX6T/exec"
                  id="email-submit-form"
                  onSubmit={submitForm}
                >
                  <IonButton
                    disabled={isLoading}
                    type="submit"
                    color="dark"
                    style={{ textTransform: "none", float: "right" }}
                  >
                    {isLoading ? (
                      <ClipLoader color="white" size={"20px"} />
                    ) : (
                      <div
                        style={{
                          fontWeight: "600",
                          textAlign: "center",
                        }}
                      >
                        Join
                      </div>
                    )}
                  </IonButton>
                  <div style={{ overflow: "hidden" }}>
                    <input
                      name="Email"
                      type="email"
                      placeholder="Enter your email"
                      required
                    />
                  </div>
                </form>
              </div>
              <IonText
                id="form-submit-success-message"
                style={{ display: "none" }}
              >
                <h4 style={{ fontFamily: "Roboto-Bold" }}>
                  Successfully joined. Stay tuned!
                </h4>
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow style={{ paddingBottom: "1%" }}>
            <IonToolbar>
              <IonText slot="start">
                <p>
                  Built by{" "}
                  <a href="https://www.linkedin.com/in/byungkyu-park/">
                    Byungkyu Park
                  </a>{" "}
                  and{" "}
                  <a href="https://www.linkedin.com/in/rsong/">Richard Song</a>
                </p>
              </IonText>
              <IonText slot="end">
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ paddingTop: "5px" }}>
                    Copyright © Nunchi 2024. All rights reserved.
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      paddingTop: "5px",
                    }}
                  >
                    <div style={{ paddingLeft: "5px" }}>
                      <a href="mailto:support@nunchi.app">Contact Us</a>
                    </div>
                    <div style={{ paddingLeft: "5px" }}>
                      <Link to="/terms">Terms</Link>
                    </div>
                    <div>
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </div>
                  </div>
                </div>
              </IonText>
            </IonToolbar>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Landing;
