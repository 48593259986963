// replace with dev endpoint for testing
export const NUNCHI_API_ROOT =
  "https://31ujlv0ikj.execute-api.us-west-2.amazonaws.com/prod";
export const COMPLETION_LAMBDA_URL =
  "https://aadpatgrrbu42f6hkh7xky4ni40hneez.lambda-url.us-west-2.on.aws";
export const TOOLBOX_LAMBDA_URL =
  "https://ciibfbx4u4ggyqjiahtldnukma0cokge.lambda-url.us-west-2.on.aws";
export const SERVICE_PUBLIC_LAMBDA_URL =
  "https://rs6lyffpwxnrdaex2pjhcsipeu0gpubm.lambda-url.us-west-2.on.aws";
export const PRODUCT_SEARCH_LAMBDA_URL =
  "https://wj2ziid3zkeinaospm2x4bbp7u0ptybx.lambda-url.us-west-2.on.aws";
export const ADMIN_USERS = new Set([
  // dev@nunchi.app cognito user pool
  "1861c3a0-3001-70dd-e6da-0cd4dcf5f9ae", // samspa.dev.pdx@gmail.com
  "e8d153d0-50c1-70d1-35c4-378d1221c59a", // richard@nunchi.app
  "684173f0-e011-70c4-b1e7-444acc5c5c80", // byungkyu@nunchi.app
  // "88415350-d091-702e-cc25-54bed97c5f55", // nunchi.dev.test@gmail.com
  "google_114594279260282550011", // bpark3179@gmail.com (Google auth)
  // samspa.dev.pdx@gmail.com cognito user pool
  "74fa9121-97e0-4060-aa5d-8c5823c921f1", // samspa.dev.pdx@gmail.com
  "e8d153d0-50c1-70d1-35c4-378d1221c59a", // richard@nunchi.app
  // "c8a1b370-a031-709f-bd3a-bc06e76e102b", // ethan.rausenberger@contentful.com
]);
export const AUTH_TOKENS = new Set([
  "88aed1ef-122e-4470-bd8a-4eafa527a4bf", // issued 8/24/23
]);

export const AGENCY_LEAD_ALLOWLISTED_USER_IDS = new Set([
  // dev@nunchi.app cognito user pool
  "1861c3a0-3001-70dd-e6da-0cd4dcf5f9ae", // samspa.dev.pdx@gmail.com
  "e8d153d0-50c1-70d1-35c4-378d1221c59a", // richard@nunchi.app
  "684173f0-e011-70c4-b1e7-444acc5c5c80", // byungkyu@nunchi.app
  "google_114594279260282550011", // bpark3179@gmail.com (Google auth)
  // samspa.dev.pdx@gmail.com cognito user pool
  "74fa9121-97e0-4060-aa5d-8c5823c921f1", // samspa.dev.pdx@gmail.com
  "e8d153d0-50c1-70d1-35c4-378d1221c59a", // richard@nunchi.app
]);
export const SAMPLE_AGENCY_LEAD_FEED_ID =
  "6c46a13e-1836-44fb-80c6-aa194ae04173";
export const MAIN_AGENCY_LEAD_FEED_ID = "e7eb3b48-22cd-4520-aa47-4fc5bacc97c9";
export const INVITE_ONLY_FEED_IDS = new Set([
  "8454763c-2b8e-494e-9e15-0a25d342baff",
  SAMPLE_AGENCY_LEAD_FEED_ID,
  MAIN_AGENCY_LEAD_FEED_ID,
]);

export const DRAWER_WIDTH = 240;
export const EXFUNC_SERVICE_API_KEY =
  "7AyA6ezXXe7xYVti20MljaafJMoPkQpGa5QTrle8";
export const EXFUNC_SERVICE_API_ROOT =
  "https://xojys085u7.execute-api.us-west-2.amazonaws.com/prod";

export interface Option {
  readonly label: string;
  readonly value: string | number | undefined;
}

export interface GroupedOption {
  readonly label: string;
  readonly value?: string;
  readonly options: readonly Option[];
}

export const SOURCE_OPTIONS: readonly Option[] = [
  { value: "LINKEDIN", label: "LinkedIn" },
  { value: "FACEBOOK", label: "Facebook" },
];

export const REGION_OPTIONS: Option[] = [
  { value: "", label: "Any Region" },
  { value: "US", label: "United States" },
  { value: "AF", label: "Afghanistan" },
  { value: "AL", label: "Albania" },
  { value: "DZ", label: "Algeria" },
  { value: "AS", label: "American Samoa" },
  { value: "AD", label: "Andorra" },
  { value: "AO", label: "Angola" },
  { value: "AI", label: "Anguilla" },
  { value: "AQ", label: "Antarctica" },
  { value: "AG", label: "Antigua & Barbuda" },
  { value: "AR", label: "Argentina" },
  { value: "AM", label: "Armenia" },
  { value: "AW", label: "Aruba" },
  { value: "AU", label: "Australia" },
  { value: "AT", label: "Austria" },
  { value: "AZ", label: "Azerbaijan" },
  { value: "BS", label: "Bahamas" },
  { value: "BH", label: "Bahrain" },
  { value: "BD", label: "Bangladesh" },
  { value: "BB", label: "Barbados" },
  { value: "BY", label: "Belarus" },
  { value: "BE", label: "Belgium" },
  { value: "BZ", label: "Belize" },
  { value: "BJ", label: "Benin" },
  { value: "BM", label: "Bermuda" },
  { value: "BT", label: "Bhutan" },
  { value: "BO", label: "Bolivia" },
  { value: "BA", label: "Bosnia & Herzegovina" },
  { value: "BW", label: "Botswana" },
  { value: "BV", label: "Bouvet Island" },
  { value: "BR", label: "Brazil" },
  { value: "IO", label: "British Indian Ocean Territory" },
  { value: "VG", label: "British Virgin Islands" },
  { value: "BN", label: "Brunei" },
  { value: "BG", label: "Bulgaria" },
  { value: "BF", label: "Burkina Faso" },
  { value: "BI", label: "Burundi" },
  { value: "KH", label: "Cambodia" },
  { value: "CM", label: "Cameroon" },
  { value: "CA", label: "Canada" },
  { value: "CV", label: "Cape Verde" },
  { value: "KY", label: "Cayman Islands" },
  { value: "CF", label: "Central African Republic" },
  { value: "TD", label: "Chad" },
  { value: "CL", label: "Chile" },
  { value: "CN", label: "China" },
  { value: "CX", label: "Christmas Island" },
  { value: "CC", label: "Cocos (Keeling) Islands" },
  { value: "CO", label: "Colombia" },
  { value: "KM", label: "Comoros" },
  { value: "CD", label: "Congo - Kinshasa" },
  { value: "CK", label: "Cook Islands" },
  { value: "CR", label: "Costa Rica" },
  { value: "CI", label: "C\u00f4te d\u2019Ivoire" },
  { value: "HR", label: "Croatia" },
  { value: "CU", label: "Cuba" },
  { value: "AN", label: "Cura\u00e7ao" },
  { value: "CY", label: "Cyprus" },
  { value: "CZ", label: "Czechia" },
  { value: "DK", label: "Denmark" },
  { value: "DJ", label: "Djibouti" },
  { value: "DM", label: "Dominica" },
  { value: "DO", label: "Dominican Republic" },
  { value: "EC", label: "Ecuador" },
  { value: "EG", label: "Egypt" },
  { value: "SV", label: "El Salvador" },
  { value: "GQ", label: "Equatorial Guinea" },
  { value: "ER", label: "Eritrea" },
  { value: "EE", label: "Estonia" },
  { value: "SZ", label: "Eswatini" },
  { value: "ET", label: "Ethiopia" },
  { value: "EU", label: "European Union" },
  { value: "FK", label: "Falkland Islands (Islas Malvinas)" },
  { value: "FO", label: "Faroe Islands" },
  { value: "FJ", label: "Fiji" },
  { value: "FI", label: "Finland" },
  { value: "FR", label: "France" },
  { value: "GF", label: "French Guiana" },
  { value: "PF", label: "French Polynesia" },
  { value: "TF", label: "French Southern Territories" },
  { value: "GA", label: "Gabon" },
  { value: "GM", label: "Gambia" },
  { value: "GE", label: "Georgia" },
  { value: "DE", label: "Germany" },
  { value: "GH", label: "Ghana" },
  { value: "GI", label: "Gibraltar" },
  { value: "GR", label: "Greece" },
  { value: "GL", label: "Greenland" },
  { value: "GD", label: "Grenada" },
  { value: "GP", label: "Guadeloupe" },
  { value: "GU", label: "Guam" },
  { value: "GT", label: "Guatemala" },
  { value: "GN", label: "Guinea" },
  { value: "GW", label: "Guinea-Bissau" },
  { value: "GY", label: "Guyana" },
  { value: "HT", label: "Haiti" },
  { value: "HM", label: "Heard & McDonald Islands" },
  { value: "HN", label: "Honduras" },
  { value: "HK", label: "Hong Kong" },
  { value: "HU", label: "Hungary" },
  { value: "IS", label: "Iceland" },
  { value: "IN", label: "India" },
  { value: "ID", label: "Indonesia" },
  { value: "IR", label: "Iran" },
  { value: "IQ", label: "Iraq" },
  { value: "IE", label: "Ireland" },
  { value: "IL", label: "Israel" },
  { value: "IT", label: "Italy" },
  { value: "JM", label: "Jamaica" },
  { value: "JP", label: "Japan" },
  { value: "JO", label: "Jordan" },
  { value: "KZ", label: "Kazakhstan" },
  { value: "KE", label: "Kenya" },
  { value: "KI", label: "Kiribati" },
  { value: "KW", label: "Kuwait" },
  { value: "KG", label: "Kyrgyzstan" },
  { value: "LA", label: "Laos" },
  { value: "LV", label: "Latvia" },
  { value: "LB", label: "Lebanon" },
  { value: "LS", label: "Lesotho" },
  { value: "LR", label: "Liberia" },
  { value: "LY", label: "Libya" },
  { value: "LI", label: "Liechtenstein" },
  { value: "LT", label: "Lithuania" },
  { value: "LU", label: "Luxembourg" },
  { value: "MO", label: "Macao" },
  { value: "MG", label: "Madagascar" },
  { value: "MW", label: "Malawi" },
  { value: "MY", label: "Malaysia" },
  { value: "MV", label: "Maldives" },
  { value: "ML", label: "Mali" },
  { value: "MT", label: "Malta" },
  { value: "MH", label: "Marshall Islands" },
  { value: "MQ", label: "Martinique" },
  { value: "MR", label: "Mauritania" },
  { value: "MU", label: "Mauritius" },
  { value: "YT", label: "Mayotte" },
  { value: "MX", label: "Mexico" },
  { value: "FM", label: "Micronesia" },
  { value: "MD", label: "Moldova" },
  { value: "MC", label: "Monaco" },
  { value: "MN", label: "Mongolia" },
  { value: "MS", label: "Montserrat" },
  { value: "MA", label: "Morocco" },
  { value: "MZ", label: "Mozambique" },
  { value: "MM", label: "Myanmar (Burma)" },
  { value: "NA", label: "Namibia" },
  { value: "NR", label: "Nauru" },
  { value: "NP", label: "Nepal" },
  { value: "NL", label: "Netherlands" },
  { value: "NC", label: "New Caledonia" },
  { value: "NZ", label: "New Zealand" },
  { value: "NI", label: "Nicaragua" },
  { value: "NE", label: "Niger" },
  { value: "NG", label: "Nigeria" },
  { value: "NU", label: "Niue" },
  { value: "NF", label: "Norfolk Island" },
  { value: "KP", label: "North Korea" },
  { value: "MK", label: "North Macedonia" },
  { value: "MP", label: "Northern Mariana Islands" },
  { value: "NO", label: "Norway" },
  { value: "OM", label: "Oman" },
  { value: "PK", label: "Pakistan" },
  { value: "PW", label: "Palau" },
  { value: "PS", label: "Palestine" },
  { value: "PA", label: "Panama" },
  { value: "PG", label: "Papua New Guinea" },
  { value: "PY", label: "Paraguay" },
  { value: "PE", label: "Peru" },
  { value: "PH", label: "Philippines" },
  { value: "PN", label: "Pitcairn Islands" },
  { value: "PL", label: "Poland" },
  { value: "PT", label: "Portugal" },
  { value: "PR", label: "Puerto Rico" },
  { value: "QA", label: "Qatar" },
  { value: "RE", label: "R\u00e9union" },
  { value: "RO", label: "Romania" },
  { value: "RU", label: "Russia" },
  { value: "RW", label: "Rwanda" },
  { value: "WS", label: "Samoa" },
  { value: "SM", label: "San Marino" },
  { value: "ST", label: "S\u00e3o Tom\u00e9 & Pr\u00edncipe" },
  { value: "SA", label: "Saudi Arabia" },
  { value: "SN", label: "Senegal" },
  { value: "RS", label: "Serbia" },
  { value: "SC", label: "Seychelles" },
  { value: "SL", label: "Sierra Leone" },
  { value: "SG", label: "Singapore" },
  { value: "SK", label: "Slovakia" },
  { value: "SI", label: "Slovenia" },
  { value: "SB", label: "Solomon Islands" },
  { value: "SO", label: "Somalia" },
  { value: "ZA", label: "South Africa" },
  { value: "GS", label: "South Georgia & South Sandwich Islands" },
  { value: "KR", label: "South Korea" },
  { value: "ES", label: "Spain" },
  { value: "LK", label: "Sri Lanka" },
  { value: "SH", label: "St. Helena" },
  { value: "KN", label: "St. Kitts & Nevis" },
  { value: "LC", label: "St. Lucia" },
  { value: "PM", label: "St. Pierre & Miquelon" },
  { value: "VC", label: "St. Vincent & Grenadines" },
  { value: "SD", label: "Sudan" },
  { value: "SR", label: "Suriname" },
  { value: "SJ", label: "Svalbard & Jan Mayen" },
  { value: "SE", label: "Sweden" },
  { value: "CH", label: "Switzerland" },
  { value: "SY", label: "Syria" },
  { value: "TW", label: "Taiwan" },
  { value: "TJ", label: "Tajikistan" },
  { value: "TZ", label: "Tanzania" },
  { value: "TH", label: "Thailand" },
  { value: "TP", label: "Timor-Leste" },
  { value: "TG", label: "Togo" },
  { value: "TK", label: "Tokelau" },
  { value: "TO", label: "Tonga" },
  { value: "TT", label: "Trinidad & Tobago" },
  { value: "TN", label: "Tunisia" },
  { value: "TR", label: "T\u00fcrkiye" },
  { value: "TM", label: "Turkmenistan" },
  { value: "TC", label: "Turks & Caicos Islands" },
  { value: "TV", label: "Tuvalu" },
  { value: "UM", label: "U.S. Outlying Islands" },
  { value: "VI", label: "U.S. Virgin Islands" },
  { value: "UG", label: "Uganda" },
  { value: "UA", label: "Ukraine" },
  { value: "AE", label: "United Arab Emirates" },
  { value: "GB", label: "United Kingdom" },
  { value: "UY", label: "Uruguay" },
  { value: "UZ", label: "Uzbekistan" },
  { value: "VU", label: "Vanuatu" },
  { value: "VA", label: "Vatican City" },
  { value: "VE", label: "Venezuela" },
  { value: "VN", label: "Vietnam" },
  { value: "WF", label: "Wallis & Futuna" },
  { value: "EH", label: "Western Sahara" },
  { value: "YE", label: "Yemen" },
  { value: "ZM", label: "Zambia" },
  { value: "ZW", label: "Zimbabwe" },
];

export const JOB_SENIORITY_OPTIONS: readonly Option[] = [
  { value: "", label: "Any Seniority" },
  { value: "CXO", label: "CXO" },
  { value: "VP", label: "VP" },
  { value: "DIRECTOR", label: "Director" },
  { value: "SENIOR", label: "Senior" },
  { value: "MANAGER", label: "Manager" },
  { value: "ENTRY", label: "Entry" },
  { value: "OTHER", label: "Other" },
];

export const JOB_FUNCTION_OPTIONS: Option[] = [
  { value: "", label: "Any Function" },
  { value: "acct", label: "Accounting / Auditing" },
  { value: "adm", label: "Administrative" },
  { value: "advr", label: "Advertising" },
  { value: "anls", label: "Analyst" },
  { value: "art", label: "Art / Creative" },
  { value: "bd", label: "Business Development" },
  { value: "cnsl", label: "Consulting" },
  { value: "cust", label: "Customer Service" },
  { value: "dist", label: "Distribution" },
  { value: "dsgn", label: "Design" },
  { value: "edu", label: "Education" },
  { value: "eng", label: "Engineering" },
  { value: "fin", label: "Finance" },
  { value: "genb", label: "General Business" },
  { value: "hcpr", label: "HealthCare Provider" },
  { value: "hr", label: "Human Resources" },
  { value: "it", label: "Information Technology" },
  { value: "lgl", label: "Legal" },
  { value: "mgmt", label: "Management" },
  { value: "mnfc", label: "Manufacturing" },
  { value: "mrkt", label: "Marketing" },
  { value: "pr", label: "Public Relations" },
  { value: "prch", label: "Purchasing" },
  { value: "prdm", label: "Product Management" },
  { value: "prjm", label: "Project Management" },
  { value: "prod", label: "Production" },
  { value: "qa", label: "QualityAssurance" },
  { value: "rsch", label: "Research" },
  { value: "sale", label: "Sales" },
  { value: "sci", label: "Science" },
  { value: "stra", label: "Strategy / Planning" },
  { value: "supl", label: "Supply Chain" },
  { value: "trng", label: "Training" },
  { value: "wrt", label: "Writing / Editing" },
];

export const COMPANY_SIZE_OPTIONS: readonly Option[] = [
  { value: "", label: "Any Size" },
  { value: "1-10", label: "1-10" },
  { value: "11-50", label: "11-50" },
  { value: "51-200", label: "51-200" },
  { value: "201-500", label: "201-500" },
  { value: "501-1000", label: "501-1000" },
  { value: "1001-5000", label: "1001-5000" },
  { value: "5001-10000", label: "5001-10000" },
  { value: "10001+", label: "10001+" },
];

export const COMPANY_INDUSTRY_OPTIONS: readonly GroupedOption[] = [
  { label: "Any Industry", options: [{ label: "Any Industry", value: "" }] },
  {
    label: "Accommodation and Food Services",
    options: [
      {
        label: "Food and Beverage Services",
        value: "Food and Beverage Services",
      },
      { label: "Hospitality", value: "Hospitality" },
    ],
  },
  {
    label: "Administrative and Support Services",
    options: [
      { label: "Collection Agencies", value: "Collection Agencies" },
      { label: "Events Services", value: "Events Services" },
      { label: "Facilities Services", value: "Facilities Services" },
      { label: "Fundraising", value: "Fundraising" },
      { label: "Office Administration", value: "Office Administration" },
      {
        label: "Security and Investigations",
        value: "Security and Investigations",
      },
      { label: "Staffing and Recruiting", value: "Staffing and Recruiting" },
      { label: "Telephone Call Centers", value: "Telephone Call Centers" },
      {
        label: "Translation and Localization",
        value: "Translation and Localization",
      },
      { label: "Travel Arrangements", value: "Travel Arrangements" },
      { label: "Writing and Editing", value: "Writing and Editing" },
    ],
  },
  {
    label: "Construction",
    options: [
      { label: "Building Construction", value: "Building Construction" },
      { label: "Civil Engineering", value: "Civil Engineering" },
      {
        label: "Specialty Trade Contractors",
        value: "Specialty Trade Contractors",
      },
    ],
  },
  {
    label: "Consumer Services",
    options: [
      {
        label: "Civic and Social Organizations",
        value: "Civic and Social Organizations",
      },
      { label: "Household Services", value: "Household Services" },
      { label: "Non-profit Organizations", value: "Non-profit Organizations" },
      {
        label: "Personal and Laundry Services",
        value: "Personal and Laundry Services",
      },
      {
        label: "Philanthropic Fundraising Services",
        value: "Philanthropic Fundraising Services",
      },
      { label: "Religious Institutions", value: "Religious Institutions" },
      { label: "Repair and Maintenance", value: "Repair and Maintenance" },
    ],
  },
  {
    label: "Education",
    options: [
      { label: "E-Learning Providers", value: "E-Learning Providers" },
      { label: "Higher Education", value: "Higher Education" },
      {
        label: "Primary and Secondary Education",
        value: "Primary and Secondary Education",
      },
      {
        label: "Professional Training and Coaching",
        value: "Professional Training and Coaching",
      },
      {
        label: "Technical and Vocational Training",
        value: "Technical and Vocational Training",
      },
    ],
  },
  {
    label: "Entertainment Providers",
    options: [
      { label: "Artists and Writers", value: "Artists and Writers" },
      {
        label: "Museums, Historical Sites, and Zoos",
        value: "Museums, Historical Sites, and Zoos",
      },
      { label: "Musicians", value: "Musicians" },
      { label: "Recreational Facilities", value: "Recreational Facilities" },
      { label: "Spectator Sports", value: "Spectator Sports" },
    ],
  },
  {
    label: "Farming, Ranching, Forestry",
    options: [
      { label: "Farming", value: "Farming" },
      { label: "Ranching and Fisheries", value: "Ranching and Fisheries" },
      { label: "Forestry and Logging", value: "Forestry and Logging" },
    ],
  },
  {
    label: "Financial Services",
    options: [
      { label: "Capital Markets", value: "Capital Markets" },
      { label: "Credit Intermediation", value: "Credit Intermediation" },
      { label: "Funds and Trusts", value: "Funds and Trusts" },
      { label: "Insurance", value: "Insurance" },
    ],
  },
  {
    label: "Government Administration",
    options: [
      {
        label: "Administration of Justice",
        value: "Administration of Justice",
      },
    ],
  },
  {
    label: "Government Administration",
    options: [
      { label: "Economic Programs", value: "Economic Programs" },
      { label: "Transportation Programs", value: "Transportation Programs" },
      { label: "Utilities Administration", value: "Utilities Administration" },
      {
        label: "Environmental Quality Programs",
        value: "Environmental Quality Programs",
      },
      {
        label: "Health and Human Services",
        value: "Health and Human Services",
      },
      {
        label: "Housing and Community Development",
        value: "Housing and Community Development",
      },
      {
        label: "Military and International Affairs",
        value: "Military and International Affairs",
      },
      { label: "Public Policy", value: "Public Policy" },
      {
        label: "Space Research and Technology",
        value: "Space Research and Technology",
      },
      { label: "Holding Companies", value: "Holding Companies" },
      {
        label: "Hospitals and Health Care",
        value: "Hospitals and Health Care",
      },
    ],
  },
  {
    label: "Manufacturing",
    options: [
      { label: "Apparel Manufacturing", value: "Apparel Manufacturing" },
      {
        label: "Appliances, Electrical, and Electronics Manufacturing",
        value: "Appliances, Electrical, and Electronics Manufacturing",
      },
      { label: "Chemical Manufacturing", value: "Chemical Manufacturing" },
      {
        label: "Computers and Electronics Manufacturing",
        value: "Computers and Electronics Manufacturing",
      },
      {
        label: "Fabricated Metal Products",
        value: "Fabricated Metal Products",
      },
      {
        label: "Food and Beverage Manufacturing",
        value: "Food and Beverage Manufacturing",
      },
      {
        label: "Furniture and Home Furnishings Manufacturing",
        value: "Furniture and Home Furnishings Manufacturing",
      },
      {
        label: "Glass, Ceramics and Concrete Manufacturing",
        value: "Glass, Ceramics and Concrete Manufacturing",
      },
      {
        label: "Leather Product Manufacturing",
        value: "Leather Product Manufacturing",
      },
      { label: "Machinery Manufacturing", value: "Machinery Manufacturing" },
      {
        label: "Medical Equipment Manufacturing",
        value: "Medical Equipment Manufacturing",
      },
      {
        label: "Oil and Coal Product Manufacturing",
        value: "Oil and Coal Product Manufacturing",
      },
      {
        label: "Paper and Forest Product Manufacturing",
        value: "Paper and Forest Product Manufacturing",
      },
      {
        label: "Pharmaceutical Manufacturing",
        value: "Pharmaceutical Manufacturing",
      },
      {
        label: "Plastics and Rubber Product Manufacturing",
        value: "Plastics and Rubber Product Manufacturing",
      },
      {
        label: "Primary Metal Manufacturing",
        value: "Primary Metal Manufacturing",
      },
      { label: "Printing Services", value: "Printing Services" },
      {
        label: "Sporting Goods Manufacturing",
        value: "Sporting Goods Manufacturing",
      },
      { label: "Textile Manufacturing", value: "Textile Manufacturing" },
      { label: "Tobacco Manufacturing", value: "Tobacco Manufacturing" },
      {
        label: "Transportation Equipment Manufacturing",
        value: "Transportation Equipment Manufacturing",
      },
    ],
  },
  {
    label: "Manufacturing",
    options: [
      {
        label: "Wood Product Manufacturing",
        value: "Wood Product Manufacturing",
      },
      {
        label: "Motor Vehicle Manufacturing",
        value: "Motor Vehicle Manufacturing",
      },
      {
        label: "Motor Vehicle Parts Manufacturing",
        value: "Motor Vehicle Parts Manufacturing",
      },
    ],
  },
  {
    label: "Oil, Gas, and Mining",
    options: [
      { label: "Mining", value: "Mining" },
      { label: "Oil and Gas", value: "Oil and Gas" },
    ],
  },
  {
    label: "Professional Services",
    options: [
      { label: "Accounting", value: "Accounting" },
      { label: "Advertising Services", value: "Advertising Services" },
      {
        label: "Architecture and Planning",
        value: "Architecture and Planning",
      },
      {
        label: "Business Consulting and Services",
        value: "Business Consulting and Services",
      },
      { label: "Design Services", value: "Design Services" },
      { label: "Engineering Services", value: "Engineering Services" },
      {
        label: "IT Services and IT Consulting",
        value: "IT Services and IT Consulting",
      },
      { label: "Legal Services", value: "Legal Services" },
      { label: "Marketing Services", value: "Marketing Services" },
      {
        label: "Public Relations and Communications Services",
        value: "Public Relations and Communications Services",
      },
      { label: "Research Services", value: "Research Services" },
      {
        label: "Services for Renewable Energy",
        value: "Services for Renewable Energy",
      },
      { label: "Veterinary Services", value: "Veterinary Services" },
    ],
  },
  {
    label: "Real Estate and Equipment Rental Services",
    options: [
      {
        label: "Equipment Rental Services",
        value: "Equipment Rental Services",
      },
      { label: "Real Estate", value: "Real Estate" },
    ],
  },
  {
    label: "Retail",
    options: [
      { label: "Food and Beverage Retail", value: "Food and Beverage Retail" },
      {
        label: "Online and Mail Order Retail",
        value: "Online and Mail Order Retail",
      },
      {
        label: "Retail Apparel and Fashion",
        value: "Retail Apparel and Fashion",
      },
      {
        label: "Retail Appliances, Electrical, and Electronic Equipment",
        value: "Retail Appliances, Electrical, and Electronic Equipment",
      },
      { label: "Retail Art Dealers", value: "Retail Art Dealers" },
      { label: "Retail Art Supplies", value: "Retail Art Supplies" },
      {
        label: "Retail Books and Printed News",
        value: "Retail Books and Printed News",
      },
      {
        label: "Retail Building Materials and Garden Equipment",
        value: "Retail Building Materials and Garden Equipment",
      },
      { label: "Retail Florists", value: "Retail Florists" },
      {
        label: "Retail Furniture and Home Furnishings",
        value: "Retail Furniture and Home Furnishings",
      },
      { label: "Retail Gasoline", value: "Retail Gasoline" },
      {
        label: "Retail Health and Personal Care Products",
        value: "Retail Health and Personal Care Products",
      },
      {
        label: "Retail Luxury Goods and Jewelry",
        value: "Retail Luxury Goods and Jewelry",
      },
      { label: "Retail Motor Vehicles", value: "Retail Motor Vehicles" },
      {
        label: "Retail Musical Instruments",
        value: "Retail Musical Instruments",
      },
      { label: "Retail Office Equipment", value: "Retail Office Equipment" },
      {
        label: "Retail Office Supplies and Gifts",
        value: "Retail Office Supplies and Gifts",
      },
      {
        label: "Retail Recyclable Materials & Used Merchandise",
        value: "Retail Recyclable Materials & Used Merchandise",
      },
    ],
  },
  {
    label: "Technology, Information and Media",
    options: [
      {
        label: "Book and Periodical Publishing",
        value: "Book and Periodical Publishing",
      },
      {
        label: "Broadcast Media Production and Distribution",
        value: "Broadcast Media Production and Distribution",
      },
      {
        label: "Computer Games",
        value: "Computer Games",
      },
      {
        label: "Data Infrastructure and Analytics",
        value: "Data Infrastructure and Analytics",
      },
      { label: "Information Services", value: "Information Services" },
      {
        label: "Internet Marketplace Platforms",
        value: "Internet Marketplace Platforms",
      },
      {
        label: "Mobile Gaming Apps",
        value: "Mobile Gaming Apps",
      },
      {
        label: "Movies, Videos, and Sound",
        value: "Movies, Videos, and Sound",
      },
      {
        label: "Social Networking Platforms",
        value: "Social Networking Platforms",
      },
      { label: "Telecommunications", value: "Telecommunications" },
      { label: "Software Development", value: "Software Development" },
    ],
  },
  {
    label: "Transportation, Logistics, Supply Chain and Storage",
    options: [
      { label: "Airlines and Aviation", value: "Airlines and Aviation" },
      {
        label: "Freight and Package Transportation",
        value: "Freight and Package Transportation",
      },
      {
        label: "Ground Passenger Transportation",
        value: "Ground Passenger Transportation",
      },
      { label: "Maritime Transportation", value: "Maritime Transportation" },
      { label: "Pipeline Transportation", value: "Pipeline Transportation" },
      { label: "Postal Services", value: "Postal Services" },
      { label: "Rail Transportation", value: "Rail Transportation" },
      { label: "Truck Transportation", value: "Truck Transportation" },
      { label: "Warehousing", value: "Warehousing" },
    ],
  },
  {
    label: "Utilities",
    options: [
      {
        label: "Electric Power Generation",
        value: "Electric Power Generation",
      },
      { label: "Natural Gas Distribution", value: "Natural Gas Distribution" },
      {
        label: "Water, Waste, Steam, and Air Conditioning Services",
        value: "Water, Waste, Steam, and Air Conditioning Services",
      },
    ],
  },
  {
    label: "Wholesale",
    options: [
      {
        label: "Wholesale Alcoholic Beverages",
        value: "Wholesale Alcoholic Beverages",
      },
      {
        label: "Wholesale Apparel and Sewing Supplies",
        value: "Wholesale Apparel and Sewing Supplies",
      },
      {
        label: "Wholesale Appliances, Electrical, and Electronics",
        value: "Wholesale Appliances, Electrical, and Electronics",
      },
      {
        label: "Wholesale Building Materials",
        value: "Wholesale Building Materials",
      },
      {
        label: "Wholesale Chemical and Allied Products",
        value: "Wholesale Chemical and Allied Products",
      },
      {
        label: "Wholesale Computer Equipment",
        value: "Wholesale Computer Equipment",
      },
      {
        label: "Wholesale Drugs and Sundries",
        value: "Wholesale Drugs and Sundries",
      },
      {
        label: "Wholesale Food and Beverage",
        value: "Wholesale Food and Beverage",
      },
      { label: "Wholesale Footwear", value: "Wholesale Footwear" },
      {
        label: "Wholesale Furniture and Home Furnishings",
        value: "Wholesale Furniture and Home Furnishings",
      },
      {
        label: "Wholesale Hardware, Plumbing, Heating Equipment",
        value: "Wholesale Hardware, Plumbing, Heating Equipment",
      },
      {
        label: "Wholesale Import and Export",
        value: "Wholesale Import and Export",
      },
      {
        label: "Wholesale Luxury Goods and Jewelry",
        value: "Wholesale Luxury Goods and Jewelry",
      },
      { label: "Wholesale Machinery", value: "Wholesale Machinery" },
      {
        label: "Wholesale Metals and Minerals",
        value: "Wholesale Metals and Minerals",
      },
      {
        label: "Wholesale Motor Vehicles and Parts",
        value: "Wholesale Motor Vehicles and Parts",
      },
      { label: "Wholesale Paper Products", value: "Wholesale Paper Products" },
      {
        label: "Wholesale Petroleum and Petroleum Products",
        value: "Wholesale Petroleum and Petroleum Products",
      },
      {
        label: "Wholesale Photography Equipment and Supplies",
        value: "Wholesale Photography Equipment and Supplies",
      },
      {
        label: "Wholesale Raw Farm Products",
        value: "Wholesale Raw Farm Products",
      },
      {
        label: "Wholesale Recyclable Materials",
        value: "Wholesale Recyclable Materials",
      },
    ],
  },
];

export const UPDATE_FREQUENCY_OPTIONS: readonly Option[] = [
  { value: "DAILY", label: "Daily" },
  { value: "WEEKLY", label: "Weekly" },
  { value: "MONTHLY", label: "Monthly" },
];

export const UPDATE_MAX_COUNT_OPTIONS: readonly Option[] = [
  { value: undefined, label: "Any Count" },
  { value: 25, label: "25" },
  { value: 50, label: "50" },
  { value: 75, label: "75" },
  { value: 100, label: "100" },
];

export const STATE_OPTIONS: readonly Option[] = [
  {
    value: "Alabama",
    label: "Alabama",
  },
  {
    value: "Alaska",
    label: "Alaska",
  },
  {
    value: "American Samoa",
    label: "American Samoa",
  },
  {
    value: "Arizona",
    label: "Arizona",
  },
  {
    value: "Arkansas",
    label: "Arkansas",
  },
  {
    value: "California",
    label: "California",
  },
  {
    value: "Colorado",
    label: "Colorado",
  },
  {
    value: "Connecticut",
    label: "Connecticut",
  },
  {
    value: "Delaware",
    label: "Delaware",
  },
  {
    value: "District Of Columbia",
    label: "District Of Columbia",
  },
  {
    value: "Federated States Of Micronesia",
    label: "Federated States Of Micronesia",
  },
  {
    value: "Florida",
    label: "Florida",
  },
  {
    value: "Georgia",
    label: "Georgia",
  },
  {
    value: "Guam",
    label: "Guam",
  },
  {
    value: "Hawaii",
    label: "Hawaii",
  },
  {
    value: "Idaho",
    label: "Idaho",
  },
  {
    value: "Illinois",
    label: "Illinois",
  },
  {
    value: "Indiana",
    label: "Indiana",
  },
  {
    value: "Iowa",
    label: "Iowa",
  },
  {
    value: "Kansas",
    label: "Kansas",
  },
  {
    value: "Kentucky",
    label: "Kentucky",
  },
  {
    value: "Louisiana",
    label: "Louisiana",
  },
  {
    value: "Maine",
    label: "Maine",
  },
  {
    value: "Marshall Islands",
    label: "Marshall Islands",
  },
  {
    value: "Maryland",
    label: "Maryland",
  },
  {
    value: "Massachusetts",
    label: "Massachusetts",
  },
  {
    value: "Michigan",
    label: "Michigan",
  },
  {
    value: "Minnesota",
    label: "Minnesota",
  },
  {
    value: "Mississippi",
    label: "Mississippi",
  },
  {
    value: "Missouri",
    label: "Missouri",
  },
  {
    value: "Montana",
    label: "Montana",
  },
  {
    value: "Nebraska",
    label: "Nebraska",
  },
  {
    value: "Nevada",
    label: "Nevada",
  },
  {
    value: "New Hampshire",
    label: "New Hampshire",
  },
  {
    value: "New Jersey",
    label: "New Jersey",
  },
  {
    value: "New Mexico",
    label: "New Mexico",
  },
  {
    value: "New York",
    label: "New York",
  },
  {
    value: "North Carolina",
    label: "North Carolina",
  },
  {
    value: "North Dakota",
    label: "North Dakota",
  },
  {
    value: "Northern Mariana Islands",
    label: "Northern Mariana Islands",
  },
  {
    value: "Ohio",
    label: "Ohio",
  },
  {
    value: "Oklahoma",
    label: "Oklahoma",
  },
  {
    value: "Oregon",
    label: "Oregon",
  },
  {
    value: "Palau",
    label: "Palau",
  },
  {
    value: "Pennsylvania",
    label: "Pennsylvania",
  },
  {
    value: "Puerto Rico",
    label: "Puerto Rico",
  },
  {
    value: "Rhode Island",
    label: "Rhode Island",
  },
  {
    value: "South Carolina",
    label: "South Carolina",
  },
  {
    value: "South Dakota",
    label: "South Dakota",
  },
  {
    value: "Tennessee",
    label: "Tennessee",
  },
  {
    value: "Texas",
    label: "Texas",
  },
  {
    value: "Utah",
    label: "Utah",
  },
  {
    value: "Vermont",
    label: "Vermont",
  },
  {
    value: "Virgin Islands",
    label: "Virgin Islands",
  },
  {
    value: "Virginia",
    label: "Virginia",
  },
  {
    value: "Washington",
    label: "Washington",
  },
  {
    value: "West Virginia",
    label: "West Virginia",
  },
  {
    value: "Wisconsin",
    label: "Wisconsin",
  },
  {
    value: "Wyoming",
    label: "Wyoming",
  },
];

export const SAMPLE_DATA = [
  {
    lead_id: "1",
    meta: {
      company_name: " Stratega ",
      company_website: " N/A ",
      person_name: " Matteo Lombardi ",
      person_job_title: " Founder ",
      person_message:
        " I agree with the process but don't you think that this is a bit too much for an SDR? Starting with the premise that if you wish to lower you CAC you need content that converts along the funnel. Wouldn't be better to have the SDRs focused on qualified leads while they work with a marketer that helps them: 1. build targeted lists 2.build content 3. analyze the numbers and improve campaigns An SDR would hardly know that you can use clearbit to identify website visitors and that these can be pushed as a notification as a buying signal. ",
      1: "1",
      2: "1",
      3: "1",
      4: "1",
      5: "1",
      6: "1",
      7: "1",
      8: "1",
    },
  },
  {
    lead_id: "2",
    meta: {
      company_name: " Pickeat ",
      company_website: " N/A ",
      person_name: " Matteo Lombardi ",
      person_job_title: " CSO ",
      person_message:
        " I agree with the process but don't you think that this is a bit too much for an SDR? Starting with the premise that if you wish to lower you CAC you need content that converts along the funnel. Wouldn't be better to have the SDRs focused on qualified leads while they work with a marketer that helps them: 1. build targeted lists 2.build content 3. analyze the numbers and improve campaigns An SDR would hardly know that you can use clearbit to identify website visitors and that these can be pushed as a notification as a buying signal. ",
    },
  },
  {
    lead_id: "3",
    meta: {
      company_name: " N/A ",
      company_website: " N/A ",
      person_name: " Scott Martinis ",
      person_job_title: " Author, Founder ",
      person_message:
        " I think the real issue I'm highlighting is that A. Most companies don't have a real outbound strategy B. The ones that do, it almost always lives inside of their best reps heads INSTEAD OF MAPPED INTO YOUR OUTBOUND SOFTWARES Team can't succeed if they don't map ICP into data provider, messaging into SEP ",
    },
  },
  {
    lead_id: "4",
    meta: {
      company_name: " N/A ",
      company_website: " N/A ",
      person_name: " Kieran Cassidy ",
      person_job_title: " N/A ",
      person_message:
        " By transforming unprofitable digital strategies, I help to restore companies\\u2019 faith in digital marketing. ",
    },
  },
  {
    lead_id: "5",
    meta: {
      company_name: " N/A ",
      company_website: " N/A ",
      person_name: " Chris Shannon ",
      person_job_title: " N/A ",
      person_message:
        " LinkedIn Nav is the best prospecting tool IMO. Zoominfo is strictly for email/phone verification for me. Lets be honest, how easy is it to mess with email variations and get it right? ",
    },
  },
  {
    lead_id: "6",
    meta: {
      company_name: " N/A ",
      company_website: " N/A ",
      person_name: " Justin Michael ",
      person_job_title: " N/A ",
      person_message:
        " If anyone needs human-verified phone numbers, I highly recommend pinging Aditya Prakash. Verifying your phone and email data is key. Then all the primary systems work well. Dumbphone + persistence (elbow grease) SEP PAD Sales Nav Set world records w/: ABSD sheet gmail cell phone switchboards Remember - 50% of contacts come from fully Navigated dials shocking statistic Gerry Hill \\ud83d\\ude80? uncovered 1) work switchboards and cell/direct dials 2) don't forget the power of chat flows (4th frame) + InMails Automate wherever possible. Shameless plug: I've put everything that works into a new book I'm crafting in case it's interesting. ",
    },
  },
  {
    lead_id: "7",
    meta: {
      company_name: " N/A ",
      company_website: " N/A ",
      person_name: " Kellen Casebeer ",
      person_job_title: " N/A ",
      person_message:
        " Aditya Prakash rules - we are very happy customers too ",
    },
  },
  {
    lead_id: "8",
    meta: {
      company_name: " N/A ",
      company_website: " N/A ",
      person_name: " Eric Buckley ",
      person_job_title: " CEO ",
      person_message:
        " Outbound is scary \\ud83e\\udd76\\ud83d\\ude31! Targeted content with a consistent nurture model will convert MQLs to qualified appointments predict ",
    },
  },
  {
    lead_id: "9",
    meta: {
      company_name: " Stratega ",
      company_website: " N/A ",
      person_name: " Matteo Lombardi ",
      person_job_title: " Founder ",
      person_message:
        " I agree with the process but don't you think that this is a bit too much for an SDR? Starting with the premise that if you wish to lower you CAC you need content that converts along the funnel. Wouldn't be better to have the SDRs focused on qualified leads while they work with a marketer that helps them: 1. build targeted lists 2.build content 3. analyze the numbers and improve campaigns An SDR would hardly know that you can use clearbit to identify website visitors and that these can be pushed as a notification as a buying signal. ",
    },
  },
  {
    lead_id: "10",
    meta: {
      company_name: " Pickeat ",
      company_website: " N/A ",
      person_name: " Matteo Lombardi ",
      person_job_title: " CSO ",
      person_message:
        " I agree with the process but don't you think that this is a bit too much for an SDR? Starting with the premise that if you wish to lower you CAC you need content that converts along the funnel. Wouldn't be better to have the SDRs focused on qualified leads while they work with a marketer that helps them: 1. build targeted lists 2.build content 3. analyze the numbers and improve campaigns An SDR would hardly know that you can use clearbit to identify website visitors and that these can be pushed as a notification as a buying signal. ",
    },
  },
  {
    lead_id: "11",
    meta: {
      company_name: " N/A ",
      company_website: " N/A ",
      person_name: " Scott Martinis ",
      person_job_title: " Author, Founder ",
      person_message:
        " I think the real issue I'm highlighting is that A. Most companies don't have a real outbound strategy B. The ones that do, it almost always lives inside of their best reps heads INSTEAD OF MAPPED INTO YOUR OUTBOUND SOFTWARES Team can't succeed if they don't map ICP into data provider, messaging into SEP ",
    },
  },
];

export const SAMPLE_LEADS = [
  {
    name: "Sophie Payne",
    company_name: "Vitally",
    id: "6102d84cc1921400014ed148",
    first_name: "Sophie",
    last_name: "Payne",
    linkedin_url: "http://www.linkedin.com/in/dr-sophie-payne",
    title: "Account Executive",
    email_status: "verified",
    photo_url:
      "https://media.licdn.com/dms/image/C4D03AQHAdr4kXTb-xQ/profile-displayphoto-shrink_200_200/0/1657620423881?e=1692835200&v=beta&t=8JZDk8wh1PL29-9IN5RXdM_6F94ax-Ij_tACpaBOXJk",
    twitter_url: null,
    github_url: null,
    facebook_url: null,
    extrapolated_email_confidence: null,
    headline: "Account Executive at Vitally",
    email: "sophie@vitally.io",
    organization_id: "5a9e2a0aa6da98d93be4e7b4",
    status: "Pending",
    employment_history: [
      {
        _id: "64906b904017db01c3f8fdb2",
        created_at: "2023-06-22T21:08:02.580Z",
        current: true,
        degree: null,
        description: null,
        emails: null,
        end_date: null,
        grade_level: null,
        kind: null,
        major: null,
        organization_id: "5a9e2a0aa6da98d93be4e7b4",
        organization_name: "Vitally.io",
        raw_address: null,
        start_date: "2023-03-01",
        title: "Account Executive",
        updated_at: "2023-06-22T21:08:02.580Z",
        id: "64906b904017db01c3f8fdb2",
        key: "64906b904017db01c3f8fdb2",
      },
      {
        _id: "6494b8324368d40001ab8e31",
        created_at: "2023-06-22T21:08:02.591Z",
        current: false,
        degree: null,
        description: null,
        emails: null,
        end_date: "2023-02-01",
        grade_level: null,
        kind: null,
        major: null,
        organization_id: "5b8451eef874f76cdb174436",
        organization_name: "GitBook",
        raw_address: null,
        start_date: "2021-12-01",
        title: "Account Executive",
        updated_at: "2023-06-22T21:08:02.591Z",
        id: "6494b8324368d40001ab8e31",
        key: "6494b8324368d40001ab8e31",
      },
      {
        _id: "6494b8324368d40001ab8e32",
        created_at: "2023-06-22T21:08:02.591Z",
        current: false,
        degree: null,
        description: null,
        emails: null,
        end_date: "2021-12-01",
        grade_level: null,
        kind: null,
        major: null,
        organization_id: "5e9ef70fbb1882008c82df2c",
        organization_name: "Oyster",
        raw_address: null,
        start_date: "2021-01-01",
        title: "Customer Success Manager",
        updated_at: "2023-06-22T21:08:02.591Z",
        id: "6494b8324368d40001ab8e32",
        key: "6494b8324368d40001ab8e32",
      },
      {
        _id: "6494b8324368d40001ab8e33",
        created_at: "2023-06-22T21:08:02.591Z",
        current: false,
        degree: null,
        description: null,
        emails: null,
        end_date: "2021-01-01",
        grade_level: null,
        kind: null,
        major: null,
        organization_id: "5db73654b9d5b400e0f8f8db",
        organization_name: "Joyn Social Media",
        raw_address: null,
        start_date: "2019-09-01",
        title: "Co-Founder & Chief Product Officer",
        updated_at: "2023-06-22T21:08:02.591Z",
        id: "6494b8324368d40001ab8e33",
        key: "6494b8324368d40001ab8e33",
      },
      {
        _id: "6494b8324368d40001ab8e34",
        created_at: "2023-06-22T21:08:02.592Z",
        current: false,
        degree: null,
        description: null,
        emails: null,
        end_date: "2019-09-01",
        grade_level: null,
        kind: null,
        major: null,
        organization_id: "54a1355569702d48e25f7e00",
        organization_name: "Exonar - Part of The NowVertical Group",
        raw_address: null,
        start_date: "2019-03-01",
        title: "Customer Success Team Lead",
        updated_at: "2023-06-22T21:08:02.592Z",
        id: "6494b8324368d40001ab8e34",
        key: "6494b8324368d40001ab8e34",
      },
      {
        _id: "6494b8324368d40001ab8e35",
        created_at: "2023-06-22T21:08:02.592Z",
        current: false,
        degree: null,
        description: null,
        emails: null,
        end_date: "2019-03-01",
        grade_level: null,
        kind: null,
        major: null,
        organization_id: "54a1355569702d48e25f7e00",
        organization_name: "Exonar - Part of The NowVertical Group",
        raw_address: null,
        start_date: "2018-09-01",
        title: "Solutions Engineer",
        updated_at: "2023-06-22T21:08:02.592Z",
        id: "6494b8324368d40001ab8e35",
        key: "6494b8324368d40001ab8e35",
      },
      {
        _id: "6494b8324368d40001ab8e36",
        created_at: "2023-06-22T21:08:02.592Z",
        current: false,
        degree: null,
        description: null,
        emails: null,
        end_date: "2017-07-01",
        grade_level: null,
        kind: null,
        major: null,
        organization_id: null,
        organization_name: "Plymouth Marine Laboratory",
        raw_address: null,
        start_date: "2017-02-01",
        title: "Environmental Psychologist",
        updated_at: "2023-06-22T21:08:02.592Z",
        id: "6494b8324368d40001ab8e36",
        key: "6494b8324368d40001ab8e36",
      },
      {
        _id: "6494b8324368d40001ab8e37",
        created_at: "2023-06-22T21:08:02.592Z",
        current: false,
        degree: null,
        description: null,
        emails: null,
        end_date: "2017-02-01",
        grade_level: null,
        kind: null,
        major: null,
        organization_id: "5ed23cb18d94aa00017797a8",
        organization_name: "Department for Education",
        raw_address: null,
        start_date: "2016-10-01",
        title: "Statistical Officer",
        updated_at: "2023-06-22T21:08:02.592Z",
        id: "6494b8324368d40001ab8e37",
        key: "6494b8324368d40001ab8e37",
      },
      {
        _id: "6494b8324368d40001ab8e38",
        created_at: "2023-06-22T21:08:02.592Z",
        current: false,
        degree: null,
        description: null,
        emails: null,
        end_date: "2017-01-01",
        grade_level: null,
        kind: null,
        major: null,
        organization_id: "54a1347369702d3a9c373600",
        organization_name: "Royal Holloway, University of London",
        raw_address: null,
        start_date: "2013-09-01",
        title: "PHD Researcher",
        updated_at: "2023-06-22T21:08:02.592Z",
        id: "6494b8324368d40001ab8e38",
        key: "6494b8324368d40001ab8e38",
      },
      {
        _id: "6494b8324368d40001ab8e39",
        created_at: "2023-06-22T21:08:02.592Z",
        current: false,
        degree: null,
        description: null,
        emails: null,
        end_date: "2013-08-01",
        grade_level: null,
        kind: null,
        major: null,
        organization_id: "5b13c729a6da98dcb895cb06",
        organization_name: "Verona Pharma",
        raw_address: null,
        start_date: "2011-08-01",
        title: "Administrative Assistant",
        updated_at: "2023-06-22T21:08:02.592Z",
        id: "6494b8324368d40001ab8e39",
        key: "6494b8324368d40001ab8e39",
      },
    ],
    state: "England",
    city: "London",
    country: "United Kingdom",
    organization: {
      id: "5a9e2a0aa6da98d93be4e7b4",
      name: "Vitally.io",
      website_url: "http://www.vitally.io",
      blog_url: null,
      angellist_url: "http://angel.co/vitally",
      linkedin_url: "http://www.linkedin.com/company/vitally.io",
      twitter_url: "https://twitter.com/vitally_io",
      facebook_url: "https://facebook.com/Vitally.io/",
      primary_phone: {
        number: "+1 931-607-7026",
        source: "Account",
      },
      languages: [],
      alexa_ranking: 368882,
      phone: "+1 931-607-7026",
      linkedin_uid: "11075131",
      founded_year: 2017,
      publicly_traded_symbol: null,
      publicly_traded_exchange: null,
      logo_url:
        "https://zenprospect-production.s3.amazonaws.com/uploads/pictures/6494495793db910001941929/picture",
      crunchbase_url: null,
      primary_domain: "vitally.io",
      sanitized_phone: "+19316077026",
      persona_counts: {},
      industry: "information technology & services",
      keywords: [
        "saas",
        "analytics",
        "business analytics",
        "customer experience",
        "enterprise software",
        "information technology",
        "software",
        "customer success",
        "b2b",
        "retention",
        "customer engagement",
        "churn",
        "onboarding",
      ],
      estimated_num_employees: 91,
      snippets_loaded: true,
      industry_tag_id: "5567cd4773696439b10b0000",
      retail_location_count: 0,
      raw_address: "109 South 5th Street, Brooklyn, New York, USA, 11249",
      street_address: "109 S 5th St",
      city: "New York",
      state: "New York",
      postal_code: "11249-5272",
      country: "United States",
      owned_by_organization_id: null,
      suborganizations: [],
      num_suborganizations: 0,
      seo_description:
        "Vitally enables forward-thinking Customer Success teams to increase revenue growth, reduce churn, and provide a proactive, world-class customer experience at scale.",
      short_description:
        "Vitally helps B2B SaaS teams like Segment, Zapier, and Productboard to deeply understand their customers and empower every customer success manager to 10x their effectiveness through powerful assistive automation.  \n\nBy quickly unifying disparate product and customer data into an incredibly powerful and easy-to-use platform, Vitally allows teams to rapidly implement better customer success processes and have an immediate impact.",
      annual_revenue_printed: "5.5M",
      annual_revenue: 5500000.0,
      total_funding: 40400000,
      total_funding_printed: "40.4M",
      latest_funding_round_date: "2023-02-01T00:00:00.000+00:00",
      latest_funding_stage: "Series B",
      funding_events: [
        {
          id: "642ec21dd584ec00014ecdda",
          date: "2023-02-01T00:00:00.000+00:00",
          news_url: null,
          type: "Series B",
          investors: "Next47, NewView Capital, Andreessen Horowitz",
          amount: "30M",
          currency: "$",
        },
        {
          id: "60f8d9aba6d627000149c125",
          date: "2021-07-21T00:00:00.000+00:00",
          news_url:
            "https://techcrunch.com/2021/07/21/andreessen-horowitz-funds-vitallys-9m-round-for-customer-experience-software/",
          type: "Series A",
          investors:
            "Andreessen Horowitz, Founder Collective, 2048 Ventures, Zelkova Ventures, TechStars",
          amount: "9.2M",
          currency: "$",
        },
        {
          id: "618bd16d44992f0001b0fe15",
          date: "2019-08-01T00:00:00.000+00:00",
          news_url: null,
          type: "Seed",
          investors:
            "Founder Collective, 2048 Ventures, Zelkova Ventures, TechStars",
          amount: "1.2M",
          currency: "$",
        },
      ],
      technology_names: [
        "Amazon AWS",
        "CloudFlare",
        "Dropbox",
        "Gmail",
        "Google Apps",
        "Google Tag Manager",
        "Hubspot",
        "Microsoft Office 365",
        "Mobile Friendly",
        "React Redux",
        "Render",
        "Route 53",
        "Varnish",
        "Wistia",
      ],
      current_technologies: [
        {
          uid: "amazon_aws",
          name: "Amazon AWS",
          category: "Hosting",
        },
        {
          uid: "cloudflare",
          name: "CloudFlare",
          category: "Web Accelerators",
        },
        {
          uid: "dropbox",
          name: "Dropbox",
          category: "Cloud Services",
        },
        {
          uid: "gmail",
          name: "Gmail",
          category: "Email Providers",
        },
        {
          uid: "google_apps",
          name: "Google Apps",
          category: "Other",
        },
        {
          uid: "google_tag_manager",
          name: "Google Tag Manager",
          category: "Tag Management",
        },
        {
          uid: "hubspot",
          name: "Hubspot",
          category: "Marketing Automation",
        },
        {
          uid: "office_365",
          name: "Microsoft Office 365",
          category: "Other",
        },
        {
          uid: "mobile_friendly",
          name: "Mobile Friendly",
          category: "Other",
        },
        {
          uid: "react_redux",
          name: "React Redux",
          category: "CSS and JavaScript Libraries",
        },
        {
          uid: "render",
          name: "Render",
          category: "Other",
        },
        {
          uid: "route_53",
          name: "Route 53",
          category: "Domain Name Services",
        },
        {
          uid: "varnish",
          name: "Varnish",
          category: "Load Balancers",
        },
        {
          uid: "wistia",
          name: "Wistia",
          category: "Online Video Platforms",
        },
      ],
    },
    phone_numbers: [
      {
        raw_number: "+1 931-607-7026",
        sanitized_number: "+19316077026",
        type: "work_hq",
        position: 0,
        status: "no_status",
        dnc_status: null,
        dnc_other_info: null,
      },
    ],
    intent_strength: null,
    show_intent: false,
    revealed_for_current_team: true,
    departments: ["master_sales"],
    subdepartments: ["inside_sales", "sales"],
    functions: ["sales"],
    seniority: "entry",
    activities: [
      {
        type: "COMMENT",
        entity_name: "Sophie Payne",
        article_body: "Where can I get myself one of these AI assistants? 😎",
        time: "2023-03-22T07:48:18.812449Z",
        url: "https://www.linkedin.com/posts/alexivos_sales-ai-activity-7041733389967601664-vV1H",
        post: {
          type: "POST",
          time: "2023-03-21T07:48:18.812449Z",
          entity_name: "Alex Ivos",
          url: "https://www.linkedin.com/posts/alexivos_sales-ai-activity-7041733389967601664-vV1H",
          article_body:
            "OpenAI released GPT-4, and it is out of this world! 🤯  But how will all of these developments in AI change sales? 🧐  Let's take a look.  There are two things that will change drastically:  ⚙️ 1. day-to-day (the operational side) 🤝 2. buying (how customers purchase products/services)   When I think of the future of salespeople, I see us focused on doing the things we love. 🤩  Helping customers and driving value.  Helping prospects champion the product they want internally.   Focused on the qualitative side of the job, that AI cannot replace.  🙅‍♂️ Say goodbye to repetitive tasks like:  - manually updating the CRM - sending the same emails over and over again - taking the same notes and filling out the same answers in playbooks  your AI assistant will do these for you. 🧞  Managers' jobs will drastically change as well. 💆‍♂️  You'll know exactly what's going on with every deal.  Forecasting will be way easier and there will be alerts and scoring.  You'll have insights and more time to focus on coaching your team.   🙅‍♂️ Say goodbye to: - asking about every deal, you'll know beforehand. - not knowing where to coach your team and help. - missing forecasts and not knowing what the pipeline actually looks like.  Buyers will know exactly what they need beforehand. 🤝  They will know exactly what they need in advance.  Tool comparisons and analysis of differences will be done for them without speaking to anyone.  The buying process will change.  Sales will work more async. 🤩  And sales will be changed, forever. 🙌  #sales #ai",
        },
      },
      {
        type: "POST",
        entity_name: "Sophie Payne",
        article_body:
          "Need some help. As prospecting becomes harder and harder, Ron Merritt and I are looking to leverage AI to reach more prospects and get more meetings.  We already use all the usual suspects, Gong, Yesware, SFDC.  The key to sales rep productivity and longevity is a full pipeline. What is the best stuff out there? Thx",
        time: "2023-05-15T13:50:02.758Z",
        url: "https://www.linkedin.com/posts/mikedeluca_need-some-help-as-prospecting-becomes-harder-activity-7063873164249812992-Ev45",
      },
      {
        type: "COMMENT",
        entity_name: "Sophie Payne",
        article_body:
          "I'm struggling to figure out how to scale it. Any tips for that?",
        time: "2023-06-15T10:42:36.743863Z",
        url: "https://www.linkedin.com/posts/thibautsouyris_i-outsource-80-of-my-prospecting-to-ai-activity-7073992277202776064-eFsj",
        post: {
          type: "POST",
          time: "2023-06-14T10:42:36.743863Z",
          entity_name: "Thibaut Souyris",
          url: "https://www.linkedin.com/posts/thibautsouyris_i-outsource-80-of-my-prospecting-to-ai-activity-7073992277202776064-eFsj",
          article_body:
            "I outsource 80% of my prospecting to AI.  Ask me anything.",
        },
      },
    ],
  },
  {
    name: "Mike Deluca",
    company_name: "Hearst",
    id: "54a62f817468692cf09c90c8",
    first_name: "Mike",
    last_name: "Deluca",
    linkedin_url: "http://www.linkedin.com/in/mikedeluca",
    title:
      "President & Publisher, Hearst Connecticut Media Group & CEO LocalEdge, Hearst",
    email_status: "verified",
    photo_url:
      "https://media.licdn.com/dms/image/C4D03AQHwNIuTlyustw/profile-displayphoto-shrink_400_400/0/1551229654308?e=1692835200&v=beta&t=J6pwvx9u5bMzw6F3C8GubA-KODgpIbmI1ME71ngHVxo",
    twitter_url: "https://twitter.com/mike_deluca",
    github_url: null,
    facebook_url: null,
    extrapolated_email_confidence: null,
    headline:
      "President & Publisher, Hearst Connecticut Media Group & CEO LocalEdge, Hearst",
    email: "mdeluca@hearst.com",
    organization_id: "5e7a3c503ab737008d48c8ff",
    status: "Pending",
    employment_history: [
      {
        _id: "6492eb3b3b649300c385555f",
        created_at: "2023-06-21T12:40:20.949Z",
        current: true,
        degree: null,
        description: null,
        emails: null,
        end_date: null,
        grade_level: null,
        kind: null,
        major: null,
        organization_id: "5e7a3c503ab737008d48c8ff",
        organization_name: "Hearst",
        raw_address: null,
        start_date: "2019-01-01",
        title:
          "President & Publisher, Hearst Connecticut Media Group & CEO LocalEdge, Hearst",
        updated_at: "2023-06-21T12:40:20.949Z",
        id: "6492eb3b3b649300c385555f",
        key: "6492eb3b3b649300c385555f",
      },
      {
        _id: "6492eb3b3b649300c3855560",
        created_at: "2023-06-21T12:40:20.949Z",
        current: true,
        degree: null,
        description: null,
        emails: null,
        end_date: null,
        grade_level: null,
        kind: null,
        major: null,
        organization_id: "5b854edaf874f7389a4f1874",
        organization_name: "Orchard",
        raw_address: null,
        start_date: "2020-08-01",
        title: "Strategic Advisor",
        updated_at: "2023-06-21T12:40:20.949Z",
        id: "6492eb3b3b649300c3855560",
        key: "6492eb3b3b649300c3855560",
      },
      {
        _id: "6492efb4efa9860001aff0bf",
        created_at: "2023-06-21T12:40:20.994Z",
        current: false,
        degree: null,
        description: null,
        emails: null,
        end_date: "2019-05-01",
        grade_level: null,
        kind: null,
        major: null,
        organization_id: "5e7a3c503ab737008d48c8ff",
        organization_name: "Hearst",
        raw_address: null,
        start_date: "2013-06-01",
        title: "CEO at LocalEdge & SVP Hearst Digital",
        updated_at: "2023-06-21T12:40:20.994Z",
        id: "6492efb4efa9860001aff0bf",
        key: "6492efb4efa9860001aff0bf",
      },
      {
        _id: "6492efb4efa9860001aff0c0",
        created_at: "2023-06-21T12:40:20.994Z",
        current: false,
        degree: null,
        description: null,
        emails: null,
        end_date: "2019-05-01",
        grade_level: null,
        kind: null,
        major: null,
        organization_id: "5e7a3c503ab737008d48c8ff",
        organization_name: "Hearst",
        raw_address: null,
        start_date: "2013-06-01",
        title: "EVP Advertising Sales, Hearst & CEO LocalEdge",
        updated_at: "2023-06-21T12:40:20.994Z",
        id: "6492efb4efa9860001aff0c0",
        key: "6492efb4efa9860001aff0c0",
      },
      {
        _id: "6492efb4efa9860001aff0c1",
        created_at: "2023-06-21T12:40:20.994Z",
        current: false,
        degree: null,
        description: null,
        emails: null,
        end_date: "2013-06-01",
        grade_level: null,
        kind: null,
        major: null,
        organization_id: "54a1cb5174686954759e3e11",
        organization_name: "Groupon",
        raw_address: null,
        start_date: "2011-12-01",
        title: "SVP of Sales & Business Development, Groupon",
        updated_at: "2023-06-21T12:40:20.994Z",
        id: "6492efb4efa9860001aff0c1",
        key: "6492efb4efa9860001aff0c1",
      },
      {
        _id: "6492efb4efa9860001aff0c2",
        created_at: "2023-06-21T12:40:20.994Z",
        current: false,
        degree: null,
        description: null,
        emails: null,
        end_date: "2013-06-01",
        grade_level: null,
        kind: null,
        major: null,
        organization_id: "54a1222e69702d94a4902a03",
        organization_name: "Yodle",
        raw_address: null,
        start_date: "2010-12-01",
        title: "Advisory Board",
        updated_at: "2023-06-21T12:40:20.994Z",
        id: "6492efb4efa9860001aff0c2",
        key: "6492efb4efa9860001aff0c2",
      },
      {
        _id: "6492efb4efa9860001aff0c3",
        created_at: "2023-06-21T12:40:20.994Z",
        current: false,
        degree: null,
        description: null,
        emails: null,
        end_date: "2011-02-01",
        grade_level: null,
        kind: null,
        major: null,
        organization_id: "54a1222e69702d94a4902a03",
        organization_name: "Yodle",
        raw_address: null,
        start_date: "2008-02-01",
        title: "SVP of Sales & Marketing",
        updated_at: "2023-06-21T12:40:20.994Z",
        id: "6492efb4efa9860001aff0c3",
        key: "6492efb4efa9860001aff0c3",
      },
      {
        _id: "6492efb4efa9860001aff0c4",
        created_at: "2023-06-21T12:40:20.994Z",
        current: false,
        degree: null,
        description: null,
        emails: null,
        end_date: "2008-02-01",
        grade_level: null,
        kind: null,
        major: null,
        organization_id: "5f68b9291f1604008c1291c8",
        organization_name: "Yahoo",
        raw_address: null,
        start_date: "2003-12-01",
        title: "VP of Sales and Account Management- Yahoo Hotjobs",
        updated_at: "2023-06-21T12:40:20.994Z",
        id: "6492efb4efa9860001aff0c4",
        key: "6492efb4efa9860001aff0c4",
      },
      {
        _id: "6492efb4efa9860001aff0c5",
        created_at: "2023-06-21T12:40:20.994Z",
        current: false,
        degree: null,
        description: null,
        emails: null,
        end_date: "2003-11-01",
        grade_level: null,
        kind: null,
        major: null,
        organization_id: null,
        organization_name: "Storageapps/HP",
        raw_address: null,
        start_date: "2000-10-01",
        title: "Sales Director",
        updated_at: "2023-06-21T12:40:20.994Z",
        id: "6492efb4efa9860001aff0c5",
        key: "6492efb4efa9860001aff0c5",
      },
      {
        _id: "6492efb4efa9860001aff0c6",
        created_at: "2023-06-21T12:40:20.994Z",
        current: false,
        degree: null,
        description: null,
        emails: null,
        end_date: "2000-01-01",
        grade_level: null,
        kind: null,
        major: null,
        organization_id: "5e994fa74b7f9d009868d6fd",
        organization_name: "EMC",
        raw_address: null,
        start_date: "1993-01-01",
        title: "Sales Manager",
        updated_at: "2023-06-21T12:40:20.994Z",
        id: "6492efb4efa9860001aff0c6",
        key: "6492efb4efa9860001aff0c6",
      },
    ],
    state: "New York",
    city: "New York",
    country: "United States",
    organization: {
      id: "5e7a3c503ab737008d48c8ff",
      name: "Hearst",
      website_url: "http://www.hearst.com",
      blog_url: null,
      angellist_url: null,
      linkedin_url: "http://www.linkedin.com/company/hearst",
      twitter_url: "https://twitter.com/hearst",
      facebook_url: "https://www.facebook.com/HearstCorp",
      primary_phone: { number: "+1 214-618-9003", source: "Account" },
      languages: [],
      alexa_ranking: 160806,
      phone: "+1 214-618-9003",
      linkedin_uid: "4520",
      founded_year: 1887,
      publicly_traded_symbol: null,
      publicly_traded_exchange: null,
      logo_url:
        "https://zenprospect-production.s3.amazonaws.com/uploads/pictures/648e1be13aad2b000168782a/picture",
      crunchbase_url: null,
      primary_domain: "hearst.com",
      sanitized_phone: "+12146189003",
      persona_counts: {},
      industry: "media production",
      keywords: [
        "television",
        "digital",
        "magazines",
        "entertainment",
        "publishing",
        "business media",
        "cable",
        "radio",
        "real estate",
        "syndication",
        "marketing",
      ],
      estimated_num_employees: 20000,
      snippets_loaded: true,
      industry_tag_id: "5567e0ea7369640d2ba31600",
      retail_location_count: 0,
      raw_address: "300 West 57th Street, New York, NY 10019, US",
      street_address: "300 West 57th Street",
      city: "New York",
      state: "New York",
      postal_code: "10019",
      country: "United States",
      owned_by_organization_id: null,
      suborganizations: [
        {
          id: "5eef30541cbe5f0001c2b27d",
          name: "Plainview Herald",
          website_url: null,
        },
      ],
      num_suborganizations: 1,
      seo_description: "",
      short_description:
        "Hearst is a leading global, diversified information, services and media company with operations in 40 countries. Its major interests include financial services leader Fitch Group; Hearst Health, a group of medical information and services businesses; Hearst Transportation, which includes CAMP Systems International, a major provider of software-as-a-service solutions for managing maintenance of jets and helicopters; ownership in cable television networks such as A&E, HISTORY, Lifetime and ESPN; 33 television stations; 24 daily and 52 weekly newspapers; digital services businesses; and nearly 250 magazines around the world.",
      annual_revenue_printed: "11.5B",
      annual_revenue: 11500000000.0,
      total_funding: null,
      total_funding_printed: null,
      latest_funding_round_date: null,
      latest_funding_stage: null,
      funding_events: [],
      technology_names: [
        "Apache",
        "Apache Coyote",
        "Apache Coyote v1.1",
        "Atlassian Cloud",
        "Brightcove",
        "Emma",
        "Facebook Custom Audiences",
        "Fastly CDN",
        "Google Analytics",
        "Google Tag Manager",
        "Liferay",
        "Linkedin Marketing Solutions",
        "Microsoft Office 365",
        "Mobile Friendly",
        "OneTrust",
        "Outlook",
        "Pardot",
        "Rackspace",
        "Route 53",
        "SendInBlue",
        "Sendgrid",
        "UltraDns",
        "UptimeRobot",
        "Varnish",
        "Vimeo",
        "YouTube",
        "reCAPTCHA",
      ],
      current_technologies: [
        { uid: "apache", name: "Apache", category: "Load Balancers" },
        {
          uid: "apache_coyote",
          name: "Apache Coyote",
          category: "Frameworks and Programming Languages",
        },
        {
          uid: "apache_coyote_v1_1",
          name: "Apache Coyote v1.1",
          category: "Frameworks and Programming Languages",
        },
        { uid: "atlassian_cloud", name: "Atlassian Cloud", category: "CMS" },
        {
          uid: "brightcove",
          name: "Brightcove",
          category: "Online Video Platforms",
        },
        { uid: "emma", name: "Emma", category: "Email Marketing" },
        {
          uid: "facebook_web_custom_audiences",
          name: "Facebook Custom Audiences",
          category: "Retargeting",
        },
        {
          uid: "fastly_cdn",
          name: "Fastly CDN",
          category: "Content Delivery Networks",
        },
        {
          uid: "google_analytics",
          name: "Google Analytics",
          category: "Analytics and Tracking",
        },
        {
          uid: "google_tag_manager",
          name: "Google Tag Manager",
          category: "Tag Management",
        },
        { uid: "liferay", name: "Liferay", category: "CMS" },
        {
          uid: "linkedin_display_ads__formerly_bizo",
          name: "Linkedin Marketing Solutions",
          category: "Advertising Networks",
        },
        { uid: "office_365", name: "Microsoft Office 365", category: "Other" },
        { uid: "mobile_friendly", name: "Mobile Friendly", category: "Other" },
        { uid: "onetrust", name: "OneTrust", category: "Widgets" },
        { uid: "outlook", name: "Outlook", category: "Email Providers" },
        { uid: "pardot", name: "Pardot", category: "Marketing Automation" },
        { uid: "rackspace", name: "Rackspace", category: "Hosting" },
        { uid: "route_53", name: "Route 53", category: "Domain Name Services" },
        { uid: "sendinblue", name: "SendInBlue", category: "Email Marketing" },
        { uid: "sendgrid", name: "Sendgrid", category: "Email Delivery" },
        { uid: "ultradns", name: "UltraDns", category: "Domain Name Services" },
        {
          uid: "uptimerobot",
          name: "UptimeRobot",
          category: "Analytics and Tracking",
        },
        { uid: "varnish", name: "Varnish", category: "Load Balancers" },
        { uid: "vimeo", name: "Vimeo", category: "Online Video Platforms" },
        { uid: "youtube", name: "YouTube", category: "Online Video Platforms" },
        { uid: "recaptcha", name: "reCAPTCHA", category: "Captcha" },
      ],
    },
    phone_numbers: [
      {
        raw_number: "+1 214-618-9003",
        sanitized_number: "+12146189003",
        type: "work_hq",
        position: 0,
        status: "no_status",
        dnc_status: null,
        dnc_other_info: null,
      },
    ],
    intent_strength: null,
    show_intent: false,
    revealed_for_current_team: true,
    departments: ["c_suite", "master_marketing"],
    subdepartments: ["executive", "advertising"],
    functions: ["media_and_commmunication"],
    seniority: "c_suite",
    activities: [
      {
        type: "POST",
        entity_name: "Mike DeLuca",
        article_body:
          "Need some help. As prospecting becomes harder and harder, Ron Merritt and I are looking to leverage AI to reach more prospects and get more meetings.  We already use all the usual suspects, Gong, Yesware, SFDC.  The key to sales rep productivity and longevity is a full pipeline. What is the best stuff out there? Thx",
        time: "2023-05-15T13:50:02.758Z",
        url: "https://www.linkedin.com/posts/mikedeluca_need-some-help-as-prospecting-becomes-harder-activity-7063873164249812992-Ev45",
      },
    ],
  },
  {
    name: "Tyler Hickey",
    company_name: "Branch",
    id: "60b00169bbda6b0001cd568b",
    first_name: "Tyler",
    last_name: "Hickey",
    linkedin_url: "http://www.linkedin.com/in/tylerjhickey",
    title: "Sales Development Representative",
    email_status: "verified",
    photo_url:
      "https://media.licdn.com/dms/image/C5603AQGaQWJ0lSg8Bw/profile-displayphoto-shrink_400_400/0/1664023847216?e=1692835200&v=beta&t=0PVTenL9OUHMwfdf-yOhwsV4HaYAUFUh2pf64KK0mcQ",
    twitter_url: null,
    github_url: null,
    facebook_url: null,
    extrapolated_email_confidence: null,
    headline: "SDR @ Branch | Helping other SDRs ramp well | Customer-Obsessed",
    email: "tyler.hickey@branch.io",
    organization_id: "5569e86173696425eeb5b800",
    status: "Pending",
    employment_history: [
      {
        _id: "649311c4af14a600a3bb69b8",
        created_at: "2023-06-21T15:21:49.038Z",
        current: true,
        degree: null,
        description: null,
        emails: null,
        end_date: null,
        grade_level: null,
        kind: null,
        major: null,
        organization_id: "5569e86173696425eeb5b800",
        organization_name: "Branch",
        raw_address: null,
        start_date: "2022-12-01",
        title: "Sales Development Representative",
        updated_at: "2023-06-21T15:21:49.038Z",
        id: "649311c4af14a600a3bb69b8",
        key: "649311c4af14a600a3bb69b8",
      },
      {
        _id: "6493158df37ea700012c3e95",
        created_at: "2023-06-21T15:21:49.099Z",
        current: false,
        degree: null,
        description: null,
        emails: null,
        end_date: "2022-11-01",
        grade_level: null,
        kind: null,
        major: null,
        organization_id: "54a11bbe69702d84c5455600",
        organization_name: "Modine Manufacturing Company",
        raw_address: null,
        start_date: "2022-07-01",
        title: "Key Account Manager I",
        updated_at: "2023-06-21T15:21:49.099Z",
        id: "6493158df37ea700012c3e95",
        key: "6493158df37ea700012c3e95",
      },
      {
        _id: "6493158df37ea700012c3e96",
        created_at: "2023-06-21T15:21:49.099Z",
        current: false,
        degree: null,
        description: null,
        emails: null,
        end_date: "2022-06-01",
        grade_level: null,
        kind: null,
        major: null,
        organization_id: "54a11bbe69702d84c5455600",
        organization_name: "Modine Manufacturing Company",
        raw_address: null,
        start_date: "2022-01-01",
        title: "Account Manager II",
        updated_at: "2023-06-21T15:21:49.099Z",
        id: "6493158df37ea700012c3e96",
        key: "6493158df37ea700012c3e96",
      },
      {
        _id: "6493158df37ea700012c3e97",
        created_at: "2023-06-21T15:21:49.099Z",
        current: false,
        degree: null,
        description: null,
        emails: null,
        end_date: "2021-12-01",
        grade_level: null,
        kind: null,
        major: null,
        organization_id: "54a11bbe69702d84c5455600",
        organization_name: "Modine Manufacturing Company",
        raw_address: null,
        start_date: "2021-01-01",
        title: "Account Manager I",
        updated_at: "2023-06-21T15:21:49.099Z",
        id: "6493158df37ea700012c3e97",
        key: "6493158df37ea700012c3e97",
      },
      {
        _id: "6493158df37ea700012c3e98",
        created_at: "2023-06-21T15:21:49.099Z",
        current: false,
        degree: null,
        description: null,
        emails: null,
        end_date: "2018-09-01",
        grade_level: null,
        kind: null,
        major: null,
        organization_id: "60f2e82a0cd80600019d4ec2",
        organization_name: "Geneva National Resort & Club",
        raw_address: null,
        start_date: "2017-05-01",
        title: "Player Services",
        updated_at: "2023-06-21T15:21:49.099Z",
        id: "6493158df37ea700012c3e98",
        key: "6493158df37ea700012c3e98",
      },
      {
        _id: "6493158df37ea700012c3e99",
        created_at: "2023-06-21T15:21:49.099Z",
        current: false,
        degree: null,
        description: null,
        emails: null,
        end_date: "2016-09-01",
        grade_level: null,
        kind: null,
        major: null,
        organization_id: "615e14bfa072f40001fb64a5",
        organization_name: "Snap Fitness 24/7",
        raw_address: null,
        start_date: "2015-12-01",
        title: "Assistant Club Manager",
        updated_at: "2023-06-21T15:21:49.099Z",
        id: "6493158df37ea700012c3e99",
        key: "6493158df37ea700012c3e99",
      },
      {
        _id: "6493158df37ea700012c3e9a",
        created_at: "2023-06-21T15:21:49.099Z",
        current: false,
        degree: null,
        description: null,
        emails: null,
        end_date: "2016-09-01",
        grade_level: null,
        kind: null,
        major: null,
        organization_id: "63109274b1c1090001923b52",
        organization_name: "Bear Property Management, Inc.",
        raw_address: null,
        start_date: "2014-05-01",
        title: "Grounds Maintenance",
        updated_at: "2023-06-21T15:21:49.099Z",
        id: "6493158df37ea700012c3e9a",
        key: "6493158df37ea700012c3e9a",
      },
    ],
    state: "Wisconsin",
    city: "Burlington",
    country: "United States",
    organization: {
      id: "5569e86173696425eeb5b800",
      name: "Branch",
      website_url: "http://www.branch.io",
      blog_url: null,
      angellist_url: "http://angel.co/branch-metrics",
      linkedin_url: "http://www.linkedin.com/company/branch-metrics",
      twitter_url: "https://twitter.com/branchmetrics",
      facebook_url: "https://www.facebook.com/branchmetrics",
      primary_phone: { number: "+1 650-209-6461", source: "Account" },
      languages: ["English", "English", "English"],
      alexa_ranking: 23916,
      phone: "+1 650-209-6461",
      linkedin_uid: "3813083",
      founded_year: 2014,
      publicly_traded_symbol: null,
      publicly_traded_exchange: null,
      logo_url:
        "https://zenprospect-production.s3.amazonaws.com/uploads/pictures/64942c63102ad300011aa529/picture",
      crunchbase_url: null,
      primary_domain: "branch.io",
      sanitized_phone: "+16502096461",
      persona_counts: {},
      industry: "information technology & services",
      keywords: [
        "mobile",
        "software",
        "marketing",
        "information technology",
        "deep linking",
        "mobile analytics",
        "mobile referral",
        "mobile install attribution",
        "mobile growth",
        "attribution",
        "mobile ecosystem",
        "mobile apps",
        "martech",
      ],
      estimated_num_employees: 640,
      snippets_loaded: true,
      industry_tag_id: "5567cd4773696439b10b0000",
      retail_location_count: 0,
      raw_address:
        "195 Page Mill Road, Building B, 2nd Floor, Palo Alto, California, USA, 94306",
      street_address: "195 Page Mill Rd",
      city: "Palo Alto",
      state: "California",
      postal_code: "94306-2030",
      country: "United States",
      owned_by_organization_id: null,
      suborganizations: [],
      num_suborganizations: 0,
      seo_description:
        "An app.link is an example of a deep link. It takes you to content within a mobile app for a better (still private) experience.",
      short_description:
        "At Branch, we're transforming how brands and users interact across digital platforms. Our mobile marketing and deep linking solutions are trusted to deliver seamless experiences that increase ROI, decrease wasted spend, and eliminate siloed attribution. By integrating Branch technology into core marketing channels including apps, web, email, social media, search, and paid ads, leading brands are driving higher-value conversions than ever before.\n\nWe power mobile links, attribution, and measurement for more than 3 billion monthly users and have been selected by over 100,000 apps since 2014, including Adobe, BuzzFeed, Yelp, and many more, and are working on exciting new ways to improve discovery in the mobile ecosystem. Branch has raised more than $667M from investors such as NEA, Founders Fund, and Playground Ventures and is headquartered in Palo Alto, CA and with offices around the globe.",
      annual_revenue_printed: "72M",
      annual_revenue: 72000000.0,
      total_funding: 664000000,
      total_funding_printed: "664M",
      latest_funding_round_date: "2022-02-01T00:00:00.000+00:00",
      latest_funding_stage: "Venture (Round not Specified)",
      funding_events: [
        {
          id: "62506aa7bcb00900015baaf0",
          date: "2022-02-01T00:00:00.000+00:00",
          news_url: null,
          type: "Venture (Round not Specified)",
          investors: "NEA",
          amount: "300M",
          currency: "$",
        },
        {
          id: "5dbb763b6c450200019cc1ac",
          date: "2019-09-01T00:00:00.000+00:00",
          news_url: "https://pitchbook.com/profiles/company/66223-18",
          type: "Series E",
          investors: "",
          amount: "125M",
          currency: "$",
        },
        {
          id: "5b93113bf874f73759cf18ae",
          date: "2018-09-07T00:00:00.000+00:00",
          news_url:
            "https://techcrunch.com/2018/09/07/deep-linking-startup-branch-is-raising-more-than-100m-at-a-unicorn-valuation/",
          type: "Series D",
          investors: "",
          amount: "129M",
          currency: "$",
        },
        {
          id: "58ee1137a6da9862924b3001",
          date: "2017-04-11T00:00:00.000+00:00",
          news_url: "https://techcrunch.com/2017/04/10/branch-60m-andy-rubin/",
          type: "Series C",
          investors:
            "Playground Ventures, Founders Fund, New Enterprise Associates, Pear Ventures, Cowboy Ventures, Madrona Ventures",
          amount: "60M",
          currency: "$",
        },
        {
          id: "56aa048f66544a27f60045ec",
          date: "2016-01-27T00:00:00.000+00:00",
          news_url:
            "http://techcrunch.com/2016/01/27/branch-metrics-snags-35-million-more-for-its-deep-linking-app-discovery-business/",
          type: "Series B",
          investors:
            "New Enterprise Associates, Pejman Mar Ventures, Cowboy Ventures, Madrona Venture Group, Founders Fund",
          amount: "35M",
          currency: "$",
        },
        {
          id: "59e6775ca6da986f3da1d75d",
          date: "2015-02-26T00:00:00.000+00:00",
          news_url:
            "http://pevc.dowjones.com/article?an=DJFVW00120150226eb2qaxalo&from=alert&pid=32&ReturnUrl=http%3a%2f%2fpevc.dowjones.com%2farticle%3fan%3dDJFVW00120150226eb2qaxalo%26from%3dalert%26pid%3d32",
          type: "Series A",
          investors:
            "New Enterprise Associate, Pejman Mar Ventures, Cowboy Ventures, TriplePoint Capital",
          amount: "15M",
          currency: "$",
        },
      ],
      technology_names: [
        "Amazon AWS",
        "Atlassian Cloud",
        "DemandBase",
        "Drift",
        "Gmail",
        "Google Apps",
        "Google Font API",
        "Google Tag Manager",
        "Greenhouse.io",
        "Marketo",
        "Mixpanel",
        "Mobile Friendly",
        "OneTrust",
        "Pingdom",
        "Route 53",
        "StatusPage IO",
        "Vidyard",
        "WP Engine",
        "WordPress.org",
        "Zendesk",
      ],
      current_technologies: [
        { uid: "amazon_aws", name: "Amazon AWS", category: "Hosting" },
        { uid: "atlassian_cloud", name: "Atlassian Cloud", category: "CMS" },
        {
          uid: "demandbase",
          name: "DemandBase",
          category: "Analytics and Tracking",
        },
        { uid: "drift", name: "Drift", category: "Widgets" },
        { uid: "gmail", name: "Gmail", category: "Email Providers" },
        { uid: "google_apps", name: "Google Apps", category: "Other" },
        { uid: "google_font_api", name: "Google Font API", category: "Fonts" },
        {
          uid: "google_tag_manager",
          name: "Google Tag Manager",
          category: "Tag Management",
        },
        {
          uid: "greenhouse_io",
          name: "Greenhouse.io",
          category: "Recruitment",
        },
        { uid: "marketo", name: "Marketo", category: "Marketing Automation" },
        {
          uid: "mixpanel",
          name: "Mixpanel",
          category: "Analytics and Tracking",
        },
        { uid: "mobile_friendly", name: "Mobile Friendly", category: "Other" },
        { uid: "onetrust", name: "OneTrust", category: "Widgets" },
        {
          uid: "pingdom",
          name: "Pingdom",
          category: "Web Performance Monitoring",
        },
        { uid: "route_53", name: "Route 53", category: "Domain Name Services" },
        {
          uid: "statuspage",
          name: "StatusPage IO",
          category: "Web Performance Monitoring",
        },
        { uid: "vidyard", name: "Vidyard", category: "Online Video Platforms" },
        { uid: "wp_engine", name: "WP Engine", category: "CMS" },
        { uid: "wordpress_org", name: "WordPress.org", category: "CMS" },
        { uid: "zendesk", name: "Zendesk", category: "Support and Feedback" },
      ],
    },
    phone_numbers: [
      {
        raw_number: "+1 650-209-6461",
        sanitized_number: "+16502096461",
        type: "work_hq",
        position: 0,
        status: "no_status",
        dnc_status: null,
        dnc_other_info: null,
      },
    ],
    intent_strength: null,
    show_intent: false,
    revealed_for_current_team: true,
    departments: ["master_sales"],
    subdepartments: ["inside_sales", "sales"],
    functions: ["sales"],
    seniority: "entry",
    activities: [
      {
        type: "COMMENT",
        article_body:
          "I'm struggling to figure out how to scale it. Any tips for that?",
        time: "2023-06-15T10:42:36.743863Z",
        url: "https://www.linkedin.com/posts/thibautsouyris_i-outsource-80-of-my-prospecting-to-ai-activity-7073992277202776064-eFsj",
        post: {
          type: "POST",
          entity_name: "Thibaut Souyris",
          time: "2023-06-14T10:42:36.743863Z",
          url: "https://www.linkedin.com/posts/thibautsouyris_i-outsource-80-of-my-prospecting-to-ai-activity-7073992277202776064-eFsj",
          article_body:
            "I outsource 80% of my prospecting to AI.  Ask me anything.",
        },
      },
    ],
  },
];

export const EXFUNC_EXAMPLES: { [key: string]: any } = {
  "2ae4bf48-94dc-bbd5-fba1-7faf2d374879": {
    input: {
      q: "nunchi",
    },
    output: {
      search_result_page: {
        "@context": "https://schema.org",
        "@type": "SearchResultPage",
        breadcrumb: {
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              position: 1,
              "@type": "ListItem",
              item: {
                "@type": "Thing",
                name: "Nunchi - Wikipedia",
                url: "https://en.wikipedia.org/wiki/Nunchi",
              },
            },
            {
              position: 2,
              "@type": "ListItem",
              item: {
                "@type": "Thing",
                name: "NUNCHI | Korean Inspired Fine Jewelry",
                url: "https://www.livenunchi.com/",
              },
            },
            {
              position: 3,
              "@type": "ListItem",
              item: {
                "@type": "Thing",
                name: "What is 'nunchi', the Korean secret to happiness? - The Guardian",
                url: "https://www.theguardian.com/lifeandstyle/2019/nov/11/what-is-nunchi-the-korean-secret-to-happiness",
              },
            },
            {
              position: 4,
              "@type": "ListItem",
              item: {
                "@type": "Thing",
                name: "The Korean Secret to Happiness and Success - The New York Times",
                url: "https://www.nytimes.com/2019/11/02/opinion/sunday/nunchi.html",
              },
            },
            {
              position: 5,
              "@type": "ListItem",
              item: {
                "@type": "Thing",
                name: "Eat Nunchi",
                url: "https://eatnunchi.com/",
              },
            },
            {
              position: 6,
              "@type": "ListItem",
              item: {
                "@type": "Thing",
                name: "8 Rules For Nunchi, The Korean Secret To Emotional Intelligence",
                url: "https://www.mindbodygreen.com/articles/what-is-nunchi",
              },
            },
            {
              position: 7,
              "@type": "ListItem",
              item: {
                "@type": "Thing",
                name: "The Hidden Power of Nunchi (\ub208\uce58) - CIEE",
                url: "https://www.ciee.org/go-abroad/college-study-abroad/blog/hidden-power-nunchi-nunchi",
              },
            },
            {
              position: 8,
              "@type": "ListItem",
              item: {
                "@type": "Thing",
                name: "lexie park (@eatnunchi) \u2022 Instagram photos and videos",
                url: "https://www.instagram.com/eatnunchi/?hl=en",
              },
            },
            {
              position: 9,
              "@type": "ListItem",
              item: {
                "@type": "Thing",
                name: "Article Korean Nunchi and Well-Being - PhilArchive",
                url: "https://philarchive.org/archive/ROBKNA-2",
              },
            },
            {
              position: 10,
              "@type": "ListItem",
              item: {
                "@type": "Thing",
                name: "Korean American Fine Jewelry - NUNCHI",
                url: "https://www.livenunchi.com/collections/shop",
              },
            },
            {
              position: 11,
              "@type": "ListItem",
              item: {
                "@type": "Thing",
                name: "The Power of Nunchi: The Korean Secret to Happiness and Success",
                url: "https://www.amazon.com/Power-Nunchi-Korean-Happiness-Success/dp/1786331802",
              },
            },
            {
              position: 12,
              "@type": "ListItem",
              item: {
                "@type": "Thing",
                name: "Nunchi - KoreanLII",
                url: "http://www.koreanlii.or.kr/w/index.php/Nunchi",
              },
            },
            {
              position: 13,
              "@type": "ListItem",
              item: {
                "@type": "Thing",
                name: "The Nunchi Game for an ESL class in Korea",
                url: "https://canaderuraee.wordpress.com/2014/09/03/the-nunchi-game-for-an-esl-class-in-korea/",
              },
            },
            {
              position: 14,
              "@type": "ListItem",
              item: {
                "@type": "Thing",
                name: "3 Korean-Australians explain the word '\ub208\uce58' (noonchi/nunchi)",
                url: "https://www.youtube.com/watch?v=Oi8hZFHdHDk",
              },
            },
            {
              position: 15,
              "@type": "ListItem",
              item: {
                "@type": "Thing",
                name: "The Power of Nunchi Summary of Key Ideas and Review | Euny Hong",
                url: "https://www.blinkist.com/en/books/the-power-of-nunchi-en",
              },
            },
            {
              position: 16,
              "@type": "ListItem",
              item: {
                "@type": "Thing",
                name: "Nunchi Essential Oils",
                url: "https://www.nunchioils.com.au/",
              },
            },
            {
              position: 17,
              "@type": "ListItem",
              item: {
                "@type": "Thing",
                name: "The Power of Nunchi by Euny Hong - Penguin Random House",
                url: "https://www.penguinrandomhouse.com/books/608336/the-power-of-nunchi-by-euny-hong/",
              },
            },
            {
              position: 18,
              "@type": "ListItem",
              item: {
                "@type": "Thing",
                name: "'Nunchi', the Korean Secret to a Happier Life - Alan Trapulionis",
                url: "https://alan-12169.medium.com/nunchi-the-korean-secret-to-a-happier-life-709288991673",
              },
            },
            {
              position: 19,
              "@type": "ListItem",
              item: {
                "@type": "Thing",
                name: "'The Power of Nunchi: the Korean Secret to Happiness and Success ...",
                url: "https://www.weforum.org/agenda/2019/09/the-power-of-nunchi-the-korean-secret-to-happiness-and-success-an-extract/",
              },
            },
            {
              position: 20,
              "@type": "ListItem",
              item: {
                "@type": "Thing",
                name: "Nunchi as a Countercultural Posture: How the Korean Construct ...",
                url: "https://christianscholars.com/nunchi-as-a-countercultural-posture-how-the-korean-construct-helps-me-support-students/",
              },
            },
          ],
        },
      },
    },
  },
  "f88149f4-58ac-798c-fd9b-f55c71f810da": {
    input: {
      q: "nunchi",
    },
    output: {
      search_result_page: {
        "@context": "https://schema.org",
        "@type": "SearchResultPage",
        breadcrumb: {
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              position: 1,
              "@type": "ListItem",
              item: {
                "@type": "Thing",
                name: "NUNCHI - LinkedIn",
                url: "https://www.linkedin.com/company/livenunchi",
              },
            },
            {
              position: 2,
              "@type": "ListItem",
              item: {
                "@type": "Thing",
                name: "Nunchi Health | LinkedIn",
                url: "https://www.linkedin.com/company/nunchi-health",
              },
            },
            {
              position: 3,
              "@type": "ListItem",
              item: {
                "@type": "Thing",
                name: "Nunchi | LinkedIn",
                url: "https://www.linkedin.com/company/nunchistudio/?trk=products_details_guest_organization_page",
              },
            },
            {
              position: 4,
              "@type": "ListItem",
              item: {
                "@type": "Thing",
                name: "Nunchi Corp | LinkedIn",
                url: "https://www.linkedin.com/company/nunchi-corp",
              },
            },
            {
              position: 5,
              "@type": "ListItem",
              item: {
                "@type": "Thing",
                name: "Nunchi - LinkedIn",
                url: "https://uk.linkedin.com/company/nunchi",
              },
            },
            {
              position: 6,
              "@type": "ListItem",
              item: {
                "@type": "Thing",
                name: "Nunchi | LinkedIn",
                url: "https://in.linkedin.com/company/nunchicomm",
              },
            },
            {
              position: 7,
              "@type": "ListItem",
              item: {
                "@type": "Thing",
                name: "NUNCHI FREIGHT LLC - LinkedIn",
                url: "https://www.linkedin.com/company/nunchi-freight",
              },
            },
            {
              position: 8,
              "@type": "ListItem",
              item: {
                "@type": "Thing",
                name: "Nunchi AB | LinkedIn",
                url: "https://www.linkedin.com/company/nunchi-ab",
              },
            },
            {
              position: 9,
              "@type": "ListItem",
              item: {
                "@type": "Thing",
                name: "Nunchi Marine - Singapore - LinkedIn",
                url: "https://sg.linkedin.com/company/nunchi-marine",
              },
            },
            {
              position: 10,
              "@type": "ListItem",
              item: {
                "@type": "Thing",
                name: "Nunchi - LinkedIn Belgium",
                url: "https://be.linkedin.com/company/nunchi-be",
              },
            },
            {
              position: 11,
              "@type": "ListItem",
              item: {
                "@type": "Thing",
                name: "Noonchi | LinkedIn",
                url: "https://www.linkedin.com/company/noonchi-restaurant",
              },
            },
          ],
        },
      },
    },
  },
  "1ce9280e-f8e4-c8d4-954c-6f27dea6e970": {
    input: {
      q: "talent",
      location: "united states",
    },
    output: {
      search_result_page: {
        "@context": "https://schema.org",
        "@type": "SearchResultPage",
        breadcrumb: {
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              position: 1,
              "@type": "ListItem",
              item: {
                "@type": "Thing",
                name: "Sr. Director, Talent Management",
                url: "https://www.linkedin.com/jobs/view/sr-director-talent-management-at-chipotle-mexican-grill-3811214182",
              },
            },
            {
              position: 2,
              "@type": "ListItem",
              item: {
                "@type": "Thing",
                name: "Director, Talent Acquisition - Stores",
                url: "https://www.linkedin.com/jobs/view/director-talent-acquisition-stores-at-rue21-3826531332",
              },
            },
            {
              position: 3,
              "@type": "ListItem",
              item: {
                "@type": "Thing",
                name: "Director, Talent & Culture",
                url: "https://www.linkedin.com/jobs/view/director-talent-culture-at-accor-3826593452",
              },
            },
            {
              position: 4,
              "@type": "ListItem",
              item: {
                "@type": "Thing",
                name: "Talent Coordinator",
                url: "https://www.linkedin.com/jobs/view/talent-coordinator-at-milk-%2B-honey-3829483460",
              },
            },
            {
              position: 5,
              "@type": "ListItem",
              item: {
                "@type": "Thing",
                name: "Playboy Talent Scout",
                url: "https://www.linkedin.com/jobs/view/playboy-talent-scout-at-playboy-enterprises-inc-3813139517",
              },
            },
            {
              position: 6,
              "@type": "ListItem",
              item: {
                "@type": "Thing",
                name: "Talent Acquisition Lead",
                url: "https://www.linkedin.com/jobs/view/talent-acquisition-lead-at-american-honda-motor-company-inc-3813118439",
              },
            },
            {
              position: 7,
              "@type": "ListItem",
              item: {
                "@type": "Thing",
                name: "Talent Acquisition Specialist",
                url: "https://www.linkedin.com/jobs/view/talent-acquisition-specialist-at-lululemon-3810826130",
              },
            },
            {
              position: 8,
              "@type": "ListItem",
              item: {
                "@type": "Thing",
                name: "Retail Recruiter - Landmark",
                url: "https://www.linkedin.com/jobs/view/retail-recruiter-landmark-at-tiffany-co-3804434927",
              },
            },
            {
              position: 9,
              "@type": "ListItem",
              item: {
                "@type": "Thing",
                name: "Manager, Talent Management",
                url: "https://www.linkedin.com/jobs/view/manager-talent-management-at-red-bull-3780685404",
              },
            },
            {
              position: 10,
              "@type": "ListItem",
              item: {
                "@type": "Thing",
                name: "Director, Talent Acquisition",
                url: "https://www.linkedin.com/jobs/view/director-talent-acquisition-at-convive-brands-3774246784",
              },
            },
          ],
        },
      },
    },
  },
  "60c25436-ca04-5efa-57a0-f6689c1a1736": {
    input: {
      q: "Design and execute PowerPoint pitch books and custom presentations. Apply and update custom templates to presentations with consistency and technical formatting. Create company branded and custom charts, graphs, tables, icons and other infographics. Create fully functional custom PowerPoint templates. Perform photo re-touching and photo manipulation. Perform Logo creation, recreation or vectorization. Design or recreate highly detailed and complex maps (shale, oil, pipelines). Design print collateral (invitations, signage, and banners). Format Word, Excel and PowerPoint documents. Build and style Word templates. Setup and run mail merges (badges, labels, letters, and cards). Work with creating, combining, editing, watermarking, redacting and optimizing PDF documents. Be involved in redlining documents. Other similar presentation design or document formatting requests.",
    },
    output: {
      item_list: {
        "@context": "https://schema.org",
        "@type": "ItemList",
        itemListElement: [
          {
            position: 1,
            "@type": "ListItem",
            item: {
              "@type": "Thing",
              name: "design execute powerpoint pitch books custom presentations apply update custom templates presentations consistency technical formatting create company branded custom charts, graphs, tables, icons infographics create fully functional custom powerpoint templates perform photo re-touching photo manipulation",
              url: null,
            },
          },
          {
            position: 2,
            "@type": "ListItem",
            item: {
              "@type": "Thing",
              name: "perform photo re-touching photo manipulation perform logo creation, recreation vectorization design recreate highly detailed complex maps (shale, oil, pipelines) design print collateral (invitations, signage, banners) format word, excel powerpoint documents build style word templates",
              url: null,
            },
          },
          {
            position: 3,
            "@type": "ListItem",
            item: {
              "@type": "Thing",
              name: "build style word templates setup run mail merges (badges, labels, letters, cards) work creating, combining, editing, watermarking, redacting optimizing pdf documents involved redlining documents similar presentation design document formatting requests",
              url: null,
            },
          },
        ],
      },
    },
  },
  "a6f8e3d7-19fd-f189-52e8-98a9cfd19cf1": {
    input: {
      url: "https://www.linkedin.com/company/aquent",
    },
    output: {
      organization: {
        "@context": "https://schema.org",
        "@type": "Organization",
        name: "Aquent",
        location: "Boston, MA, US",
        overview:
          'Aquent\'s industry is "Staffing and Recruiting". Aquent\'s overview is "Aquent is the leading global work solutions company helping guide businesses toward the talent, technology, and services they need to excel. From specialty recruiting and talent experience to creative strategy and project management, Aquent is making the future of work better for everyone. Its brands include Aquent Talent, Aquent Studios, Aquent RoboHead, Aquent Scout, Aquent Employer of Record, and Aquent Gymnasium. To learn more, visit aquent.com.". Aquent\'s specialties are "Employer of Record Services, Digital Marketing, Graphic Design, Freelance Recruitment, Extended Workforce Services, Marketing Talent, Creative Talent, Marketing Staffing, Creative Staffing, Web Development, Staffing, Web Development Staffing, UX Staffing, Professional Development, Content Marketing, Creative Studio, UI Staffing, UI, UX, Web Development Staffing, and Project Management Cloud Solution".',
      },
    },
  },
  "9f562d14-2aed-50ff-abc1-8a99760a17f4": {
    input: {
      url: "https://www.linkedin.com/jobs/view/graphic-designer-at-aquent-3810133040",
    },
    output: {
      job_posting: {
        "@context": "https://schema.org",
        "@type": "JobPosting",
        responsibilities:
          "Design and execute PowerPoint pitch books and custom presentations. Apply and update custom template to presentations with consistency and technical formatting. Create company branded and custom charts, graphs, tables, icons and other infographics. Create fully functional custom PowerPoint templates. Photo re-touching and photo manipulation. Logo creation, recreation or vectorization. Design or recreate highly detailed and complex maps (shale, oil, pipelines). Design print collateral (invitations, signage, and banners) - this is 5-10% of total work. Format Word, Excel and PowerPoint documents. Build and style Word templates. Setup and run mail merges (badges, labels, letters, and cards). Work with creating, combining, editing, watermarking, redacting and optimizing PDF documents. Redlining documents. Other similar presentation design or document formatting requests.",
        qualifications:
          "Ability to work independently on a project or collaboratively as a team player and able to integrate with a diverse team with varying opinions and ideas. Expert knowledge of Microsoft PowerPoint, Word, Excel (non-technical), and Adobe Suite. Intermediate knowledge of Adobe Photoshop, InDesign, and Illustrator. Strong knowledge and experience designing infographics, financial charts, tables and graphs. Ability to take ideas and think creatively to make professional, aesthetically pleasing layouts while maintaining company identity standards. Ability to work with a job ticketing system, work well under pressure and make quick sound decisions. Professional written and verbal communications skills. Flexible and open to changing priorities and managing multiple tasks simultaneously within tight deadlines. Research skills - know the places to look and manage time spent efficiently. Strong aptitude to acclimatize and learn quickly. MUST be able to work ONSITE in West Los Angeles area 2x per week (Usually Tuesday/Thursday).",
        hiringOrganization: {
          "@type": "Organization",
          name: "Financial company",
          location: null,
          overview: null,
        },
      },
    },
  },
  "4fb0d603-7826-97af-2c4c-017227ae1361": {
    input: {
      item: {
        name: "80Twenty hiring Senior Copywriter in Arizona, United States | LinkedIn",
        url: "https://www.linkedin.com/jobs/view/senior-copywriter-at-80twenty-3809698632",
      },
    },
    output: {
      val: true,
    },
  },
  "18a5017b-469a-3d5e-2012-5f32534b429d": {
    input: {
      organization: {
        "@context": "https://schema.org",
        "@type": "Organization",
        name: "Aquent",
        location: "Boston, MA, US",
        overview:
          'Aquent\'s industry is "Staffing and Recruiting". Aquent\'s overview is "Aquent is the leading global work solutions company helping guide businesses toward the talent, technology, and services they need to excel. From specialty recruiting and talent experience to creative strategy and project management, Aquent is making the future of work better for everyone. Its brands include Aquent Talent, Aquent Studios, Aquent RoboHead, Aquent Scout, Aquent Employer of Record, and Aquent Gymnasium. To learn more, visit aquent.com.". Aquent\'s specialties are "Employer of Record Services, Digital Marketing, Graphic Design, Freelance Recruitment, Extended Workforce Services, Marketing Talent, Creative Talent, Marketing Staffing, Creative Staffing, Web Development, Staffing, Web Development Staffing, UX Staffing, Professional Development, Content Marketing, Creative Studio, UI Staffing, UI, UX, Web Development Staffing, and Project Management Cloud Solution".',
      },
      values: ["Staffing & Recruiting"],
    },
    output: {
      val: true,
    },
  },
  "2e19e2d2-60d5-54ad-1fe4-ab684bcab735": {
    input: {
      organization: {
        "@context": "https://schema.org",
        "@type": "Organization",
        name: "Aquent",
        location: "Boston, MA, US",
        overview:
          'Aquent\'s industry is "Staffing and Recruiting". Aquent\'s overview is "Aquent is the leading global work solutions company helping guide businesses toward the talent, technology, and services they need to excel. From specialty recruiting and talent experience to creative strategy and project management, Aquent is making the future of work better for everyone. Its brands include Aquent Talent, Aquent Studios, Aquent RoboHead, Aquent Scout, Aquent Employer of Record, and Aquent Gymnasium. To learn more, visit aquent.com.". Aquent\'s specialties are "Employer of Record Services, Digital Marketing, Graphic Design, Freelance Recruitment, Extended Workforce Services, Marketing Talent, Creative Talent, Marketing Staffing, Creative Staffing, Web Development, Staffing, Web Development Staffing, UX Staffing, Professional Development, Content Marketing, Creative Studio, UI Staffing, UI, UX, Web Development Staffing, and Project Management Cloud Solution".',
      },
      values: ["Aquent Agency"],
    },
    output: {
      val: true,
    },
  },
  "201c7aa8-5619-948a-e2c5-793e355c1ec6": {
    input: {
      job_posting_1: {
        "@context": "https://schema.org",
        "@type": "JobPosting",
        responsibilities:
          "Design and execute PowerPoint pitch books and custom presentations. Apply and update custom template to presentations with consistency and technical formatting. Create company branded and custom charts, graphs, tables, icons and other infographics. Create fully functional custom PowerPoint templates. Photo re-touching and photo manipulation. Logo creation, recreation or vectorization. Design or recreate highly detailed and complex maps (shale, oil, pipelines). Design print collateral (invitations, signage, and banners) - this is 5-10% of total work. Format Word, Excel and PowerPoint documents. Build and style Word templates. Setup and run mail merges (badges, labels, letters, and cards). Work with creating, combining, editing, watermarking, redacting and optimizing PDF documents. Redlining documents. Other similar presentation design or document formatting requests.",
        qualifications: null,
      },
      job_posting_2: {
        "@context": "https://schema.org",
        "@type": "JobPosting",
        responsibilities:
          " Design and execute PowerPoint pitch books and custom presentations. Apply and update custom templates to presentations with consistency and technical formatting. Create company branded and custom charts, graphs, tables, icons and other infographics. Create fully functional custom PowerPoint templates. Perform photo re-touching and photo manipulation. Perform Logo creation, recreation or vectorization. Design or recreate highly detailed and complex maps (shale, oil, pipelines). Design print collateral (invitations, signage, and banners). Format Word, Excel and PowerPoint documents. Build and style Word templates. Setup and run mail merges (badges, labels, letters, and cards). Work with creating, combining, editing, watermarking, redacting and optimizing PDF documents. Be involved in redlining documents. Other similar presentation design or document formatting requests.",
        qualifications:
          "2+ years of experience as a Presentations Specialist or similar capacity editing financial documents, spreadsheets and presentations. Expert knowledge of Microsoft PowerPoint, Word, Excel (non-technical), and Adobe Acrobat Pro. Intermediate knowledge of Adobe Photoshop, InDesign, and Illustrator. Strong knowledge and experience designing infographics, financial charts, tables and graphs. Ability to work independently on a project or collaboratively as a team player and able to integrate with a diverse team with varying opinions and ideas. Ability to take ideas and think creatively to make professional, aesthetically pleasing layouts while maintaining company identity standards. Ability to work with a job ticketing system, work well under pressure and make quick sound decisions. Professional written and verbal communications skills. Flexible and open to changing priorities and managing multiple tasks simultaneously within tight deadlines. Research skills - know the places to look and manage time spent efficiently. Strong aptitude to acclimatize and learn quickly.",
      },
    },
    output: {
      val: true,
    },
  },
};
