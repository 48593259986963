import React from "react";
import moment from "moment";

import { useNavigate } from "react-router";
import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useAuthenticator } from "@aws-amplify/ui-react";

import {
  SERVICE_ACCOUNT_ID,
  Function,
  capitalizeFirstLetter,
  listFunctions,
} from "../api/Nunchi";
import { StyledTableCell, StyledTableRow } from "./CustomTable";

import "@aws-amplify/ui-react/styles.css";

const Functions: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuthenticator((context) => [context.user]);
  const [functions, setFunctions] = React.useState<Function[]>([]);

  React.useEffect(() => {
    (async function () {
      const newFunctions: Function[] = [];
      if (user.getUsername() !== SERVICE_ACCOUNT_ID) {
        const response = await listFunctions(user, SERVICE_ACCOUNT_ID);
        if (response.ok) {
          const responseData = await response.json();
          newFunctions.push(...responseData.functions);
        } else {
          alert("Unexpected error occurred");
        }
      }

      const response = await listFunctions(user);
      if (response.ok) {
        const responseData = await response.json();
        newFunctions.push(...responseData.functions);
      } else {
        alert("Unexpected error occurred");
      }

      setFunctions(
        newFunctions.sort((a: Function, b: Function) =>
          a.name.localeCompare(b.name)
        )
      );
    })();
  }, []);

  return (
    <Stack direction="column" spacing={2} width={"100%"}>
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography variant={"h6"} color="text.primary">
          Functions
        </Typography>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell style={{ width: 200 }} align="right">
                Status
              </StyledTableCell>
              <StyledTableCell style={{ width: 200 }} align="right">
                Creation Date
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {functions.map((item) => (
              <StyledTableRow
                key={item.name}
                sx={{ cursor: "pointer" }}
                onClick={() =>
                  navigate({ pathname: `/v2/functions/${item.function_id}` })
                }
              >
                <StyledTableCell>{item.name}</StyledTableCell>
                <StyledTableCell style={{ width: 200 }} align="right">
                  {capitalizeFirstLetter(item.status)}
                </StyledTableCell>
                <StyledTableCell style={{ width: 200 }} align="right">
                  {moment(item.creation_time).local().format("l LT")}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default Functions;
