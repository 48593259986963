import React, { useCallback, useEffect, useMemo, useState } from "react";
import DataGrid, { Column, SortColumn } from "react-data-grid";
import moment from "moment";

import Home from "../components/Home";

import { Chip } from "@mui/material";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { IonButton, IonList, IonText } from "@ionic/react";
import { useNavigate, useSearchParams } from "react-router-dom";

import {
  INTENT_VALUE_LABEL,
  SERVICE_ACCOUNT_ID,
  Feed,
  listFeeds,
  capitalizeFirstLetter,
  listFeedItems,
} from "../api/Nunchi";
import { ADMIN_USERS, INVITE_ONLY_FEED_IDS } from "../constants";

import "./Feeds.css";

type Row = {
  account_id: string;
  feed_id: string;
  description: string;
  status: string;
  last_event_time: string;
  creation_time: string;
};

const Feeds: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuthenticator((context) => [context.user]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [rows, setRows] = useState<Row[]>([]);
  const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);
  const onSortColumnsChange = useCallback((sortColumns: SortColumn[]) => {
    setSortColumns(sortColumns.slice(-1));
  }, []);

  useEffect(() => {
    if (sortColumns.length > 0) {
      const { columnKey, direction } = sortColumns[0];
      let sortedRows: Row[] = [...rows];
      switch (columnKey) {
        default:
          sortedRows = sortedRows.sort((a, b) =>
            a[columnKey as keyof Row].localeCompare(b[columnKey as keyof Row])
          );
      }
      setRows(direction === "DESC" ? sortedRows.reverse() : sortedRows);
    }
  }, [sortColumns]);

  useEffect(() => {
    if (!ADMIN_USERS.has(user.getUsername())) {
      setSearchParams({});
    }

    (async function () {
      const newRows: Row[] = [];
      if (user.username !== SERVICE_ACCOUNT_ID) {
        const serviceAccountResp = await listFeeds(
          user,
          SERVICE_ACCOUNT_ID,
          undefined
        );
        if (serviceAccountResp.ok) {
          const serviceAccountRespData = await serviceAccountResp.json();
          newRows.push(
            ...serviceAccountRespData.feeds
              .filter((feed: Feed) => !INVITE_ONLY_FEED_IDS.has(feed.feed_id))
              .map((feed: Feed) => ({
                account_id: feed.account_id,
                feed_id: feed.feed_id,
                description: capitalizeFirstLetter(
                  feed.resource_description,
                  false
                ),
                status: feed.status,
                last_event_time: feed.creation_time,
                creation_time: feed.creation_time,
              }))
          );
        }
      }

      const accountId = searchParams.get("accountId") || undefined;
      const response = await listFeeds(user, accountId, undefined);
      if (response.ok) {
        const responseData = await response.json();
        newRows.push(
          ...responseData.feeds
            .filter((feed: Feed) => feed.status != "DELETED")
            .map((feed: Feed) => ({
              account_id: feed.account_id,
              feed_id: feed.feed_id,
              description: capitalizeFirstLetter(
                feed.resource_description,
                false
              ),
              status: feed.status,
              last_event_time: feed.creation_time,
              creation_time: feed.creation_time,
            }))
        );
      }

      for (var row of newRows) {
        const listFeedItemsResp = await listFeedItems(
          user,
          row.account_id,
          row.feed_id,
          undefined,
          1,
          undefined
        );
        if (listFeedItemsResp.ok) {
          const listFeedItemsRespData = await listFeedItemsResp.json();
          if (listFeedItemsRespData.feed_items.length > 0) {
            row.last_event_time =
              listFeedItemsRespData.feed_items[0].creation_time;
          }
        }
      }

      setRows(newRows);
    })();
  }, []);

  const columns = useMemo((): readonly Column<Row>[] => {
    return [
      {
        key: "feed",
        name: "Feed",
        resizable: true,
        sortable: true,
        renderCell({ row, onRowChange }) {
          return (
            <div
              onClick={() => {
                if (row.status !== "PENDING") {
                  navigate({
                    pathname: row.feed_id,
                    search: searchParams.toString(),
                  });
                }
              }}
            >
              {row.account_id === SERVICE_ACCOUNT_ID && (
                <Chip
                  label="Public"
                  color="success"
                  variant="outlined"
                  size="small"
                  style={{ marginRight: "5px" }}
                />
              )}
              {row.status === "PENDING" ? (
                <span>{row.description}</span>
              ) : (
                <span className={"hyperlink"}>{row.description}</span>
              )}
            </div>
          );
        },
      },
      {
        key: "status",
        name: "Status",
        resizable: true,
        sortable: true,
        width: 200,
        renderCell({ row, onRowChange }) {
          return (
            <div>
              <IonText>{capitalizeFirstLetter(row.status)}</IonText>
            </div>
          );
        },
      },
      {
        key: "last_event_time",
        name: "Last Event Time",
        resizable: true,
        sortable: true,
        width: 200,
        renderCell({ row, onRowChange }) {
          return (
            <div>
              <IonText>
                {moment(row.last_event_time).local().format("l LT")}
              </IonText>
            </div>
          );
        },
      },
      {
        key: "creation_time",
        name: "Creation Time",
        resizable: true,
        sortable: true,
        width: 200,
        renderCell({ row, onRowChange }) {
          return (
            <div>
              <IonText>
                {moment(row.creation_time).local().format("l LT")}
              </IonText>
            </div>
          );
        },
      },
    ];
  }, []);

  return (
    <Home>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          paddingTop: "2%",
          paddingBottom: "2%",
          paddingLeft: "10%",
          paddingRight: "10%",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <IonText>
            <h1 style={{ margin: "0px" }}>Feeds</h1>
          </IonText>
          <IonButton
            color="dark"
            fill="clear"
            style={{
              width: "fit-content",
              border: "0.1px solid rgba(0, 0, 0, 0.2)",
            }}
            onClick={() => {
              navigate({ pathname: "create", search: searchParams.toString() });
            }}
          >
            <div
              style={{
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              Create
            </div>
          </IonButton>
        </div>
        <IonList
          color="none"
          lines="none"
          style={{
            background: "transparent",
            paddingTop: "15px",
          }}
        >
          <DataGrid
            className="rdg-light"
            style={{ height: "auto" }}
            columns={columns}
            rows={rows}
            sortColumns={sortColumns}
            onSortColumnsChange={onSortColumnsChange}
            rowHeight={() => 45}
          />
        </IonList>
      </div>
    </Home>
  );
};

export default Feeds;
