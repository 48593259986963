import { IonCol, IonContent, IonGrid, IonPage } from "@ionic/react";
import { Helmet } from "react-helmet";

const Demo: React.FC = () => {
  return (
    <IonPage>
      <IonContent fullscreen>
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
        <IonGrid
          style={{
            backgroundColor: "black",
            paddingLeft: "4%",
            paddingRight: "4%",
            minHeight: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <IonCol
            size="12"
            size-lg="12"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: "0px",
            }}
          >
            <video width="90%" controls>
              <source src="assets/nunchi-v2-demo.mov" type="video/mp4" />
            </video>
          </IonCol>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Demo;
