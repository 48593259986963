import React from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import Drawer from "../components/Drawer";
import Tasks from "../components/Tasks";

import {
  AppBar,
  Box,
  Breadcrumbs,
  CssBaseline,
  Toolbar,
  Typography,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

import { DRAWER_WIDTH } from "../constants";
import { darkTheme } from "../components/Theme";

import "@aws-amplify/ui-react/styles.css";

const TasksPage: React.FC = () => {
  const breadcrumbs = [
    <Typography key="1" color="text.primary">
      Tasks
    </Typography>,
  ];

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Box minHeight={"100%"} overflow={"auto"} sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: `calc(100% - ${DRAWER_WIDTH}px)`,
            ml: `${DRAWER_WIDTH}px`,
          }}
        >
          <Toolbar>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              {breadcrumbs}
            </Breadcrumbs>
          </Toolbar>
        </AppBar>
        <Drawer />
        <Box
          component="main"
          sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
        >
          <Toolbar />
          <Tasks />
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default TasksPage;
