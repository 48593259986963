import React, { MouseEventHandler, useEffect, useState } from "react";
import ReactSelect, {
  ControlProps,
  MultiValueGenericProps,
  components,
} from "react-select";
import CreatableSelect from "react-select/creatable";

import Home from "../components/Home";
import Logo from "../components/Logo";

import { useAuthenticator } from "@aws-amplify/ui-react";
import { ClipLoader } from "react-spinners";
import { IonButton, IonIcon, IonText } from "@ionic/react";
import { closeOutline, closeSharp } from "ionicons/icons";
import {
  InputAdornment,
  List,
  ListItem,
  MenuItem,
  TextField,
  Select,
} from "@mui/material";
import { useNavigate } from "react-router";
import { useParams, useSearchParams } from "react-router-dom";

import {
  SOURCE_OPTIONS,
  REGION_OPTIONS,
  JOB_SENIORITY_OPTIONS,
  JOB_FUNCTION_OPTIONS,
  COMPANY_SIZE_OPTIONS,
  COMPANY_INDUSTRY_OPTIONS,
  UPDATE_FREQUENCY_OPTIONS,
  UPDATE_MAX_COUNT_OPTIONS,
  Option,
  GroupedOption,
  ADMIN_USERS,
} from "../constants";
import {
  INTENT_VALUE_LABEL,
  RESOURCE_TYPE_VALUE_LABEL,
  IntentValue,
  ResourceTypeValue,
  getFeed,
  updateFeed,
} from "../api/Nunchi";

import "@aws-amplify/ui-react/styles.css";

const IS_LOCALHOST = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

const formatGroupLabel = (data: GroupedOption) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    }}
  >
    <span>{data.label}</span>
    <span
      style={{
        backgroundColor: "#EBECF0",
        borderRadius: "2em",
        color: "#172B4D",
        display: "inline-block",
        fontSize: 12,
        fontWeight: "normal",
        lineHeight: "1",
        minWidth: 1,
        padding: "0.16666666666667em 0.5em",
        textAlign: "center",
      }}
    >
      {data.options.length}
    </span>
  </div>
);

const MultiValueLabel = (props: MultiValueGenericProps<Option>) => {
  const url =
    props.data.value === "LINKEDIN"
      ? "https://www.linkedin.com"
      : "https://www.facebook.com";

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        paddingLeft: "5px",
      }}
    >
      <Logo url={url} />
      <components.MultiValueLabel {...props} />
    </div>
  );
};

const Control = ({
  children,
  ...props
}: ControlProps<Option, true, GroupedOption>) => {
  // @ts-ignore
  const { include, setInclude } = props.selectProps;

  const onMouseDown: MouseEventHandler<HTMLSpanElement> = (e) => {
    setInclude(!include);
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <components.Control {...props}>
      <span
        onMouseDown={onMouseDown}
        style={{
          borderRight: "0.1px solid rgba(0, 0, 0, .1)",
          paddingLeft: "15px",
          paddingRight: "15px",
          cursor: "pointer",
        }}
      >
        {include ? "Include" : "Exclude"}
      </span>
      {children}
    </components.Control>
  );
};

const FeedEdit: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuthenticator((context) => [context.user]);
  const { feedId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [intent, setIntent] = useState<string>(IntentValue.EXPRESSING_INTEREST);
  const [query, setQuery] = useState<string>("");
  const [resourceType, setResourceType] = useState<string>(
    ResourceTypeValue.SOCIAL_MEDIA_POSTING
  );
  const [resourceDescription, setResourceDescription] = useState<string>("");
  const [sources, setSources] = useState<any>([]);
  const [regions, setRegions] = useState<any>({
    include: true,
    value: [],
  });
  const [jobSeniority, setJobSeniority] = useState<any>({
    include: true,
    value: [],
  });
  const [jobFunctions, setJobFunctions] = useState<any>({
    include: true,
    value: [],
  });
  const [companySizes, setCompanySizes] = useState<any>({
    include: true,
    value: [],
  });
  const [companyIndustries, setCompanyIndustries] = useState<any>({
    include: true,
    value: [],
  });
  const [updateFrequency, setUpdateFrequency] = useState<any>(null);
  const [updateMaxCount, setUpdateMaxCount] = useState<any>(null);
  const isAdminUser = ADMIN_USERS.has(user.getUsername());

  const debugLog = (message?: any, ...optionalParams: any[]): void => {
    if (IS_LOCALHOST) {
      console.log(message, optionalParams);
    }
  };

  const onSave = (e: any) => {
    setIsLoading(true);
    (async function () {
      const author = {
        regions: {
          include: regions.include,
          value: regions.value.map((v: any) => v.value),
        },
        job_seniority: {
          include: jobSeniority.include,
          value: jobSeniority.value.map((v: any) => v.value),
        },
        job_functions: {
          include: jobFunctions.include,
          value: jobFunctions.value.map((v: any) => v.value),
        },
        company_sizes: {
          include: companySizes.include,
          value: companySizes.value.map((v: any) => v.value),
        },
        company_industries: {
          include: companyIndustries.include,
          value: companyIndustries.value.map((v: any) => v.value),
        },
      };

      const accountId = searchParams.get("accountId") || undefined;
      const response = await updateFeed(
        user,
        accountId,
        feedId as string,
        updateFrequency.value,
        updateMaxCount.value,
        author
      );
      if (response.ok) {
        const responseData = await response.json();
        debugLog("Feed updated:", responseData);
        setIsLoading(false);
        navigate({ pathname: "/feeds", search: searchParams.toString() });
      } else {
        setIsLoading(false);
        alert("Unexpected error occurred");
      }
    })();
  };

  useEffect(() => {
    (async function () {
      const accountId = searchParams.get("accountId") || undefined;
      const response = await getFeed(user, accountId, feedId as string);
      if (response.ok) {
        const responseData = await response.json();
        const feed = responseData.feed;
        setResourceType(feed.resource_type);
        setResourceDescription(feed.resource_description);
        setSources(
          feed.sources.map((value: string) =>
            SOURCE_OPTIONS.find((option: Option) => option.value === value)
          )
        );
        if (feed.entity.regions) {
          setRegions({
            include: feed.entity.regions.include,
            value: feed.entity.regions.value.map((value: string) =>
              REGION_OPTIONS.find((option: Option) => option.value === value)
            ),
          });
        }
        if (feed.entity.job_seniority) {
          setJobSeniority({
            include: feed.entity.job_seniority.include,
            value: feed.entity.job_seniority.value.map((value: string) =>
              JOB_SENIORITY_OPTIONS.find(
                (option: Option) => option.value === value
              )
            ),
          });
        }
        if (feed.entity.job_functions) {
          setJobFunctions({
            include: feed.entity.job_functions.include,
            value: feed.entity.job_functions.value.map((value: string) =>
              JOB_FUNCTION_OPTIONS.find(
                (option: Option) => option.value === value
              )
            ),
          });
        }
        if (feed.entity.company_sizes) {
          setCompanySizes({
            include: feed.entity.company_sizes.include,
            value: feed.entity.company_sizes.value.map((value: string) =>
              COMPANY_SIZE_OPTIONS.find(
                (option: Option) => option.value === value
              )
            ),
          });
        }
        if (feed.entity.company_industries) {
          const companyIndustryOptions: Option[] = [];
          feed.entity.company_industries.value.forEach((value: string) => {
            COMPANY_INDUSTRY_OPTIONS.forEach((groupedOption: GroupedOption) => {
              const match = groupedOption.options.find(
                (option: Option) => option.value === value
              );
              if (match) {
                companyIndustryOptions.push(match);
              }
            });
          });
          setCompanyIndustries({
            include: feed.entity.company_industries.include,
            value: companyIndustryOptions,
          });
        }

        if (feed.update_frequency) {
          setUpdateFrequency(
            UPDATE_FREQUENCY_OPTIONS.find(
              (option: Option) => option.value === feed.update_frequency
            )
          );
        }

        const updateMaxCountOption = feed.update_max_count
          ? {
              value: feed.update_max_count,
              label: String(feed.update_max_count),
            }
          : UPDATE_MAX_COUNT_OPTIONS[0];
        setUpdateMaxCount(updateMaxCountOption);
      }
    })();
  }, []);

  return (
    <Home>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          paddingTop: "2%",
          paddingBottom: "10%",
          paddingLeft: "10%",
          paddingRight: "10%",
          width: "100%",
          // TODO: make this responsive to dropdown expand
          minHeight: "1000px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <IonText>
            <h1 style={{ margin: "0px" }}>Edit feed</h1>
          </IonText>
          <div>
            <IonButton
              color="dark"
              fill="clear"
              style={{
                width: "fit-content",
                border: "0.1px solid rgba(0, 0, 0, 0.2)",
              }}
              onClick={() =>
                navigate({
                  pathname: `/feeds/${feedId as string}`,
                  search: searchParams.toString(),
                })
              }
            >
              <div
                style={{
                  fontWeight: "600",
                  textAlign: "center",
                }}
              >
                Cancel
              </div>
            </IonButton>
            <IonButton
              color="dark"
              fill="clear"
              style={{
                width: "fit-content",
                border: "0.1px solid rgba(0, 0, 0, 0.2)",
              }}
              onClick={onSave}
            >
              {isLoading ? (
                <ClipLoader size={"15px"} />
              ) : (
                <div
                  style={{
                    fontWeight: "600",
                    textAlign: "center",
                  }}
                >
                  Save
                </div>
              )}
            </IonButton>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            paddingTop: "15px",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <List disablePadding>
              <ListItem
                style={{
                  paddingLeft: "0px",
                  paddingRight: "0px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <TextField
                      id="main-search-bar"
                      variant="outlined"
                      disabled={true}
                      onChange={(event) =>
                        setResourceDescription(event.target.value)
                      }
                      size="small"
                      value={resourceDescription}
                      placeholder="content marketing solutions"
                      style={{
                        backgroundColor: "white",
                        width: "100%",
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Select
                              variant="standard"
                              style={{
                                borderRight: "0.1px solid rgba(0, 0, 0, .1)",
                                marginRight: "7px",
                              }}
                              autoWidth
                              disableUnderline
                              value={resourceType}
                              onChange={(event) =>
                                setResourceType(event.target.value)
                              }
                              disabled
                            >
                              <MenuItem
                                value={ResourceTypeValue.SOCIAL_MEDIA_POSTING}
                              >
                                {
                                  RESOURCE_TYPE_VALUE_LABEL[
                                    ResourceTypeValue.SOCIAL_MEDIA_POSTING
                                  ]
                                }
                              </MenuItem>
                              <MenuItem value={ResourceTypeValue.JOB_POSTING}>
                                {
                                  RESOURCE_TYPE_VALUE_LABEL[
                                    ResourceTypeValue.JOB_POSTING
                                  ]
                                }
                              </MenuItem>
                            </Select>
                          </InputAdornment>
                        ),
                        endAdornment: query ? (
                          <div
                            onClick={() => setResourceDescription("")}
                            style={{ cursor: "pointer", display: "flex" }}
                          >
                            <IonIcon
                              ios={closeOutline}
                              md={closeSharp}
                              style={{ fontSize: "18px" }}
                            />
                          </div>
                        ) : undefined,
                      }}
                    />
                  </div>
                </div>
              </ListItem>
              <ListItem
                style={{
                  paddingLeft: "0px",
                  paddingRight: "0px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <h4 style={{ margin: "0px", paddingBottom: "15px" }}>
                    Filters
                  </h4>
                  <List style={{ border: "0.1px solid rgba(0, 0, 0, .1)" }}>
                    <ListItem>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        <h5 style={{ margin: "0px", paddingBottom: "15px" }}>
                          Sources
                        </h5>
                        <ReactSelect
                          isMulti
                          isDisabled={true}
                          value={sources}
                          options={SOURCE_OPTIONS}
                          components={{ MultiValueLabel }}
                          onChange={(newValue) => setSources(newValue)}
                        />
                      </div>
                    </ListItem>
                    <ListItem>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        <h5 style={{ margin: "0px", paddingBottom: "15px" }}>
                          Author region
                        </h5>
                        <ReactSelect
                          isMulti
                          value={regions.value}
                          options={REGION_OPTIONS}
                          components={{ Control }}
                          onChange={(newValue) =>
                            setRegions({ ...regions, value: newValue })
                          }
                          // @ts-ignore
                          include={regions.include}
                          setInclude={(newValue: any) =>
                            setRegions({ ...regions, include: newValue })
                          }
                        />
                      </div>
                    </ListItem>
                    <ListItem>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        <h5 style={{ margin: "0px", paddingBottom: "15px" }}>
                          Author job seniority
                        </h5>
                        <ReactSelect
                          isMulti
                          value={jobSeniority.value}
                          options={JOB_SENIORITY_OPTIONS}
                          components={{ Control }}
                          onChange={(newValue) =>
                            setJobSeniority({
                              ...jobSeniority,
                              value: newValue,
                            })
                          }
                          // @ts-ignore
                          include={jobSeniority.include}
                          setInclude={(newValue: any) =>
                            setJobSeniority({
                              ...jobSeniority,
                              include: newValue,
                            })
                          }
                        />
                      </div>
                    </ListItem>
                    <ListItem>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        <h5 style={{ margin: "0px", paddingBottom: "15px" }}>
                          Author job function
                        </h5>
                        <ReactSelect
                          isMulti
                          value={jobFunctions.value}
                          options={JOB_FUNCTION_OPTIONS}
                          components={{ Control }}
                          onChange={(newValue) =>
                            setJobFunctions({
                              ...jobFunctions,
                              value: newValue,
                            })
                          }
                          // @ts-ignore
                          include={jobFunctions.include}
                          setInclude={(newValue: any) =>
                            setJobFunctions({
                              ...jobFunctions,
                              include: newValue,
                            })
                          }
                        />
                      </div>
                    </ListItem>
                    <ListItem>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        <h5 style={{ margin: "0px", paddingBottom: "15px" }}>
                          Author company size
                        </h5>
                        <ReactSelect
                          isMulti
                          value={companySizes.value}
                          options={COMPANY_SIZE_OPTIONS}
                          components={{ Control }}
                          onChange={(newValue) => {
                            setCompanySizes({
                              ...companySizes,
                              value: newValue,
                            });
                          }}
                          // @ts-ignore
                          include={companySizes.include}
                          setInclude={(newValue: any) =>
                            setCompanySizes({
                              ...companySizes,
                              include: newValue,
                            })
                          }
                        />
                      </div>
                    </ListItem>
                    <ListItem>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        <h5 style={{ margin: "0px", paddingBottom: "15px" }}>
                          Author company industry
                        </h5>
                        <ReactSelect<Option, true, GroupedOption>
                          isMulti
                          value={companyIndustries.value}
                          options={COMPANY_INDUSTRY_OPTIONS}
                          formatGroupLabel={formatGroupLabel}
                          components={{ Control }}
                          onChange={(newValue) =>
                            setCompanyIndustries({
                              ...companyIndustries,
                              value: newValue,
                            })
                          }
                          // @ts-ignore
                          include={companyIndustries.include}
                          setInclude={(newValue: any) =>
                            setCompanyIndustries({
                              ...companyIndustries,
                              include: newValue,
                            })
                          }
                        />
                      </div>
                    </ListItem>
                  </List>
                </div>
              </ListItem>
              <ListItem
                style={{
                  paddingLeft: "0px",
                  paddingRight: "0px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <h4 style={{ margin: "0px", paddingBottom: "15px" }}>
                    Update configuration
                  </h4>
                  <List style={{ border: "0.1px solid rgba(0, 0, 0, .1)" }}>
                    <ListItem>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        <h5 style={{ margin: "0px", paddingBottom: "15px" }}>
                          Frequency
                        </h5>
                        <ReactSelect
                          isDisabled={isLoading}
                          value={[updateFrequency]}
                          options={UPDATE_FREQUENCY_OPTIONS}
                          onChange={(newValue) => setUpdateFrequency(newValue)}
                        />
                      </div>
                    </ListItem>
                    <ListItem>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        <h5 style={{ margin: "0px", paddingBottom: "15px" }}>
                          Maximum number of items
                        </h5>
                        <CreatableSelect
                          createOptionPosition="first"
                          isDisabled={isLoading}
                          value={updateMaxCount}
                          options={UPDATE_MAX_COUNT_OPTIONS}
                          onChange={(newValue) => {
                            if (newValue.value === "0") {
                              alert("Maximum count must be greater than 0");
                            } else {
                              setUpdateMaxCount(newValue);
                            }
                          }}
                        />
                      </div>
                    </ListItem>
                  </List>
                </div>
              </ListItem>
            </List>
          </div>
        </div>
      </div>
    </Home>
  );
};

export default FeedEdit;
