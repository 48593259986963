import React from "react";
import moment from "moment";

import { useNavigate } from "react-router";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useAuthenticator } from "@aws-amplify/ui-react";

import { StyledTableCell, StyledTableRow } from "./CustomTable";
import {
  Execution,
  capitalizeFirstLetter,
  listExecutions,
} from "../api/Nunchi";

import "@aws-amplify/ui-react/styles.css";

const Executions = ({ taskId }: { taskId: string | undefined }) => {
  const navigate = useNavigate();
  const { user } = useAuthenticator((context) => [context.user]);
  const [executions, setExecutions] = React.useState<Execution[]>([]);

  React.useEffect(() => {
    (async function () {
      const response = await listExecutions(user, undefined, taskId);
      if (response.ok) {
        const responseData = await response.json();
        setExecutions(
          responseData.executions.sort((a: Execution, b: Execution) =>
            b.start_time.localeCompare(a.start_time)
          )
        );
      } else {
        alert("Unexpected error occurred");
      }
    })();
  }, []);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>Name</StyledTableCell>
            <StyledTableCell style={{ width: 200 }} align="right">
              Status
            </StyledTableCell>
            <StyledTableCell style={{ width: 200 }} align="right">
              Start Date
            </StyledTableCell>
            <StyledTableCell style={{ width: 200 }} align="right">
              End Date
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {executions.map((item: Execution) => (
            <StyledTableRow
              key={item.execution_id}
              sx={{ cursor: "pointer" }}
              onClick={() =>
                navigate({ pathname: `/v2/executions/${item.execution_id}` })
              }
            >
              <StyledTableCell>{item.execution_id}</StyledTableCell>
              <StyledTableCell style={{ width: 200 }} align="right">
                {capitalizeFirstLetter(item.status)}
              </StyledTableCell>
              <StyledTableCell style={{ width: 200 }} align="right">
                {moment(item.start_time).local().format("l LT")}
              </StyledTableCell>
              <StyledTableCell style={{ width: 200 }} align="right">
                {item.end_time && moment(item.end_time).local().format("l LT")}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Executions;
