import React from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import Drawer from "../components/Drawer";
import Execution from "../components/Execution";

import { useNavigate, useParams } from "react-router";
import {
  AppBar,
  Box,
  Breadcrumbs,
  CssBaseline,
  Link,
  Toolbar,
  Typography,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { useAuthenticator } from "@aws-amplify/ui-react";

import { DRAWER_WIDTH } from "../constants";
import { darkTheme } from "../components/Theme";
import { SERVICE_ACCOUNT_ID, Task, getTask, getExecution } from "../api/Nunchi";

import "@aws-amplify/ui-react/styles.css";

const ExecutionPage: React.FC = () => {
  const navigate = useNavigate();
  const { executionId } = useParams();
  const { user } = useAuthenticator((context) => [context.user]);
  const [breadcrumbs, setBreadcrumbs] = React.useState<any>([
    <Link
      underline="hover"
      key="1"
      color="inherit"
      sx={{ cursor: "pointer" }}
      onClick={() => navigate("/v2/tasks")}
    >
      Tasks
    </Link>,
  ]);

  React.useEffect(() => {
    (async function () {
      if (executionId) {
        const getExecutionResponse = await getExecution(
          user,
          undefined,
          executionId
        );
        if (getExecutionResponse.ok) {
          const getExecutionResponseData = await getExecutionResponse.json();
          const execution = getExecutionResponseData.execution;

          var newTask: any = null;
          const response = await getTask(
            user,
            SERVICE_ACCOUNT_ID,
            execution.task_id
          );
          if (response.ok) {
            const responseData = await response.json();
            newTask = responseData.task;
          }

          if (!newTask) {
            const response = await getTask(user, undefined, execution.task_id);
            if (response.ok) {
              const responseData = await response.json();
              newTask = responseData.task;
            }
          }

          if (newTask) {
            const newBreadcrumbs = [
              ...breadcrumbs,
              <Link
                underline="hover"
                key="2"
                color="inherit"
                sx={{ cursor: "pointer" }}
                onClick={() => navigate(`/v2/tasks/${newTask.task_id}`)}
              >
                {newTask.name}
              </Link>,
              <Typography key="3" color="text.primary">
                {executionId}
              </Typography>,
            ];
            setBreadcrumbs(newBreadcrumbs);
          }
        }
      }
    })();
  }, []);

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Box minHeight={"100%"} overflow={"auto"} sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: `calc(100% - ${DRAWER_WIDTH}px)`,
            ml: `${DRAWER_WIDTH}px`,
          }}
        >
          <Toolbar>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              {breadcrumbs}
            </Breadcrumbs>
          </Toolbar>
        </AppBar>
        <Drawer />
        <Box
          component="main"
          sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
        >
          <Toolbar />
          <Execution />
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default ExecutionPage;
