import React from "react";
import NotFound from "./NotFound";

import {
  useAuthenticator,
  Authenticator,
  CheckboxField,
} from "@aws-amplify/ui-react";
import { IonContent, IonPage } from "@ionic/react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { Navigate, useSearchParams } from "react-router-dom";
import { AUTH_TOKENS } from "../constants";

import "@aws-amplify/ui-react/styles.css";

const Auth: React.FC = () => {
  const location = useLocation();
  const { route } = useAuthenticator((context) => [context.route]);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (route === "authenticated") {
      navigate(location.state ? location.state.from : "/home");
    }
  }, [route, navigate]);

  return (
    <IonPage>
      <IonContent fullscreen>
        <div
          style={{ height: "100%", display: "flex", justifyContent: "center" }}
        >
          {AUTH_TOKENS.has(searchParams.get("token") || "") ? (
            <Authenticator
              components={{
                SignUp: {
                  FormFields() {
                    const { validationErrors } = useAuthenticator();

                    return (
                      <>
                        <Authenticator.SignUp.FormFields />
                        <CheckboxField
                          errorMessage={
                            validationErrors.acknowledgement as string
                          }
                          hasError={!!validationErrors.acknowledgement}
                          name="acknowledgement"
                          value="yes"
                          label={
                            <span>
                              I agree with the{" "}
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="/terms"
                              >
                                {" "}
                                Terms & Conditions
                              </a>
                            </span>
                          }
                        />
                      </>
                    );
                  },
                },
              }}
              services={{
                async validateCustomSignUp(formData) {
                  if (!formData.acknowledgement) {
                    return {
                      acknowledgement:
                        "You must agree to the Terms & Conditions",
                    };
                  }
                },
              }}
            ></Authenticator>
          ) : (
            <Navigate
              to={`/auth?token=${AUTH_TOKENS.values().next().value}`}
              replace={true}
            />
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Auth;
