import React, { useEffect, useRef, useState } from "react";
import CreatableSelect from "react-select/creatable";
import TextareaAutosize from "react-textarea-autosize";

import { IonIcon } from "@ionic/react";
import { bulbOutline } from "ionicons/icons";
import { Lead, PersonExternal, complete, getUser } from "../api/Nunchi";
import { SyncLoader } from "react-spinners";
import { SingleValueProps, components } from "react-select";
import { useAuthenticator } from "@aws-amplify/ui-react";

const UTF8_DECODER = new TextDecoder();
const DEFAULT_ROW_HEIGHT = 500;

interface Option {
  readonly label: string;
  readonly value: string;
}

const options: Option[] = [
  {
    label: "Write personalized message",
    value: "Write personalized message",
  },
  // {
  //   label: "Save as draft",
  //   value: "Save as draft",
  // },
];

const ActionCard = ({
  person,
  setRowHeight,
}: {
  person: PersonExternal;
  setRowHeight: any;
}) => {
  const componentRef = useRef<null | HTMLDivElement>(null);
  const [conversation, setConversation] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [companyWebsiteUrl, setCompanyWebsiteUrl] = useState("");
  const [companyProductDescription, setCompanyProductDescription] =
    useState("");
  const { user } = useAuthenticator((context) => [context.user]);

  useEffect(() => {
    if (user) {
      (async function () {
        const response = await getUser(user);
        if (response.ok) {
          const responseData = await response.json();
          if (responseData.user.company_name) {
            setCompanyName(responseData.user.company_name);
          }
          if (responseData.user.company_website_url) {
            setCompanyWebsiteUrl(responseData.user.company_website_url);
          }
          if (responseData.user.company_product_description) {
            setCompanyProductDescription(
              responseData.user.company_product_description
            );
          }
        }
      })();
    }
  }, [user]);

  const SingleValue = ({ children, ...props }: SingleValueProps<Option>) => (
    <components.SingleValue {...props}>
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        {isLoading && (
          <div style={{ paddingBottom: "5px", paddingRight: "5px" }}>
            <SyncLoader color="hsl(0, 0%, 40%)" size="4px" />
          </div>
        )}
        {children}
      </div>
    </components.SingleValue>
  );

  const streamCompletionResponse = (
    input: any,
    val: string,
    buffer: string
  ) => {
    const newConversation = [
      ...conversation,
      { input: input.value, output: buffer },
    ];
    setConversation(newConversation);

    const componentHeight = componentRef.current?.clientHeight;
    if (componentHeight) {
      setRowHeight(Math.max(DEFAULT_ROW_HEIGHT, componentHeight + 150));
    }
  };

  const systemPrompt = `You are a sales expert and assistant. You want to convince the person to buy services from the company named ${companyName} (${companyProductDescription}).`;
  // const messageTemplate = `Hi [Lead First Name],\n\nThis is ${user.attributes?.name}, co-founder of Nunchi. I came across [Lead Activity]. We're building an AI tool that automates lead prospecting on LinkedIn. Would you be interested in doing a quick call to learn more?\n\nBest,\n${user.attributes?.name}`;
  const handleCreate = (input: any) => {
    setIsLoading(true);

    (async function () {
      const messages: any[] = [{ role: "system", content: systemPrompt }];
      conversation.forEach((x: any) => {
        messages.push({ role: "user", content: x.input });
        messages.push({ role: "assistant", content: x.output });
      });
      messages.push({ role: "user", content: input.value });

      const context = JSON.stringify({
        Person: person,
        // MessageTemplate: messageTemplate,
      });
      const response = await complete(user, messages, context);
      if (response.ok) {
        let buffer = "";
        try {
          // Async iterable is only supported in Firefox at the moment
          for await (const chunk of response.body) {
            const val = UTF8_DECODER.decode(chunk);
            buffer += val;
            streamCompletionResponse(input, val, buffer);
          }
        } catch (error) {
          // Fallback to traditional stream reader approach
          const reader = response.body.getReader();
          while (true) {
            const { done, value } = await reader.read();
            if (done) break;
            const val = UTF8_DECODER.decode(value);
            buffer += val;
            streamCompletionResponse(input, val, buffer);
          }
        }
      }
      setIsLoading(false);
    })();
  };

  useEffect(() => {
    const componentHeight = componentRef.current?.clientHeight;
    if (componentHeight) {
      setRowHeight(DEFAULT_ROW_HEIGHT);
    }
  }, []);

  return (
    <div
      ref={componentRef}
      onKeyDown={(e) => {
        e.stopPropagation();
      }}
    >
      <CreatableSelect
        autoFocus
        isClearable
        formatCreateLabel={(inputValue: string) => inputValue}
        options={options}
        onChange={(input) => {
          if (input) {
            handleCreate(input);
          }
        }}
        placeholder={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <IonIcon
              ios={bulbOutline}
              md={bulbOutline}
              style={{ fontSize: "18px" }}
            />
          </div>
        }
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          SingleValue,
        }}
      />
      <div
        style={{
          overflowY: "scroll",
          marginTop: "10px",
          border:
            conversation.length > 0
              ? "0.01px solid rgba(0, 0, 0, .1)"
              : undefined,
        }}
      >
        {conversation &&
          conversation.map((x, i) => (
            <div key={i}>
              {x.input && (
                <div
                  style={{
                    whiteSpace: "pre-wrap",
                    padding: "10px",
                    backgroundColor: "hsl(0deg 0% 96%)",
                  }}
                >
                  {x.input}
                </div>
              )}
              {x.input && (
                <TextareaAutosize
                  value={x.output}
                  onChange={(e) => {
                    const newConversation = [...conversation];
                    newConversation[i].output = e.target.value;
                    setConversation(newConversation);
                  }}
                  onFocus={(e) => (e.currentTarget.style.cursor = "default")}
                  onBlur={(e) => (e.currentTarget.style.cursor = "pointer")}
                  style={{
                    display: "flex",
                    width: "100%",
                    margin: "0px",
                    padding: "10px",
                    resize: "none",
                    outline: "0.01px solid rgba(0, 0, 0, .1)",
                    border: "0px",
                    cursor: "pointer",
                  }}
                />
              )}
            </div>
          ))}
        {/* <div ref={conversationEndRef} /> */}
      </div>
    </div>
  );
};

export default ActionCard;
