import React from "react";

import { IonContent, IonPage } from "@ionic/react";

const PrivacyPolicy: React.FC = () => {
  return (
    <IonPage>
      <IonContent fullscreen>
        <object
          width="100%"
          height="100%"
          data="/assets/privacy-policy.pdf"
          type="application/pdf"
        >
          Privacy Policy
        </object>
      </IonContent>
    </IonPage>
  );
};

export default PrivacyPolicy;
