import React from "react";

import { IonContent, IonPage } from "@ionic/react";

const Unsubcribe: React.FC = () => {
  return (
    <IonPage>
      <IonContent fullscreen>
        <div style={{ textAlign: "center" }}>
          <p>Unsubscribed!</p>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Unsubcribe;
