import React, { KeyboardEventHandler, useEffect, useState } from "react";
import Home from "../components/Home";
import PersonList from "../components/PersonList";

import { Auth } from "aws-amplify";
import { useAuthenticator } from "@aws-amplify/ui-react";
import {
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonList,
  IonPopover,
  IonText,
} from "@ionic/react";
import {
  chevronBackOutline,
  chevronBackSharp,
  closeOutline,
  closeSharp,
  ellipsisHorizontalOutline,
  ellipsisHorizontalSharp,
  searchOutline,
  searchSharp,
  refreshOutline,
  refreshSharp,
  optionsOutline,
  optionsSharp,
} from "ionicons/icons";
import {
  InputAdornment,
  MenuItem,
  Popover,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { ClipLoader } from "react-spinners";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { Option } from "../constants";
import {
  SERVICE_ACCOUNT_ID,
  PUBLIC_FEED_IDS,
  Activity,
  ActivityTimeValue,
  PersonExternal,
  createFeedSubscription,
  deleteFeedSubscription,
  listFeedItems,
  listFeedSubscriptions,
  putUserAction,
  UserActionEntityType,
  UserActionType,
  getFeed,
  INTENT_VALUE_LABEL,
  capitalizeFirstLetter,
} from "../api/Nunchi";
import {
  usePopupState,
  bindTrigger,
  bindPopover,
} from "material-ui-popup-state/hooks";

import "@aws-amplify/ui-react/styles.css";
import "./Feed.css";

const IS_LOCALHOST = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

const Feed: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const accountIdParam = searchParams.get("accountId") || undefined;
  const defaultTimeValue = Object.values(ActivityTimeValue).includes(
    searchParams.get("datePosted") as ActivityTimeValue
  )
    ? (searchParams.get("datePosted") as ActivityTimeValue)
    : ActivityTimeValue.ANY_TIME;

  const navigate = useNavigate();
  const [persons, setPersons] = useState<Map<string, PersonExternal>>(
    new Map()
  );
  const [activityIds, setActivityIds] = useState<string[]>([]);
  const [activities, setActivities] = useState<{ [key: string]: any }>({});
  const [entityToActivitiesMap, setEntityToActivitiesMap] = useState<
    Map<string, Activity[]>
  >(new Map());
  const [activityToEntityMap, setActivityToEntityMap] = useState<
    Map<string, string>
  >(new Map());
  const [isLoading, setIsLoading] = useState(false);
  const [isActivityLoading, setIsActivityLoading] = useState(false);
  const [isSubscriptionChanging, setIsSubscriptionChanging] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [feedSubscriptionId, setFeedSubscriptionId] = useState(null);
  const [nextToken, setNextToken] = useState(undefined);
  const [timeValue, setTimeValue] = useState<string>(defaultTimeValue);
  const [jobTitleOptions, setJobTitleOptions] = useState<Option[]>([]);
  const popupState = usePopupState({
    variant: "popover",
    popupId: "demoPopover",
  });
  const [title, setTitle] = useState("");
  console.log(title);

  const { user } = useAuthenticator((context) => [context.user]);
  const { feedId } = useParams();

  const debugLog = (message?: any, ...optionalParams: any[]): void => {
    if (IS_LOCALHOST) {
      console.log(message, optionalParams);
    }
  };

  const exportToCsv = () => {
    const head = [
      [
        "entity_url",
        "name",
        "tagline",
        "activity_url",
        "activity_body",
        "parent_activity_body",
        "activity_posted_date",
      ],
    ];
    const body = [];
    for (let person of persons.values()) {
      let activities = entityToActivitiesMap.get(person.person_id);
      const entityUrl = person.person_id.startsWith("https://")
        ? person.person_id
        : "";
      if (!activities) {
        let item = [entityUrl, person.name, person.job_title, "", "", "", ""];
        body.push(item);
      } else {
        for (let activity of activities) {
          let item = [
            entityUrl,
            person.name,
            person.job_title,
            activity.url,
            JSON.stringify(activity.article_body),
            JSON.stringify(activity.parent_article_body),
            activity.activity_time,
          ];
          body.push(item);
        }
      }
    }
    const content = [...head, ...body]
      .map((cells) => cells.map(serialiseCellValue).join(","))
      .join("\n");

    downloadFile(
      "export.csv",
      new Blob([content], { type: "text/csv;charset=utf-8;" })
    );
    recordUserAction(
      UserActionType.DOWNLOAD_CSV,
      feedId!,
      UserActionEntityType.FEED,
      ""
    );
  };

  function serialiseCellValue(value: unknown) {
    if (typeof value === "string") {
      const formattedValue = value.replace(/"/g, '""');
      return formattedValue.includes(",")
        ? `"${formattedValue}"`
        : formattedValue;
    }
    return value;
  }

  function downloadFile(fileName: string, data: Blob) {
    const downloadLink = document.createElement("a");
    downloadLink.download = fileName;
    const url = URL.createObjectURL(data);
    downloadLink.href = url;
    downloadLink.click();
    URL.revokeObjectURL(url);
  }

  const recordUserAction = (
    actionType: string,
    entityId: string,
    entityType: string,
    queryString: string
  ) => {
    (async function () {
      const actionId = uuidv4();
      await putUserAction(
        user,
        actionId,
        actionType,
        entityId,
        entityType,
        "",
        queryString
      );
    })();
  };

  const callListFeedItems = (
    startTime: string | undefined = undefined,
    limit: number | undefined = undefined,
    nextToken: string | undefined = undefined
  ) => {
    (async function () {
      const accountId = PUBLIC_FEED_IDS.includes(feedId as string)
        ? SERVICE_ACCOUNT_ID
        : accountIdParam
        ? accountIdParam
        : undefined;
      const response = await listFeedItems(
        user,
        accountId,
        feedId as string,
        startTime,
        limit,
        nextToken
      );
      if (response.ok) {
        const responseData = await response.json();
        const items = responseData.feed_items;
        const activityIds = items.map((x: any) => x.activity_id);
        const activities: { [key: string]: any } = {};
        items.forEach((x: any) => (activities[x.activity_id] = x));
        setActivityIds(activityIds);
        setActivities(activities);
        setNextToken(responseData.next_token);
      }
      setIsLoading(false);
    })();
  };

  const handleKeyDownSearch: KeyboardEventHandler = (event) => {
    if (!searchValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        setSearchValue(searchValue);
    }
  };

  const handleTimeChange = (event: SelectChangeEvent) => {
    const selectedTimeValue = event.target.value;
    if (selectedTimeValue === timeValue) {
      return;
    }
    debugLog("Time changed: ", selectedTimeValue);
    setTimeValue(selectedTimeValue);

    const updatedSearchParams: any = { datePosted: selectedTimeValue };
    if (accountIdParam) {
      updatedSearchParams.accountId = accountIdParam;
    }

    setIsLoading(true);
    setPersons(new Map());
    setActivityIds([]);
    setActivityToEntityMap(new Map());
    setEntityToActivitiesMap(new Map());
    setSearchParams(updatedSearchParams);
    callListFeedItems(selectedTimeValue, undefined, undefined);
    popupState.setOpen(false);
  };

  const handleSubscriptionChange = () => {
    setIsSubscriptionChanging(true);
    (async function () {
      const accountId = searchParams.get("accountId") || undefined;
      if (!feedSubscriptionId) {
        const response = await createFeedSubscription(
          user,
          accountId,
          feedId as string
        );
        if (response.ok) {
          const responseData = await response.json();
          setFeedSubscriptionId(responseData.feed_subscription_id);
        } else {
          alert("Unexpected error occurred");
        }
      } else {
        const response = await deleteFeedSubscription(
          user,
          accountId,
          feedSubscriptionId
        );
        if (response.ok) {
          setFeedSubscriptionId(null);
        } else {
          alert("Unexpected error occurred");
        }
      }
      setIsSubscriptionChanging(false);
    })();
  };

  const renderBottomText = () => {
    let message = "";
    if (!isLoading && !isActivityLoading) {
      if (persons.size === 0) {
        message = "No results";
      }
    }
    return <IonText>{message}</IonText>;
  };

  useEffect(() => {
    // Update activities state with activity IDs
    const fetchData = async () => {
      setIsActivityLoading(true);
      try {
        let toBeProcessed = [];
        for (let activityId of activityIds) {
          if (!activityToEntityMap.has(activityId)) {
            toBeProcessed.push(activityId);
          }
        }

        const updatedDataPromises = toBeProcessed.map(async (activityId) => {
          if (activityId && activityId !== "None") {
            return activities[activityId];
          }
        });

        // Wait for all async calls to finish using Promise.all
        const updatedDataArray = await Promise.all(updatedDataPromises);
        // Filter out undefined and company (non-person) profiles
        const filteredArray = updatedDataArray.map((x: any) => ({
          person_id: x.entity_id,
          name: x.entity_name,
          location: "",
          job_title: x.entity_tagline,
          company_name: "",
          company: {
            company_id: "",
            name: "",
            location: "",
            industry: "",
            overview: "",
            num_employees: "",
            website_url: "",
          },
          activities: [x],
        }));

        if (filteredArray.length === 0) {
          setIsActivityLoading(false);
          return;
        }

        const newActivityMap: Map<string, string> = new Map(
          activityToEntityMap
        );
        const newPersons: Map<string, PersonExternal> = new Map(persons);
        const newEntityToActivitiesMap: Map<string, Activity[]> = new Map(
          entityToActivitiesMap
        );
        for (let person of filteredArray) {
          if (person) {
            newActivityMap.set(
              person.activities[0].activity_id,
              person.person_id
            );
            if (
              person.person_id &&
              newEntityToActivitiesMap.has(person.person_id)
            ) {
              const updatedActivities = newEntityToActivitiesMap
                .get(person.person_id)!
                .concat(person.activities);
              newEntityToActivitiesMap.set(person.person_id, updatedActivities);
            } else {
              newEntityToActivitiesMap.set(person.person_id, person.activities);
              // Only set person if ther person does not exist in the map
              newPersons.set(person.person_id, person);
            }
          }
        }

        // Set the updated array state with the fetched data
        setActivityToEntityMap(newActivityMap);
        setPersons(newPersons);
        setEntityToActivitiesMap(newEntityToActivitiesMap);
        setIsActivityLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsActivityLoading(false);
      }
    };

    fetchData();
  }, [activityIds]);

  useEffect(() => {
    (async function () {
      try {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        const currentSession = await Auth.currentSession();
        cognitoUser.refreshSession(currentSession.getRefreshToken(), () => {
          debugLog("Successfully refreshed the token");
        });
      } catch (e) {
        debugLog("Unable to refresh Token", e);
      }
    })();

    (async function () {
      const accountId = searchParams.get("accountId") || undefined;
      const response = await listFeedSubscriptions(
        user,
        accountId,
        feedId as string,
        undefined
      );
      if (response.ok) {
        const responseData = await response.json();
        if (responseData.feed_subscriptions.length > 0) {
          setFeedSubscriptionId(
            responseData.feed_subscriptions[0].feed_subscription_id
          );
        }
      }
    })();

    (async function () {
      const accountId = PUBLIC_FEED_IDS.includes(feedId as string)
        ? SERVICE_ACCOUNT_ID
        : accountIdParam
        ? accountIdParam
        : undefined;
      const response = await getFeed(user, accountId, feedId as string);
      if (response.ok) {
        const responseData = await response.json();
        const feed = responseData.feed;
        setTitle(capitalizeFirstLetter(feed.resource_description, false));
      }
    })();

    const datePostedParam = searchParams.get("datePosted") || undefined;
    const updatedSearchParams: any = {};
    if (
      Object.values(ActivityTimeValue).includes(
        datePostedParam as ActivityTimeValue
      )
    ) {
      updatedSearchParams.datePosted = datePostedParam;
    }
    if (accountIdParam) {
      updatedSearchParams.accountId = accountIdParam;
    }
    setSearchParams(updatedSearchParams);
    setIsLoading(true);
    callListFeedItems(datePostedParam, undefined, undefined);
  }, []);

  useEffect(() => {
    setJobTitleOptions([]);
  }, [persons]);

  return (
    <Home>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          paddingTop: "2%",
          paddingBottom: "2%",
          paddingLeft: "10%",
          paddingRight: "10%",
          width: "100%",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <IonButton
                color="dark"
                fill="clear"
                style={{
                  margin: "0px",
                  "--padding-start": "0.2em",
                  "--padding-end": "0.2em",
                }}
                onClick={() =>
                  navigate({
                    pathname: "/feeds",
                    search: searchParams.toString(),
                  })
                }
              >
                <IonIcon
                  ios={chevronBackOutline}
                  md={chevronBackSharp}
                  style={{ fontSize: "20px" }}
                />
              </IonButton>
              <div>
                {!PUBLIC_FEED_IDS.includes(feedId as string) && (
                  <IonButton
                    color="dark"
                    fill="clear"
                    style={{
                      width: "fit-content",
                      border: "0.1px solid rgba(0, 0, 0, 0.2)",
                    }}
                    onClick={() =>
                      navigate({
                        pathname: "edit",
                        search: searchParams.toString(),
                      })
                    }
                  >
                    <div
                      style={{
                        fontWeight: "600",
                        textAlign: "center",
                      }}
                    >
                      Edit
                    </div>
                  </IonButton>
                )}
                <IonButton
                  color="dark"
                  fill="clear"
                  style={{
                    width: "fit-content",
                    border: "0.1px solid rgba(0, 0, 0, 0.2)",
                  }}
                  onClick={handleSubscriptionChange}
                >
                  {isSubscriptionChanging ? (
                    <ClipLoader size={"15px"} />
                  ) : (
                    <div
                      style={{
                        fontWeight: "600",
                        textAlign: "center",
                      }}
                    >
                      {feedSubscriptionId !== null
                        ? "Unsubscribe"
                        : "Subscribe"}
                    </div>
                  )}
                </IonButton>
                <IonButton
                  color="dark"
                  fill="clear"
                  style={{
                    width: "fit-content",
                    border: "0.1px solid rgba(0, 0, 0, 0.2)",
                  }}
                  onClick={exportToCsv}
                >
                  <div
                    style={{
                      fontWeight: "600",
                      textAlign: "center",
                    }}
                  >
                    Download
                  </div>
                </IonButton>
              </div>
            </div>
            <div>
              <h3
                style={{
                  marginTop: "5px",
                  marginBottom: "5px",
                }}
              >
                {title}
              </h3>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                height: "50px",
              }}
            >
              <div style={{ width: "100%" }}>
                <TextField
                  id="main-search-bar"
                  variant="outlined"
                  onChange={(event) => {
                    setSearchValue(event.target.value);
                  }}
                  onKeyDown={handleKeyDownSearch}
                  size="small"
                  value={searchValue}
                  placeholder="Search"
                  disabled={isLoading || isActivityLoading}
                  style={{
                    width: "100%",
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {isLoading || isActivityLoading ? (
                          <ClipLoader size={"15px"} />
                        ) : (
                          <IonIcon
                            ios={searchOutline}
                            md={searchSharp}
                            style={{ fontSize: "18px" }}
                          />
                        )}
                      </InputAdornment>
                    ),
                    endAdornment:
                      searchValue && !isLoading && !isActivityLoading ? (
                        <div
                          onClick={() => setSearchValue("")}
                          style={{ cursor: "pointer", display: "flex" }}
                        >
                          <IonIcon
                            ios={closeOutline}
                            md={closeSharp}
                            style={{ fontSize: "18px" }}
                          />
                        </div>
                      ) : undefined,
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: "5px",
                }}
              >
                <IonButton
                  id="filter-trigger"
                  color="dark"
                  fill="clear"
                  style={{
                    margin: "0px",
                    "--padding-start": "0.8em",
                    "--padding-end": "0.8em",
                  }}
                  {...bindTrigger(popupState)}
                >
                  <IonIcon
                    ios={optionsOutline}
                    md={optionsSharp}
                    style={{ fontSize: "20px" }}
                  />
                </IonButton>
                <Popover
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  {...bindPopover(popupState)}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      padding: "15px",
                      alignItems: "center",
                      width: "300px",
                    }}
                  >
                    <IonText
                      style={{
                        flex: "1",
                        paddingRight: "30px",
                      }}
                    >
                      Date posted
                    </IonText>
                    <Select
                      inputProps={{ "aria-label": "Without label" }}
                      variant="standard"
                      style={{
                        width: "100%",
                        flex: "1",
                      }}
                      onChange={handleTimeChange}
                      value={timeValue}
                      disabled={isLoading || isActivityLoading}
                    >
                      <MenuItem value={ActivityTimeValue.ANY_TIME}>
                        Any Time
                      </MenuItem>
                      <MenuItem value={ActivityTimeValue.PAST_MONTH}>
                        Past Month
                      </MenuItem>
                      <MenuItem value={ActivityTimeValue.PAST_WEEK}>
                        Past Week
                      </MenuItem>
                      <MenuItem value={ActivityTimeValue.PAST_DAY}>
                        Last 24 Hours
                      </MenuItem>
                    </Select>
                  </div>
                </Popover>
              </div>
            </div>
            <div>
              <PersonList
                persons={persons}
                entityToActivitiesMap={entityToActivitiesMap}
                filters={{ searchValue }}
              />
              <div
                style={{
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                {renderBottomText()}
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {nextToken ? (
                  !isLoading && !isActivityLoading ? (
                    <IonButton
                      color="dark"
                      fill="clear"
                      style={{
                        width: "fit-content",
                      }}
                      onClick={() =>
                        callListFeedItems(timeValue, undefined, nextToken)
                      }
                    >
                      <IonIcon
                        ios={refreshOutline}
                        md={refreshSharp}
                        style={{ fontSize: "20px", paddingRight: "10px" }}
                      />
                      <IonText color="black">Load more</IonText>
                    </IonButton>
                  ) : (
                    <IonButton
                      color="dark"
                      fill="clear"
                      style={{
                        width: "fit-content",
                      }}
                      disabled={true}
                    >
                      <div
                        style={{
                          paddingRight: "10px",
                        }}
                      >
                        <ClipLoader size={"20px"} />
                      </div>
                      <IonText>Loading more...</IonText>
                    </IonButton>
                  )
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Home>
  );
};

export default Feed;
