import React from "react";
import spec from "../nunchi-api-v1-openapi.json";

import { IonPage, IonContent } from "@ionic/react";
import { RedocStandalone } from "redoc";

import "@aws-amplify/ui-react/styles.css";

const ApiReference: React.FC = () => {
  return (
    <IonPage id="main-content">
      <IonContent className="ion-padding">
        <RedocStandalone
          spec={spec}
          options={{
            nativeScrollbars: true,
            theme: { colors: { primary: { main: "#3880ff" } } },
          }}
        />
      </IonContent>
    </IonPage>
  );
};

export default ApiReference;
