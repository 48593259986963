import React, { useEffect, useRef } from "react";
import moment from "moment";

import {
  returnDownForwardOutline,
  returnDownForwardSharp,
  linkOutline,
  linkSharp,
} from "ionicons/icons";
import { IonIcon } from "@ionic/react";
import {
  Activity,
  PersonExternal,
  UserActionEntityType,
  UserActionType,
  putUserAction,
} from "../api/Nunchi";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { v4 as uuidv4 } from "uuid";
import Logo from "./Logo";

const Post = ({
  activity,
  person,
}: {
  activity: Activity;
  person: PersonExternal;
}) => {
  const { user } = useAuthenticator((context) => [context.user]);

  const recordUserAction = (
    entityId: string,
    entityType: string,
    activityId: string
  ) => {
    (async function () {
      const actionId = uuidv4();
      await putUserAction(
        user,
        actionId,
        UserActionType.CLICK,
        entityId,
        entityType,
        activityId,
        ""
      );
    })();
  };

  const articleBody =
    (activity.activity_type === "SHARE" ||
      activity.activity_type === "REPOST") &&
    !activity.article_body
      ? activity.parent_article_body
      : activity.article_body;
  return (
    <div>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={activity.url}
        onClick={() => {
          recordUserAction(
            activity.entity_id,
            UserActionEntityType.ACTIVITY,
            activity.activity_id
          );
        }}
      >
        <Logo url={activity.url} />
      </a>
      <span style={{ paddingLeft: "7px", fontWeight: "bold" }}>
        {person.name}
      </span>
      <span style={{ paddingLeft: "4px" }}>{articleBody}</span>
      <span style={{ paddingLeft: "7px" }}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={activity.url}
          style={{ verticalAlign: "middle", paddingTop: "4px", color: "black" }}
          onClick={() => {
            recordUserAction(
              activity.entity_id,
              UserActionEntityType.ACTIVITY,
              activity.activity_id
            );
          }}
        >
          <IonIcon ios={linkOutline} md={linkSharp} size="small" />
        </a>
      </span>
      <span style={{ paddingLeft: "7px", opacity: "0.5" }}>
        {moment(activity.activity_time).local().fromNow()}
      </span>
    </div>
  );
};

const Comment = ({
  activity,
  person,
}: {
  activity: Activity;
  person: PersonExternal;
}) => {
  const { user } = useAuthenticator((context) => [context.user]);

  const recordUserAction = (
    entityId: string,
    entityType: string,
    activityId: string
  ) => {
    (async function () {
      const actionId = uuidv4();
      await putUserAction(
        user,
        actionId,
        UserActionType.CLICK,
        entityId,
        entityType,
        activityId,
        ""
      );
    })();
  };

  return (
    <>
      <div>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={activity.url}
          onClick={() => {
            recordUserAction(
              activity.entity_id,
              UserActionEntityType.ACTIVITY,
              activity.activity_id
            );
          }}
        >
          <Logo url={activity.url} />
        </a>
        <span style={{ paddingLeft: "4px" }}>
          {activity.parent_article_body}
        </span>
        <span style={{ paddingLeft: "7px" }}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={activity.url}
            style={{
              verticalAlign: "middle",
              paddingTop: "4px",
              color: "black",
            }}
            onClick={() => {
              recordUserAction(
                activity.entity_id,
                UserActionEntityType.ACTIVITY,
                activity.activity_id
              );
            }}
          >
            <IonIcon ios={linkOutline} md={linkSharp} size="small" />
          </a>
        </span>
      </div>
      <div style={{ padding: "5px" }}>
        <IonIcon
          ios={returnDownForwardOutline}
          md={returnDownForwardSharp}
          size="small"
        />
        <span style={{ paddingLeft: "7px", fontWeight: "bold" }}>
          {person.name}
        </span>
        <span style={{ paddingLeft: "4px" }}>{activity.article_body}</span>
        <span style={{ paddingLeft: "4px", opacity: "0.5" }}>
          {moment(activity.activity_time).local().fromNow()}
        </span>
      </div>
    </>
  );
};

const ActivityCard = ({
  person,
  setRowHeight,
  activities,
}: {
  person: PersonExternal;
  setRowHeight: any;
  activities: Activity[];
}) => {
  const componentRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    const componentHeight = componentRef.current?.clientHeight;
    if (componentHeight) {
      setRowHeight(componentHeight + 120);
    }
  }, []);

  return (
    <div
      ref={componentRef}
      onKeyDown={(e) => {
        e.stopPropagation();
      }}
    >
      {activities.map((activity: Activity, i: number) => (
        <div
          key={i}
          style={{
            display: "flex",
            flexDirection: "column",
            width: "90%",
            whiteSpace: "normal",
            borderBottom: "0.01px solid rgba(0, 0, 0, .1)",
            paddingBottom: "5px",
            marginBottom: "20px",
          }}
        >
          {activity.activity_type === "POST" ||
          ((activity.activity_type === "SHARE" ||
            activity.activity_type === "REPOST") &&
            !activity.article_body) ? (
            <Post activity={activity} person={person} />
          ) : (
            <Comment activity={activity} person={person} />
          )}
        </div>
      ))}
    </div>
  );
};

export default ActivityCard;
