import React from "react";

const LINKEDIN_BASE_URL = "https://www.linkedin.com";
const FACEBOOK_BASE_URL = "https://www.facebook.com";

const Logo = ({ url }: { url: string }) => {
  if (url.startsWith(LINKEDIN_BASE_URL)) {
    return (
      <img
        alt="linkedin"
        width={20}
        height={20}
        style={{ verticalAlign: "middle", marginBottom: "2px" }}
        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAARVBMVEVHcEwAd7UAd7X///8Ad7UAd7UAd7UAd7UAd7UAd7UAcLIAaq4AZawAdbTB2ura7PVroMp1p80re7fy+fyrzOJSkMKHs9TfsXiuAAAACnRSTlMA2f//Q7YW91eNyjh/sgAAAOFJREFUOI3dk9sOgzAIhmurq7Zgz77/ow5ajcvSuvv9F0LKFwIIQoh50X0ts2BtcqyN4utDvBITWzQGe3GthahxG4I13RQzAyYmpVLs5lgZgKxIO4wBx4CzY2D/kUFaxwnGNVCX3n/2iYhfgAXgBMaS6BtjvLquAJZMKmgOtlBcSsl5uAETuMjDWC7WedWU4QbqW2hAUpcKdoFbNUUPOIpvkxsAGaiNSto+UCSes+XRdIrkv/oMyL8FHImAzJaB5jVAMwEk2hDaG2ibVz12Xo+nKeUiztsbaHrV8x5cv5Ybxd/u3ReZwFWTfAAAAABJRU5ErkJggg=="
      />
    );
  } else if (url.startsWith(FACEBOOK_BASE_URL)) {
    return (
      <img
        alt="facebook"
        width={20}
        height={20}
        style={{ verticalAlign: "middle", marginBottom: "2px" }}
        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAD4UlEQVR4nE2TP2yVVRjGf+853/3XW6y2lAYFEhbioAkDiU7GRBcXFyw1QRNjjAOx4sRaOugghog0IRLjZEhpxcTJODFpjIkGNiODBIFYmhbo7S33ft857+Nw2wvvcpLznvP8Oe9zjCdrSZFjlicXVkaL5/a8jXFU4jDukwCEsGrGNcSVdOfe4upHU5s7d3YgbLDImMOYN59aSjMU8bRFnkegCnAf4MWA1UAGnviLlE+vHCsuM6fAPAKTgYwlAscsT35XfRl2FSfpg5elb/NZDGYS9LPkkqJBrVkPoQG5k86tvlP7ZKAUtx3JE98+OhfHmx/7g34CAmYBIBp0q4GP/aPGSAHdBPf77tnx8HSjyOu9r9beb51kSdEAxr/pHQ27Gt9rs18hFZgZQDDoVuLFicBnLxccmQxEg9LFF9cyn/9ZaaIVUh5p1LzTf2v9g+aVYnJhZTSlfMa7SVQ5DsCEAX2Hqabx4xs1pkZsOLs2xrMtSJVMRY7eTVLKZyYXVn4uSmvPhGLkoG9tulkIsD2AABtbcOKFAVg/QyPCr3edWxvO73czdYmcLHjZc2uOHiyzZgqSzyi4lCSZD1XEAJbEoTFDgnqEP/7LvLq4RXJoRmMkQqoEkqhcJJ8pVPlhec9wBdAgREAJqBSSsG23dzqi6jnPtIws4WnIH9TrGdkPF8qaIFfDTGp7suMtI2ZohMe5HynEeAOeKmCzFL3EDplBBWjC2p+uZRhEJBg8qsShicjV954iGLTrRqsYAJYZHvREEeDdHzb56UbFroaRtUMpLzxpzUKcxJNkZp6cqMCe9uOp7lQ9Mty//SBjPrAtSYTClNNaoeTXqdVeI1XupmhZbPWc325VIDi4OzLVHvhefyT+Xk087Il/1zOFhFdC4NRqQbm8Xqj0ywRep0rmFqkhbq8nXrlwn6rrfH18jA9fagHwyz8lb158QNEyokEAMoYpGwFT6ZdDv2mLudu56dY0Vdk9CSVRR5hEfMJyAIxBT0l4EqqyuzUtdx/e7DdtMTA/tWmuU7KWKSsruZQcrxxVjnz44khC5XYvOUouZWVZy8ztFPNTm4FpxfLs3mXvrC6ovrumpKTKXUmQtBNNhgHddrB9Jqm+u+ad1YXy7N5lphUDyzjTivn8/tm8cW/Bi7GaUw+eciZll/sToZBIyT3l7NSDF2O1vHFvIZ/fP8u0Ist4ABPLOHMKurBv1rvrx5X8hkI7omYwi8P8hBANmkHWjkp+w7vrx3Vh3yxzGgjDtP0PTMybM70UuXjgkjobR4qqf4KyvGpiBUhAlrRKma7G1D+hzsYRLh64xPRSZN4cTAD/A2WTcuqqwC+DAAAAAElFTkSuQmCC"
      />
    );
  } else {
    return null;
  }
};

export default Logo;
